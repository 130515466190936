<div class="modal-header">
  <h5 class="modal-title" id="fileuploadModalLabel">Upload Documents</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body stkWHordr">
  <h6>Upload Documents</h6>
  <form method="POST" class="PopupForm" [formGroup]="documentUploadForm"
    (ngSubmit)="uploadFilesOnServer(documentUploadForm)">

    <table class="table">

      <thead>
        <tr>
          <th>
            Sr. No.
          </th>
          <th>
            Description
          </th>
          <th>
            Files
          </th>
          <th>
            Action
          </th>
        </tr>
      </thead>
      <tbody formArrayName="documents">

        <tr *ngFor="let s of getAllControls(documentUploadForm); let i=index" [formGroupName]="i">
          <td>{{i+1}}</td>
          <td>
            <input type="text" [id]="'docDescription'+i" class="form-control" (blur)="addDescriptionToImage(i)"
              formControlName="docDescription">
            <small *ngIf="validation[i]" style="color: rgb(248, 26, 26);">Description is required.</small>
          </td>
          <td>
            <input type="file" [id]="'uploadDocs'+i" formControlName="document"
              [ngClass]="{'is-invalid':fileSizeValidations[i] != undefined && fileSizeValidations[i].size>10}"
              (change)="uploadFileOnChange($event, i)"><br>
            <small *ngIf="fileValid[i]" style="color: rgb(248, 26, 26);">Please choose file.</small>
            <small *ngIf="fileSizeValidations[i] != undefined && fileSizeValidations[i].size>10"
              style="color: rgb(248, 26, 26);">File size limit exceeded, You can upload max 10MB file</small>
          </td>
          <td><button class="btn btn-danger" (click)="deleteFile(i)">Delete</button></td>
        </tr>
        <tr>
          <td colspan="3">

            <button type="button" (click)="addDocument()" ejs-button cssClass=""
              class="add button_padding button_margin buttonSize" [disabled]="cancelledbtnFlag">
              Add
            </button>

          </td>
          <td>


          </td>
        </tr>
      </tbody>

    </table>
    <div class="form-group" style="text-align: right;">

      <button type="submit" ejs-button cssClass="" class="submit button_padding button_margin buttonSize"
        [disabled]="cancelledbtnFlag">
        Upload
      </button>

    </div>
  </form>
  <hr />
  <h6>
    Download documents
  </h6>
  <table class="table">
    <thead>
      <tr>
        <th>Sr. No.</th>
        <th>Date</th>
        <th>Doc From</th>
        <th>File Name</th>
        <th>Description</th>
        <th>Type</th>
        <th>Action</th>
        <th *ngIf="isDeleteAccess"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of multipleFilesRes; let i=index">
        <tr *ngIf="item.action!=2">
          <td>{{i+1}}</td>
          <td>{{item.createdDate==null? serverDate :item.createdDate}}</td>
          <td>{{item.fulfillmentFlag}}</td>
          <td>{{item.fileName}}</td>
          <td>{{item.description}}</td>
          <td><i [ngClass]="updateIconsClass(item.extension)" title="{{ getFileTitle(item.extension) }}" class="fa"
              aria-hidden="true"></i></td>
          <td>
            <a href="javascript:void(0)" (click)="downloadUploadedFile(item.fileKey)">Download</a>
          </td>
          <td *ngIf="isDeleteAccess">
            <button type="button" style="padding: 1px 5px;" class="btn btn-danger"
              [disabled]="buttonFlag && item.formPk>0" (click)="deleteFileDetail(i)">
              Doc delete
            </button>
          </td>
        </tr>
      </ng-container>

    </tbody>
  </table>

</div>
<div class="modal-footer">
  <button ejs-button cssClass="" class="back button_padding button_margin buttonSize" type="button"
    (click)="closeModal()">back</button>
  <button ejs-button cssClass="" class="save button_padding button_margin buttonSize" type="button"
    (click)="done()">Save</button>
</div>

<ngx-ui-loader></ngx-ui-loader>
