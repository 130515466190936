import { Component, OnInit, ViewChild, ViewContainerRef, Inject, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonVariables } from 'src/app/commonVariables';
import { PdfStandardFont, PdfFontFamily, PdfFontStyle } from '@syncfusion/ej2-pdf-export';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from 'src/app/app.component';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { PoFullfilmentService } from '../modules/po-fullfilment-date/po-fullfilment.service';

@Component({
  selector: 'app-mail-navigate',
  templateUrl: './mail-navigate.component.html',
  styleUrls: ['./mail-navigate.component.css']
})
export class MailNavigateComponent implements OnInit {
  mailSettingsData: any = {};
  constructor(private ngxLoader: NgxUiLoaderService, private router: Router, private route: ActivatedRoute,
    public appComp: AppComponent,
    private poFullFilmentService: PoFullfilmentService, private globalService: GlobalServiceService) {
    this.currentUser = {
      companyCode: 'tathata',
      userId: '1',
      password: '123',
      changePassword: null,
      pk: 1
    }
  }

  urlToken: any = '';
  emailIds: any = '';

  // header vars
  currentUser: any = { companyCode: 'tathata', userId: '1', password: '123', changePassword: null, pk: 1 };
  baseurl = environment.apiUrl;
  private currentUserSubject: BehaviorSubject<any>;
  // public currentUser: Observable<any>;
  private facilitySubject: BehaviorSubject<any>;
  public facility: Observable<any>;
  private financialYearSubject: BehaviorSubject<any>;
  public financialYear: Observable<any>;
  public profilePicUrl: Observable<any>;
  private profilePicUrlSubject: BehaviorSubject<any>;
  private accessTokenSubject: BehaviorSubject<any>;
  public accessToken: Observable<any>;

  ngOnInit() {
    this.route.queryParams.subscribe(
      params => {
        console.log("queryParams params ->", params);
        this.urlToken = params['token'];
        this.emailIds = params['emailIds'];

      }
    );
    let formId = 102;
    let emailIds = 'xyz@gmail.com';
    this.getShortUrlDetails(formId, this.urlToken, this.emailIds);
    let mailData: any = { 'mailTemplate': '' };
  }

  getShortUrlDetails(formId, urlToken, emailIds): void {
    this.ngxLoader.start();
    this.poFullFilmentService.getShortUrlDetails(formId, urlToken, emailIds).subscribe(
      (data: any) => {
        this.ngxLoader.stop();
        this.mailSettingsData = data.data;
        console.log("Received xvendor fxacility data-", this.mailSettingsData);
        this.globalService.setMailSettingData(this.mailSettingsData);
        //this.setHeder(this.mailSettingsData);
        console.log("mailSetData ", JSON.parse(sessionStorage.getItem('mailSettingData')));

        if (data.statusCode == 200) {
          this.navigateToPage();
        }
      },
      (err: any) => {
        console.log(err);
        this.ngxLoader.stop();
      });
    this.ngxLoader.stop();
  }

  navigateToPage() {
    this.router.navigateByUrl(`${this.mailSettingsData.actualUrl}`);
  }


  setHeder(mailSettingsData) {
    let currentUser: any = { companyCode: mailSettingsData.companyCode, pk: 1 };
    let facility: any = { id: mailSettingsData.facilityId };
    let financialYear: any = { id: mailSettingsData.financialYearId };
    let accessT: any = { access_token: mailSettingsData.urlToken };

  }
}
