import { Injectable } from "@angular/core";
import {
    CanDeactivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { Observable } from "rxjs";
import { CanConfirmationDialogService } from "../can-deactivate-confirm-dialog/can-confirmation-dialog.service";
import { DirtyComponent } from "../_models/dirty-component";

@Injectable({
    providedIn: "root"
})
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {
    constructor(private confirmationDialogService: CanConfirmationDialogService) { }

    canDeactivate(
        component: DirtyComponent,
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (component.canDeactivate()) {
            return this.confirmationDialogService.confirm(
                "Please confirm..",
                "Do you really want to ... ?"
            );
        } else {
            return true;
        }
    }
}
