import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { rippleMouseHandler } from '@syncfusion/ej2-buttons';

@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  companyForm: FormGroup;
  verificationForm: FormGroup;
  loading = false;
  submitted = false;
  isOTP: boolean = false;
  mobileOtp: any;
  emailOtp: any;
  countries: any;
  states: any;
  isVerified: boolean = false;
  modules: any[] = [];
  public countryFields: Object = { text: 'countryName', value: 'countryId' };
  public stateFields: Object = { text: 'stateName', value: 'stateId' };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router, private ngxLoader: NgxUiLoaderService,
    private globalService: GlobalServiceService) { }

  ngOnInit() {
    this.ngxLoader.start();
    this.getindustryTypeList();
    this.getCountry();
    this.companyForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      domainName: ['', Validators.required],
      companyAddress: ['', Validators.required],
      countryId: ['', Validators.required],
      industryPk: [''],
      stateId: ['', Validators.required],
      city: ['', Validators.required],
      pinCode: ['', Validators.required],
      contactPerson: ['', Validators.required],
      contactMobile: ['', Validators.required],
      companyEmail: ['', Validators.required],
      moduleList: this.formBuilder.array([], this.minLengthArray(1))
    });
    this.verificationForm = this.formBuilder.group({
      mobileOtp: ['', Validators.required],
      emailOtp: ['', Validators.required],
    });
    this.ngxLoader.stop();
  }

  get f() { return this.companyForm.controls; }

  minLengthArray = (min: number) => {
    return (c: AbstractControl): {[key: string]: any} => {
      if (c.value.length >= min)
        return null;
  
      return { MinLengthArray: true};
    }
  }

  onSubmit() {
    this.ngxLoader.start();
    this.submitted = true;

    if (this.companyForm.invalid) {
      this.ngxLoader.stop();
      return;
    }

    this.globalService.registerCompany(this.companyForm.value)
      .subscribe(
        (data: any) => {
          this.ngxLoader.stop();
          console.log("data", data);
          //this.router.navigate(['/landing'], { queryParams: { page: 'success' } });
          this.router.navigate(['/success-message'], { queryParams: { page: 'register' } });
        },
        error => {
          this.ngxLoader.stop();
          this.loading = false;
        });
  }

  onNext() {
    this.globalService.otpVerification(this.companyForm.value.companyEmail, this.companyForm.value.contactMobile).subscribe(
      (data: any) => {
        this.isOTP = true;
        this.mobileOtp = data.data.otpSms;
        this.emailOtp = data.data.otpMail;
      },
      error => {
        this.loading = false;
      }
    )
  }

  public fields1: Object = { text: "countryName", value: "countryId" };
  public placeholder1: string = "Search a Country";
  getCountry() {
    this.globalService.getCountry().subscribe(
      (data: any) => {
        this.countries = data.data;
      }
    )
  }

  public fields2: Object = { text: "stateName", value: "stateId" };
  public placeholder2: string = "Search a State";

  getState(countryId: number) {
    this.globalService.getStateByCountryId(countryId).subscribe(
      (data: any) => {
        this.states = data.data;
        console.log("states", this.states);
      }
    )
  }

  onCountryChanged(event: any) {
    let countryId = event.value;
    this.getState(countryId);

  }
  onVerify() {

    if (this.emailOtp == this.verificationForm.value.emailOtp && this.mobileOtp == this.verificationForm.value.mobileOtp) {
      alert("Sucessfully Verified.")
      this.globalService.getAllPaidModules().subscribe(
        (data: any) => {
          this.modules = data;
          document.querySelector('.lRipple label').addEventListener('mouseup', rippleHandler);
          document.querySelector('.lRipple label').addEventListener('mousedown', rippleHandler);
          function rippleHandler(e: MouseEvent): void {
            let rippleSpan: Element = this.parentElement.nextElementSibling.querySelector('.e-ripple-container');
            if (rippleSpan) {
              rippleMouseHandler(e, rippleSpan);
            }
          }
        }
      )
      this.isVerified = true;
      this.isOTP = false;
    }
    else {
      alert("Please enter correct otp");
    }
  }

  onCheckChange(event, pk) {
    const formArray: FormArray = this.companyForm.get('moduleList') as FormArray;
    if (event.checked == true) {
      this.modules.forEach(item => {
        if (item.pk == pk)
          formArray.push(new FormControl(item));
      })
    }
    else {
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value.pk == pk) {
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

  resetCompanyForm(): void {
    this.companyForm.reset();
    this.companyForm.patchValue({
      "stateId": '',
      "countryId": ''
    });
  }

  resetVerificationForm(): void {
    this.verificationForm.reset();

  }

  navigateToRegister(): void {
    this.isOTP = false;
    this.isVerified = false;
    this.resetCompanyForm();
    this.resetVerificationForm();
  }
  sendOtp(req): void {
    let mobileNo: number = null;
    let email: string = ''
    if (req == 'mobile')
      mobileNo = this.companyForm.value.contactMobile;
    if (req == 'email')
      email = this.companyForm.value.companyEmail;
    this.globalService.otpVerification(email, mobileNo).subscribe(
      (data: any) => {
        if (req == 'mobile')
          this.mobileOtp = data.data.otpSms;
        if (req == 'email')
          this.emailOtp = data.data.otpMail;
      },
      error => {
        this.loading = false;
      }
    )
  }

  setAttribute() {
    $("#ddlelement_popup .e-input").attr("placeholder", "Type here to search");
  }
  industryTypeList: any[] = [];
  getindustryTypeList() {
    this.globalService.getIndustryTypeList().subscribe(
      (data: any) => {
        this.industryTypeList = data;
        console.log("industryTypes ", data);
      }
    )
  }
  setIndustryType(item) {
    this.companyForm.patchValue({
      industryPk: item.pk
    });
    console.log("industry ", item);
    console.log("Form value ", this.companyForm.value.industryPk);

  }
}
