<div id="mySidenav" class="sidenav side-bar-display" *ngIf="currentUser?.userId">
  <ul class="a">
    <li *ngFor="let menu of menus" onclick="closeNav()">
      <a (click)="navigateToModule(menu);selectMenu($event, menu)" class="dflex" [ngClass]="{'active':menu.pk==menuId}"> <i class="{{menu.moduleIcon}}" container='body'
          ngbTooltip="{{menu.moduleName}}"></i>
        <span class="spn"> {{menu.moduleName}}</span>
      </a>
    </li>
    <li>
    </li>
  </ul>
  <div class="ResponsiveMenu" onclick="openNav()">&hArr;</div>

</div>



<!-- ngbTooltip="{{menu.moduleName}}" -->