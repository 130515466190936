<div class="container-fluid fixed">
    <div class="row expire-date-marquee" *ngIf="topmsgFlag">
        <div class="col-sm-12 marquee" style="color: white;">
            <div id="div1" class="text-possition">
                <span [innerHTML]="topMessage"></span>
                <span *ngIf="noticationFlag">
                    <a style="font-style: italic; font-weight: bold; color: #cccc00;" href="javascript:void(0)"
                        [routerLink]="['/settings/6/subscription']">subscribe</a> to continue using
                    our services.
                    Thanks
                </span>
            </div>
            <div id="div2" class="text-possition">
                <a style="cursor: pointer; color: black; font-weight: 700;" (click)="gotToNotication(latestNotication)"
                    href="javascript:void(0)">{{latestNotication.shortDesc}}</a>
            </div>
            <div class="close-top-msg" (click)="closeTopMsg()">X</div>

        </div>
    </div>

    <div class="row">
        <div class="col-sm-3 paddingLeft header-responaive">
            <div class="box1">
            </div>
            <div class="box2">
                <div class="top-company-logo">
                    <img src="data:image/png;base64,{{logo}}" class="img-responsive" title="{{companyName}}" id="logo">
                    <!--  <img src={{logo}} class="img-responsive" id="logo"> -->
                </div>
                <div class="top-company-name"> <span class="companyName" title="{{companyName}}">{{companyName}}</span></div>

            </div>
        </div>
        <div class="col-sm-9 header-responaive" >
            <app-navigation></app-navigation>

        </div>

    </div>
</div>