import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ErrorMsg } from '../model/errormsg';
import { Observable, of, throwError } from 'rxjs';
import { catchError,retry } from 'rxjs/operators';
import { HeaderConfiguration } from 'src/app/headerConfiguration';
import { Role } from '../model/user';
import { Facility } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseurl = environment.apiUrl;
  requestHeader = HeaderConfiguration.headerConfg;
  constructor(private http: HttpClient) {
    console.log("service constructor called");
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
  }

  getAllUsers(): Observable<any[] | ErrorMsg> {
    return this.http.get<any[]>(`${this.baseurl}/common/allUserList`, { headers: this.requestHeader })
      .pipe(
        catchError(err => this.handleHttpError(err))
      );
  }

  getUserById(userId: number): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();

    return this.http.get<any>(`${this.baseurl}/common/userDetailsByPkForRole/?userPk=${userId}`, { headers: this.requestHeader });
  }

  addUser(user: any): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.post<any>(`${this.baseurl}/common/addUpdateUser`, user, { headers: this.requestHeader });
  }

  updateUser(user: any): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.post<any>(`${this.baseurl}/common/updateUserProfile`, user, { headers: this.requestHeader });
  }

  deleteColor(colorId: number): Observable<void> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.delete<void>(`${this.baseurl}/manufacturing/deleteColor/${colorId}`, { headers: this.requestHeader })
  }

  getActiveFacilityList(): Observable<Facility[]> {
    return this.http.get<Facility[]>(`${this.baseurl}/common/activeFacilityList`, { headers: this.requestHeader })
  }

  getAllRoleList(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this.baseurl}/common/role/allRoleMasterList`, { headers: this.requestHeader })
  }

  updateUserProfilePicture(fileKey: any): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/updateUserProfilePicture?userPicture=${fileKey}`);
  }

  getProfilePicUrl(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/document/getUserProfilePicUrl`);
  }

  deleteUsers(userIds): Observable<any> {
    let reqParam = { "userPk": userIds };
    return this.http.get<any>(`${this.baseurl}/common/deleteMultipleUsersByUserPks`, { params: reqParam });
  }
  private handleHttpError(error: HttpErrorResponse): Observable<ErrorMsg> {
    let dataError = new ErrorMsg();
    dataError.errorNumber = error.status;
    dataError.message = error.statusText;
    dataError.friendlyMsg = "An error occurred while retrieving data";
    return throwError(dataError);
  }
  forgetPassword(userId: string): Observable<any> {
    let companycode = HeaderConfiguration.getCompanyCode();
    console.log("company code: ", HeaderConfiguration.getCompanyCode());
    let reqParam = { "userId": userId, "companyCode": companycode };
    return this.http.get<any>(`${this.baseurl}/common/forgetPassword`, { params: reqParam });
  }

  forgetPasswordWithoutLogin(userId: string, companyCode:string): Observable<any> {
    let reqParam = { "userId": userId, "companyCode": companyCode };
    return this.http.get<any>(`${this.baseurl}/common/forgetPassword`, { params: reqParam });
  }

  markDeleteUserByPk(userPk:number): Observable<any> {
    return this.http.delete<any>(`${this.baseurl}/common/markDeleteUserByPk?userPk=${userPk}`);
  }

  deleteUserByPk(userPk: number): Observable<any> {
    return this.http.delete<any>(`${this.baseurl}/common/deleteUserByPk?userPk=${userPk}`);
  }

  moduleListWithPermissionByUserAndFacilityPk(userPk: number,facilityId:number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/role/moduleListWithPermissionByUserAndFacilityPk?userPk=${userPk}&facilityId=${facilityId}`).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );;
  }

  viewButtonRoleModuleList(userPk: number, facilityId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/role/viewButtonRoleModuleList?userPk=${userPk}&facilityId=${facilityId}`).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );;
  }

}
