<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span style="font-size: 14px;">{{errorMessage}}</span>
  <br />
  {{ message }}
</div>
<div class="modal-footer">
  <button type="button" ejs-button cssClass="" class="cancel button_padding button_margin buttonSize"
    (click)="decline()">{{ btnCancelText }}</button>
  <button type="button" ejs-button cssClass="" class="done button_padding button_margin buttonSize"
    (click)="accept()">{{ btnOkText }}</button>
</div>