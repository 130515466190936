import { Component, Inject, OnInit } from '@angular/core';
import { User } from '../modules/core/model/model';
import { Facility } from './models/facility';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { Router } from '@angular/router';
import { UserService } from '../modules/user-roles/service/user.service';
import { NavigateService } from "./navigate.service";
import { Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { LogoutDialogService } from '../existing-user-logout/logout-dialog.service';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs/operators';
import { DataService } from '../dataservices/dataservice.service';
import { PaidPlanSubscribeService } from '../modules/subscription/paid-plan-subscribe.service';
import { CommonVariables } from '../commonVariables';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  clickEventsubscription: Subscription;
  notificationCount: number = 0;
  notifications: any[];
  currentUser: User;
  currentUser2: User;
  users: User[] = [];
  userName: string;
  public size: number;
  public square: number;
  facilites: Facility[];
  facility: any;
  sessionUser: any;
  message: any = {};
  facilityName: string;
  financialYear: any;
  profilePic: any = '';
  public elem;
  isUnreadNotification: boolean = false;
  isUpgradeAccess: boolean = false;
  isUpgradeView: boolean = false;
  menus: any[] = [];
  url: string = '';
  companyName: string = '';
  public planName: string = '';
  public planStatus: string = '';
  public remainingDays: number = 0;
  gettingStarted: string = '';
  knowledgeBase: string = '';
  planVersionName: string = '';
  isNotification: boolean = false;
  facilityId:number = 0;
  fyId:number = 0;
  constructor(public appComp: AppComponent, private dataservice: DataService, public translate: TranslateService, private router: Router, private globalService: GlobalServiceService, private userService: UserService,
    private navigationService: NavigateService, private paidPlanService: PaidPlanSubscribeService,
    private logoutDialogService: LogoutDialogService, @Inject(DOCUMENT) private document: any) {

    this.globalService.getUpgradeAccess().subscribe((access) => {
      console.log("upgrade Access ", access);

      this.isUpgradeAccess = JSON.parse(localStorage.getItem('isUpgradeAccess'));


    });
    this.globalService.getUpgradeViewAccess().subscribe((viewAccess) => {


      this.isUpgradeView = JSON.parse(localStorage.getItem('isViewAccess'));

      this.isUpgradeView = viewAccess;
    });

    this.globalService.currentUser.subscribe(x => { this.currentUser = x; });
    let loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log("logged In User ", loggedInUser, this.currentUser);
    let userDetail: any = {
      "userId": loggedInUser.userId,
      "password": loggedInUser.password,
      "companyCode": loggedInUser.companyCode
    };

    this.clickEventsubscription = this.navigationService.getClickEvent().pipe(take(1)).subscribe(() => {

      this.updateCount();

    });


    this.globalService.getUnreadNotification().pipe(take(1)).subscribe(value => {
      let flag: boolean = true;
      flag = value;
      if (!flag) {

        this.getNotificationsList();
      }

    });
    this.globalService.getNotificationAdded().subscribe(value => {
      let flag: boolean = false;

      flag = value;
      if (flag) {
        this.getNotificationsList();
      }

    });

  }

  ngOnDestroy() {


  }

  public facilityFlag: boolean = false;

  ngOnInit() {
    console.log("navigationcomponent");
    this.dataservice.getAllMenu(this.currentUser.pk).subscribe((data: any) => {
      console.log(data);
      this.menus = data.data;

      let menuFilter: any = this.menus.filter(o => o.moduleAction == "settings");
      console.log("menu filtered ", menuFilter);
      this.url = menuFilter[0]?.moduleAction + "/" + menuFilter[0]?.pk;

      this.menus.forEach(element => {
        if (element.pk == 7) {
          // mo free
          this.gettingStarted = "https://easemyproduction.com/help-getting-started-starter-manufacturing";

        } else if (element.pk == 3 || element.pk == 4) {
          //ultimate
          this.gettingStarted = "https://easemyproduction.com/help-getting-started-ultimate-manufacturing-inventory-plan";

        } else if (element.pk == 13 || element.pk == 14) {
          //pro
          this.gettingStarted = "https://easemyproduction.com/help-getting-started-pro-manufacturing-inventory-plan";

        }
      });
      /*  */

    });


    //this.getProfilePictureUrl();
    this.elem = document.documentElement;
    this.sessionUser = localStorage.getItem('currentUser');
    let profilePic = JSON.parse(localStorage.getItem('profilePicUrl'));
    console.log("currentUser ", this.currentUser);
    this.globalService.profilePicUrl.subscribe(profilePic => {
      console.log("profile pic from sessoin", profilePic);
      if (profilePic) {
        this.profilePic = (profilePic !== "") ? profilePic : 'assets/images/avatars/user.jpg';
      }
      if (profilePic == undefined || profilePic == null) {
        this.getProfilePictureUrl();
      }
    });

    /*  if (profilePic != undefined && profilePic != '' && profilePic != null) {
       this.profilePic = profilePic;
     } */
    this.globalService.facility.pipe(take(1)).subscribe(facility => {
      console.log(facility);
      if (facility) {
        this.facilityName = facility.name;
        this.facilityId = facility.id;
      } else {
        this.facilityName = '';
        this.facilityId =0;
      }
     
      this.isCompanyExpire();
    });
    this.globalService.financialYear.subscribe(financialYear => {
      if (financialYear){
        this.financialYear = financialYear.financialYear;
        this.fyId = financialYear.id;
      }else{
        this.financialYear = ''
        this.fyId = 0;
      }
      
    });
    this.globalService.getUnreadNotificationCount().pipe(take(1)).subscribe((data) => {

      if (data) {
        this.notificationCount = data.data;
        if (this.notificationCount > 0) {
          this.globalService.isUnreadNotification(true);
        } else {

        }
      }
    }
    );
    this.getNotificationsList();
    this.getTranslateLanguage();

    var ref = this;
    this.globalService.getCurrentUser().subscribe(user => {
      if (user != null) {

        this.currentUser2 = user;
        this.companyName = user.companyName;

      }

      if (user) {
        this.currentUser2 = user;
        this.companyName = user.companyName;
        this.getCompanySubscribeDetails(user.companyCode);
      }
    });

    const modulePk = 6;
    const actionName = "subscription"
    this.getButtonAccess(modulePk, actionName);
  }



  logout() {
    let user: any = JSON.parse(localStorage.getItem("currentUser"));
    console.log("currently Logged in user: ", user);
    if (user != undefined && user != null) {
      this.logoutDialogService.confirm('Are you sure you want to logout of ' + user.companyName + ' company?', 'Yes to logout')
        .then((confirmed) => {
          if (confirmed) {
            this.globalService.logout().subscribe(
              (data: any) => {
                console.log('inside service', data);
                if (data.statusCode == 200) {
                  this.appComp.loggedInUser = null;
                  this.globalService.logoutSession();
                  this.router.navigate(['/login']);
                  window.location.href = "/";
                }
              }
            );
          }
        }).catch(() => console.log('User dismissed the dialog'));
    }
  }

  selectFacility(): void {
    this.router.navigateByUrl(`/facility/${this.currentUser.pk}`);
  }

  selectFinancialYear(): void {
    this.router.navigateByUrl(`/financialYear/${this.currentUser.pk}`);
  }

  updateProfile(): void {
    this.router.navigateByUrl(`/user-profile`)
  }

  changePassword(): void {
    this.router.navigate(['/password-reset'], { queryParams: { userId: this.currentUser.userId, domainName: this.currentUser.companyCode } });
    // this.router.navigateByUrl(`/change-password`)
  }

  getNotificationsList(): void {
    this.globalService.getLessActiveNotificationList().subscribe((data: any) => {
      this.notifications = data.data;

      this.updateCount();
    }
    );
  }

  navigateToNotification(notyficationId): void {
    if (notyficationId > 0) {
      this.saveReadReceipt(notyficationId);
      this.router.navigateByUrl(`${this.url}/notifications/add-notification/${notyficationId}/1`);

    } else {
      this.router.navigateByUrl(`${this.url}/notifications/ReadNotificationComponent`);
    }

  }

  navigateToSMS() {
    this.router.navigateByUrl(`${this.url}/sms-setting/admin-sms`)
  }

  saveReadReceipt(notificationId): any {
    this.globalService.saveReadReceipt(notificationId).subscribe((data: any) => {

      if (data.statusCode == 200) {
        this.getNotificationsList();

      }

    }

    );
  }

  getProfilePictureUrl() {
    this.userService.getProfilePicUrl().subscribe((data) => {
      console.log("Profile picture Url ", data);

      if (data.data[0].imageUrl != '' && data.data[0].imageUrl != null) {
        //this.profilePic = data.data[0].imageUrl;
        this.profilePic = (data.data[0].imageUrl !== "") ? data.data[0].imageUrl : 'assets/images/avatars/user.jpg';
        this.globalService.setProfilePic(this.profilePic);
      }

    });
  }

  updateCount() {

    this.globalService.getUnreadNotificationCount().subscribe((data) => {
      this.notificationCount = data.data;
      if (this.notificationCount > 0) {
        this.globalService.isUnreadNotification(true);
      } else {

      }
    }

    )
  }

  changeAdminLoginSetting(): void {
    this.router.navigateByUrl(`/allow-admin-access`);
  }


  redirectToSubscription(): void {
    this.router.navigateByUrl(`${this.url}/subscription`);
  }

  switchLang(lang: string) {
    this.appComp.switchLang(lang);
    var ref = this;
    setTimeout(function () {
      ref.getCurrentLanguage();
    }, 500);
  }

  public languages: any[] = [];
  public currentLanguage: String = 'en';
  getTranslateLanguage() {
    this.languages = this.appComp.translate.getLangs();
    console.log("langaues  ", this.languages);
    this.currentLanguage = this.appComp.translate.currentLang;
    if (this.currentLanguage == undefined || this.currentLanguage == null) {
      this.currentLanguage = this.appComp.translate.getDefaultLang();
    }

    console.log("Current langaues  ", this.currentLanguage);
  }

  getCurrentLanguage() {
    this.currentLanguage = this.appComp.translate.currentLang;
    if (this.currentLanguage == undefined || this.currentLanguage == null) {
      this.currentLanguage = this.appComp.translate.getDefaultLang();
    }
  }

  public fullscreenFlag: boolean = false;
  applyFullScreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
      this.fullscreenFlag = true;
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
      this.fullscreenFlag = true;
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
      this.fullscreenFlag = true;
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
      this.fullscreenFlag = true;
    }
  }
  exitFullScreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
      this.fullscreenFlag = false;
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
      this.fullscreenFlag = false;
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
      this.fullscreenFlag = false;
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
      this.fullscreenFlag = false;
    }
  }

  public companyExpired: boolean = false;
  isCompanyExpire() {
    this.globalService.getIsCompanyExpired().subscribe((data) => {
      localStorage.setItem('isCompanyExpired', data.data);
      this.companyExpired = data.data;
      this.appComp.isCompanyExired = data.data;

    }
    );
  }


  getButtonAccess(modulePk, actionName) {

    this.globalService.getButtonAccess(modulePk, actionName).subscribe((data: any) => {
      console.log("access ", data);
      this.globalService.setActionBean(data.data)
      var ref = this;
      setTimeout(function () {

        ref.isUpgradeAccess = JSON.parse(localStorage.getItem('isUpgradeAccess'));
        ref.isUpgradeView = JSON.parse(localStorage.getItem('isViewAccess'));

      }, 1000);


    }

    );
  }

  subscribedPlanDetails: any = {};
  public notificationFlag: boolean = false;
  startedAndKnowledgeBaseFlag: boolean = false;

  getCompanySubscribeDetails(domainName: string) {

    var ref = this;
    ref.startedAndKnowledgeBaseFlag = false;
    this.paidPlanService.getCompanySubsciptionDetailsByDomainName(domainName).subscribe((data) => {
      console.log("subscribed Details ", data);
      this.subscribedPlanDetails = data.data;
      let planStartDateTime = this.subscribedPlanDetails.planStartDate.split(" ");

      let startDate = planStartDateTime[0].split("/");
      let planStartDate = new Date(startDate[1] + '/' + startDate[0] + '/' + startDate[2]);

      let toDay = new Date();

      let time = toDay.getTime() - planStartDate.getTime();
      let days = time / (1000 * 3600 * 24);
      console.log("diff ", days);
      if (days <= 21) {
        ref.startedAndKnowledgeBaseFlag = true;
      } else {
        ref.startedAndKnowledgeBaseFlag = false;
      }

      let plan: any[] = [];
      let saperatePlan: any[] = [];
      if (this.subscribedPlanDetails.plan != null) {
        plan = this.subscribedPlanDetails.plan.split("#");
        console.log("current plan ", plan);
        this.planName = plan[1];
        saperatePlan = this.planName.split("-");
        this.planVersionName = saperatePlan[1];
      } else {
        this.planName = "None";
        this.planVersionName = "None";
      }

      this.planStatus = this.subscribedPlanDetails.subscriptionStatus;
      ref.remainingDays = ref.subscribedPlanDetails.trialRemainingDays;

    });
  }


  displayNotification() {
    this.isNotification = !this.isNotification;
  }

}

