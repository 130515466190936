import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import { ErrorMsg } from '../../group/model/errormsg';
import { environment } from 'src/environments/environment';
import {Observable, BehaviorSubject,Subject  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigateService {

  baseurl = environment.apiUrl;
  private subject = new Subject<any>();
  constructor(private http: HttpClient) { }

  getLessActiveNotificationList(): Observable<any[]> {
    let user: any = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get<any[]>(`${this.baseurl}/common/lessActiveNotificationList?userPk=${user.pk}`);
  }

  getUnreadNotificationCount(): Observable<any> {
    let user: any = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get<any[]>(`${this.baseurl}/common/unreadNotificationCount?userPk=${user.pk}`);
  }

  sendClickEvent(value: any) {
    this.subject.next(value);
  }
  getClickEvent(): Observable<any>{
    return this.subject.asObservable();
  }

}
