import { Component, OnInit } from '@angular/core';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.css']
})
export class SuccessMessageComponent implements OnInit {
  page:string;
  returnUrl:string;
  pageMessage:string;
  constructor(private globalService: GlobalServiceService, private router:Router,private route:ActivatedRoute) { }

  ngOnInit() {
     this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.page = params['page'];
        this.returnUrl=params['returnUrl'];
      });
      if(this.page=="forgotPassword"){
        this.pageMessage="New Password is sent to your registered email. Please check your email for detials."
      }
      if(this.page=="addCompany"){
        this.pageMessage="New Company Deatils is sent to your registered email. Please check your email for detials."
      }
      if(this.page=="register"){
        this.pageMessage="Congratulations! You have been registered sucessfully. Your account will be active in 2 hours. You will receive email notifications shortly."
      }
  }

  okBtn() {
    if(this.page=="forgotPassword"){
    this.globalService.logout().subscribe(
      (data: any) => {
        this.globalService.logoutSession();
        this.router.navigate(['/login']);
      },
      (err: any) => {
        console.log(err);
      }
    );
  
    }
    if(this.page=="addCompany"){
      this.router.navigateByUrl(`/admin/8/company-list/company`);
    }
    if(this.page=="register"){
      this.router.navigate(['/landing']);
    }
   }

}
