import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-message-mail-navigate',
  templateUrl: './success-message-mail-navigate.component.html',
  styleUrls: ['./success-message-mail-navigate.component.css']
})
export class SuccessMessageMailNavigateComponent implements OnInit {

  constructor(private location: LocationStrategy) {
    /*prevent browser back button action*/ 
    history.pushState(null, null, window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
     //this.stepper.previous();
    });
   }

  ngOnInit() {
  }

}
