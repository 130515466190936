export enum CreadCrumbLabel {
    Inventory = "Inventory",
    Manufacturing = "Manufacturing",
    OrderProcessing = "Order Processing"
}
export enum modulePreviewPageIrl {
    InventoryPreview = "inv-overview",
    ManufacturingPreview = "mfrg-overview",
    OrderProcessingPreview = "processing-overview"
}
