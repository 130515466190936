import { Component} from '@angular/core';
import { User } from '../_models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent  {
  currentUser: User;
  message: any = {};
  constructor()
   {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }
}