<div id="MainContainer" class="container-fluid">
  <app-header></app-header>
  <form [formGroup]="facilityForm" (ngSubmit)="onSubmit()" class="user_facility marginTop">

    <div class="col-sm-12" style="margin-top: 5px;text-align: center;"><h2>Facility creation</h2></div>
    <div class="row">
      <div class="col-sm-2">
        <label> Name</label>
      </div>
      <div class="col-sm-4">
        <div class="e-float-input">
          <input class="form-control" placeholder="Enter Facility Name" formControlName="name" type="text"
          [ngClass]="{'is-invalid': (f['name'].touched ||  f['name'].dirty) && !f['name'].valid || (submitted && !f['name'].valid) }"
           autocomplete="off">
          <span class="e-float-line"></span>
          <div class="invalid-feedback">
            <div *ngIf="f['name'].hasError('required')"> Name is required.</div>
            <div *ngIf="f['name'].hasError('maxlength')">Maximun 50 characters allowed.</div>
          </div>
        </div>
      </div>

      <div class="col-sm-2">
        <label> Short Name</label>
      </div>
      <div class="col-sm-4">
        <div class="e-float-input">
          <input class="form-control" placeholder="Enter Short Name" formControlName="shortName" type="text"
          [ngClass]="{'is-invalid': (f['shortName'].touched || f['shortName'].dirty) && !f['shortName'].valid || (submitted && !f['shortName'].valid) }"
          pattern="[a-zA-Z0-9\s]+" autocomplete="off">
          <span class="e-float-line"></span>
          <div class="invalid-feedback">
            <div *ngIf="f['shortName'].hasError('required')">Short Name is required.</div>
            <div *ngIf="f['shortName'].hasError('minlength')"> At least 3 character required.</div>
            <div *ngIf="f['shortName'].hasError('maxlength')"> Maximum 5 character allowed.</div>
          </div>
        </div>
      </div>
    </div>
 <div class="row">
  <div class="col-sm-2">
    <label> Facility Type</label>
  </div>
  <div class="col-sm-4">
    <fieldset [disabled]="isEdit">
      <ejs-dropdownlist id='ddlelement' #samples [dataSource]='facilityTypes' [fields]='faciliTyfields'
        class="form-control" placeholder='Select Facility' [allowFiltering]='true' formControlName="facilityTypeId"
        (click)="setAttribute()"  [ngClass]="{'is-invalid': (f['facilityTypeId'].touched ||
        f['facilityTypeId'].dirty) &&  !f['facilityTypeId'].valid || (submitted && !f['facilityTypeId'].valid) }">
      </ejs-dropdownlist>
      <div *ngIf="f['facilityTypeId'].errors" class="invalid-feedback">
        <div *ngIf="f['facilityTypeId'].hasError('required')">Facility Type is required</div>
      </div>
    </fieldset>
  </div>

  <div class="col-sm-2">
  </div>
  <div class="col-sm-4">
  </div>
</div>

<div class="row">
  <div class="col-sm-2">
    <label> Address</label>
  </div>
  <div class="col-sm-4">
    <div class="e-float-input">
      <textarea rows="2" cols="50" class="form-control" placeholder="Enter address" formControlName="address"
        type="text" [ngClass]="{'is-invalid': (f['address'].touched ||
        f['address'].dirty) && !f['address'].valid || (submitted && !f['address'].valid) }" autocomplete="off">
        </textarea>
      <span class="e-float-line"></span>
      <div class="invalid-feedback">
        <div *ngIf="f['address'].hasError('required')"> Address is required.</div>
        <div *ngIf="f['address'].hasError('maxlength')"> Maximun 250 characters allowed.</div>
      </div>
    </div>
  </div>

  <div class="col-sm-2">
  </div>
  <div class="col-sm-4">
  </div>
</div>

<div class="row">
  <div class="col-sm-2">
    <label>Country</label>
  </div>
  <div class="col-sm-4">
    <ejs-dropdownlist id='ddlelement' #samples [dataSource]='countries' [fields]='countryFields' class="form-control"
      placeholder='Choose country' [allowFiltering]='true' formControlName="countryId" (change)='changeCountry($event)'
      (click)="setAttribute()" [ngClass]="{'is-invalid': (f['countryId'].touched ||
      f['countryId'].dirty) && !f['countryId'].valid || (submitted && !f['countryId'].valid)}">
    </ejs-dropdownlist>
    <div *ngIf="f['countryId'].errors" class="invalid-feedback">
      <div *ngIf="f['countryId'].hasError('required')">Country is required</div>
    </div>
  </div>

  <div class="col-sm-2">
    <label>State</label>
  </div>
  <div class="col-sm-4">
    <ejs-dropdownlist id='ddlelement' #samples [dataSource]='states' [fields]='statefields' class="form-control"
      placeholder='Choose state' [allowFiltering]='true' formControlName="stateId" (click)="setAttribute()" [ngClass]="{'is-invalid': (f['stateId'].touched ||
    f['stateId'].dirty) && !f['stateId'].valid || (submitted && !f['stateId'].valid)}">
    </ejs-dropdownlist>
    <div *ngIf="f['stateId'].errors" class="invalid-feedback">
      <div *ngIf="f['stateId'].hasError('required')">State is required</div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-2">
    <label> City</label>
  </div>
  <div class="col-sm-4">
    <div class="e-float-input">
      <input class="form-control" placeholder="Enter City" formControlName="cityName" type="text" [ngClass]="{'is-invalid': (f['cityName'].touched ||
      f['cityName'].dirty) && !f['cityName'].valid || (submitted && !f['cityName'].valid) }"  pattern="[a-zA-Z\s]+" autocomplete="off">
      <span class="e-float-line"></span>
      <div class="invalid-feedback">
        <div *ngIf="f['cityName'].hasError('required')"> City is required.</div>
        <!-- <div *ngIf="f['cityName'].hasError('minlength')"> city must at least 2 characters.</div> -->
        <div *ngIf="f['cityName'].hasError('maxlength')"> Maximun 50 characters are allowed.</div>
        <div *ngIf="f['cityName'].hasError('pattern')"> City must be in valid format.</div>
      </div>
    </div>
  </div>

  <div class="col-sm-2">
    <label> Pincode</label>
  </div>
  <div class="col-sm-4">
    <div class="e-float-input">
      <input class="form-control" placeholder="Enter pincode" formControlName="pincode" type="text"
      [ngClass]="{'is-invalid': (f['pincode'].touched || f['pincode'].dirty) && !f['pincode'].valid || (submitted && !f['pincode'].valid) }"
      pattern="[0-9]+"
        autocomplete="off">
      <span class="e-float-line"></span>
      <div class="invalid-feedback">
        <div *ngIf="f['pincode'].hasError('required')"> Pincode is required.</div>
        <div *ngIf="f['pincode'].hasError('minlength')"> At least 4 digits required.</div>
        <div *ngIf="f['pincode'].hasError('maxlength')"> Maximun 8 digits are allowed.</div>
        <div *ngIf="f['pincode'].hasError('pattern')"> Pincode must be in valid format.</div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-2">
    <label> Mobile</label>
  </div>
  <div class="col-sm-4">
    <div class="e-float-input">
      <input class="form-control" placeholder="Enter Mobile Number" formControlName="mobile" type="text"
      [ngClass]="{'is-invalid': (f['mobile'].touched || f['mobile'].dirty) && !f['mobile'].valid || (submitted && !f['mobile'].valid)}"
       pattern="[0-9]+"
        autocomplete="off">
      <span class="e-float-line"></span>
      <div class="invalid-feedback">
        <div *ngIf="f['mobile'].hasError('required')"> Mobile Number is required.</div>
        <div *ngIf="f['mobile'].hasError('minlength')"> At least 8 digits required.</div>
        <div *ngIf="f['mobile'].hasError('maxlength')">Maximun 15 digits are allowed.</div>
        <div *ngIf="f['mobile'].hasError('pattern')"> Mobile Number must be in valid format.</div>
      </div>
    </div>
  </div>

  <div class="col-sm-2">
    <label> Phone</label>
  </div>
  <div class="col-sm-4">
    <div class="e-float-input">
      <input class="form-control" placeholder="Enter Phone Number" formControlName="phone" type="text"
      [ngClass]="{'is-invalid': (f['phone'].touched || f['phone'].dirty) && !f['phone'].valid || (submitted && !f['phone'].valid) }"
        pattern="[0-9]+" autocomplete="off">
      <span class="e-float-line"></span>
      <div class="invalid-feedback">
        <div *ngIf="f['phone'].hasError('required')"> Phone Number is required.</div>
        <div *ngIf="f['phone'].hasError('minlength')"> At least 6 digits required.</div>
        <div *ngIf="f['phone'].hasError('maxlength')"> Maximun 15 digits are allowed.</div>
        <div *ngIf="f['phone'].hasError('pattern')"> Phone Number must be in valid format.</div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-2">
    <label> Email*</label>
  </div>
  <div class="col-sm-4">
    <div class="e-float-input">
      <input class="form-control" placeholder="Please enter email" formControlName="email" type="text"
      [ngClass]="{'is-invalid': (f['email'].touched || f['email'].dirty) && !f['email'].valid || (submitted && !f['email'].valid)}"
      autocomplete="off"
        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$">
      <span class="e-float-line"></span>
      <div class="invalid-feedback">
        <div *ngIf="f['email'].hasError('required')">Email is required.</div>
        <!-- <div *ngIf="f['email'].hasError('minlength')">Email must at least 2 characters.</div> -->
        <div *ngIf="f['email'].hasError('maxlength')">Maximum 100 characters are allowed.</div>
        <div *ngIf="f['email'].hasError('pattern')">Email must be in valid format.</div>
      </div>
    </div>
  </div>

  <div class="col-sm-2">
    <label> GST No.</label>
  </div>
  <div class="col-sm-4">
    <div class="e-float-input">
      <input class="form-control" placeholder="Enter GST No." formControlName="gstNo" type="text"
      [ngClass]="{'is-invalid': (f['gstNo'].touched || f['gstNo'].dirty) && !f['gstNo'].valid || (submitted && !f['gstNo'].valid) }"
       pattern="[a-zA-Z0-9\s]+"
        autocomplete="off">
      <span class="e-float-line"></span>
      <div class="invalid-feedback">
        <div *ngIf="f['gstNo'].hasError('required')"> GST is required.</div>
        <div *ngIf="f['gstNo'].hasError('minlength')"> Atleast 10 character required.</div>
        <div *ngIf="f['gstNo'].hasError('maxlength')"> Maximum 20 character allowed.</div>
        <div *ngIf="f['gstNo'].hasError('pattern')"> GST Number must be in valid format.</div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-2">
    <label>Status</label>
  </div>
  <div class="col-sm-4">
    <ejs-dropdownlist id='ddlelement' #samples [dataSource]='statusDropDn' [fields]='statusFields' class="form-control"
      placeholder='Choose Status' formControlName="statusId" (click)="setAttribute()"
      [ngClass]="{'is-invalid': (f['statusId'].touched || f['statusId'].dirty) && !f['statusId'].valid || (submitted && !f['statusId'].valid)}">
    </ejs-dropdownlist>
    <div class="invalid-feedback">
      <div *ngIf="f['statusId'].hasError('required')"> Status is required.</div>
    </div>
  </div>

  <div class="col-sm-2">
  </div>
  <div class="col-sm-4">
  </div>
</div>


<div class="row">
  <div class="col-sm-4">
    <label> Allowed Operation</label>
  </div>
  <div class="col-sm-8" id="allowedOperation" style="padding-left: 21px!important;">
    <fieldset [disabled]="isManufacture">
      <div class="form-check" formArrayName="allowedOperation"
        *ngFor="let order of facilityForm.controls['allowedOperation']['controls']; let i = index">
        <label class="form-check-label" for="check1">
          <input type="checkbox" [formControlName]="i" class="facility-check form-check-input allowedOperation">
          <div class="form-check-label"></div>{{allowedOperation[i].descr}}
        </label>
      </div>
      <div *ngIf="f['allowedOperation'].hasError('required')"  style="color: #dc3545; font-size: 13px;">Atleast one allowed operation must be selected.</div>
    </fieldset>
  </div>
</div>


<div class="row">
  <div class="col-sm-4">
  </div>
  <div class="col-sm-8">
    <button ejs-button class="submit button_padding button_margin buttonSize"
      type="submit">{{isEdit?"Update":"Submit"}}</button>
    <button *ngIf="!isEdit" type="button" ejs-button class="reset button_padding button_margin buttonSize"
      (click)="resetFacilityForm()"><i class="fa fa-undo"></i>
      Reset</button>
  </div>
</div>
</form>
</div>
<ngx-ui-loader></ngx-ui-loader>
