import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-zoho-confirm',
  templateUrl: './zoho-confirm.component.html',
  styleUrls: ['./zoho-confirm.component.css']
})
export class ZohoConfirmComponent implements OnInit {
  @Input() public planData;
  @Input() public companyData;
  @Output() planConfirm: EventEmitter<any> = new EventEmitter();
  constructor(public activeModal: NgbActiveModal, private http: HttpClient) { }

  ngOnInit() {
    console.log("planData ", this.planData);
  }

  done() {
    console.log("hadhadg")
    this.planConfirm.emit(true);
    this.activeModal.close();
  }
  closeModal() {
    this.planConfirm.emit(false);
    this.activeModal.close();
  }

}
