<div class="card">
  <div class="card-header ">
    <span><b>Service Status</b></span>
  </div>
  <ejs-dashboardlayout id='default_dashboard' columns="5" #default_dashboard [cellSpacing]='cellSpacing'
    [allowResizing]='true'>
    <div class="card-body overviewCard serviceStatus">
      <div class="container-fluid">
        <div class="row">

          <div class="col-sm-12">
            <div class="control-section summaryList">
              <!-- <h5 style="text-align: center;">Current Performance</h5> -->
              <div class="container-fluid">
                <div class="row" style="padding:30px">

                  <div class="col-md-4 current-performance col-md-offset-1">
                    <div class="card header-cards current-perform-container text-white"
                      style="max-width: 18rem;height: 12rem;">
                      <div class="card-body card-padding" id="common">
                        <h6 class="card-subtitle mb-2 text-muted current-performance-title current-subtitle">
                          COMMON
                        </h6>
                        <p *ngIf="commonStatus==200" class="status">Good</p>
                        <p *ngIf="commonStatus==100" class="status">Slow</p>
                        <p *ngIf="commonStatus==0" class="status">Not Working</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 current-performance col-md-offset-1">
                    <div class="card header-cards current-perform-container text-white"
                      style="max-width: 18rem;height: 12rem;">
                      <div class="card-body card-padding" id="inventory">
                        <h6 class="card-subtitle mb-2 text-muted current-performance-title current-subtitle">
                          INVENTORY
                        </h6>
                        <p *ngIf="inventoryStatus==200" class="status">Good</p>
                        <p *ngIf="inventoryStatus==100" class="status">Slow</p>
                        <p *ngIf="inventoryStatus==0" class="status">Not Working</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 current-performance col-md-offset-1">
                    <div class="card header-cards current-perform-container text-white"
                      style="max-width: 18rem;height: 12rem;">
                      <div class="card-body card-padding">
                        <h6 class="card-subtitle mb-2 text-muted current-performance-title current-subtitle">
                          MANUFACTURING
                        </h6>
                        <p *ngIf="manufacturingStatus==200" class="status">Good</p>
                        <p *ngIf="manufacturingStatus==100" class="status">Slow</p>
                        <p *ngIf="manufacturingStatus==0" class="status">Not Working</p>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="row" style="padding:30px">

                  <div class="col-md-4 current-performance col-md-offset-1">
                    <div class="card header-cards current-perform-container text-white"
                      style="max-width: 18rem;height: 12rem;">
                      <div class="card-body card-padding">
                        <h6 class="card-subtitle mb-2 text-muted current-performance-title current-subtitle">
                          MASTER
                        </h6>
                        <p *ngIf="masterStatus==200" class="status">Good</p>
                        <p *ngIf="masterStatus==100" class="status">Slow</p>
                        <p *ngIf="masterStatus==0" class="status">Not Working</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 current-performance col-md-offset-1">
                    <div class="card header-cards current-perform-container text-white"
                      style="max-width: 18rem;height: 12rem;">
                      <div class="card-body card-padding">
                        <h6 class="card-subtitle mb-2 text-muted current-performance-title current-subtitle">
                          REPORTING
                        </h6>
                        <p *ngIf="reportingStatus==200" class="status">Good</p>
                        <p *ngIf="reportingStatus==100" class="status">Slow</p>
                        <p *ngIf="reportingStatus==0" class="status">Not Working</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 current-performance col-md-offset-1">
                    <div class="card header-cards current-perform-container text-white"
                      style="max-width: 18rem;height: 12rem;">
                      <div class="card-body card-padding">
                        <h6 class="card-subtitle mb-2 text-muted current-performance-title current-subtitle">
                          ZUUL
                        </h6>
                        <p *ngIf="zullStatus==200" class="status">Good</p>
                        <p *ngIf="zullStatus==100" class="status">Slow</p>
                        <p *ngIf="zullStatus==0" class="status">Not Working</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" style="padding:30px">

                  <div class="col-md-4 current-performance col-md-offset-1"></div>
                  <div class="col-md-4 current-performance col-md-offset-1">
                    <div class="card header-cards current-perform-container text-white"
                      style="max-width: 18rem;height: 12rem;">
                      <div class="card-body card-padding">
                        <h6 class="card-subtitle mb-2 text-muted current-performance-title current-subtitle">
                          DISCOVERY
                        </h6>
                        <p *ngIf="discoveryStatus==200" class="status">Good</p>
                        <p *ngIf="discoveryStatus==100" class="status">Slow</p>
                        <p *ngIf="discoveryStatus==0" class="status">Not Working</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 current-performance col-md-offset-1"></div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </ejs-dashboardlayout>
</div>
<!-- <div class="control-section">
    <ejs-dashboardlayout id='default_dashboard' columns="5" #default_dashboard [cellSpacing]='cellSpacing'  [allowResizing]='true'>
        <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="1" data-sizeY="1">
            <div class="e-panel-container">
                <div class="text-align">0</div>
            </div>
        </div>
        <div id="three" class="e-panel" data-row="0" data-col="1" data-sizeX="2" data-sizeY="1">
            <div class="e-panel-container">
                <div class="text-align">2</div>
            </div>
        </div>
        <div id="six" class="e-panel" data-row="0" data-col="3" data-sizeX="1" data-sizeY="1">
            <div class="e-panel-container">
                <div class="text-align">5</div>
            </div>
        </div>
        <div id="eight" class="e-panel" data-row="0" data-col="4" data-sizeX="1" data-sizeY="1">
          <div class="e-panel-container">
              <div class="text-align">7</div>
          </div>
      </div>
    </ejs-dashboardlayout>
</div> -->