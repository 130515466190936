import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { ErrorMsg } from '../group/model/errormsg';
import { environment } from 'src/environments/environment';
import { HeaderConfiguration } from 'src/app/headerConfiguration';
import { catchError, retry, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  baseurl = environment.devApiUrl;

  constructor(private http: HttpClient) { }

  common(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/testService`).pipe(
      retry(1),
      catchError(this.handleHttpError));
  }

  inventory(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/inventory/testService`).pipe(
      retry(1),
      catchError(this.handleHttpError));
  }

  master(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/master/testService`).pipe(
      retry(1),
      catchError(this.handleHttpError));
  }

  reporting(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/reporting/testService`).pipe(
      retry(1),
      catchError(this.handleHttpError));
  }

  manufacturing(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/manufacturing/testService`).pipe(
      retry(1),
      catchError(this.handleHttpError));
  }

  private handleHttpError(error: HttpErrorResponse): Observable<ErrorMsg> {
    let dataError = new ErrorMsg();
    dataError.errorNumber = error.status;
    dataError.message = error.statusText;
    dataError.friendlyMsg = "An error occurred while retrieving data";
    return throwError(dataError);
  }

  zuul(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/testService`).pipe(
      retry(1),
      catchError(this.handleHttpError));
  }
  discovery(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/discovery/testService`).pipe(
      retry(1),
      catchError(this.handleHttpError));
  }
}
