import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
//import { take } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { GlobalServiceService } from '../modules/core/services/global-service.service';

@Component({
  selector: 'app-financial-year',
  templateUrl: './financial-year.component.html',
  styleUrls: ['./financial-year.component.css']
})
export class FinancialYearComponent implements OnInit {
  financialYearList: any = [];
  userPk: number;
  tempData: any;
  constructor(
     public appComp: AppComponent,
     private globalService: GlobalServiceService,
     private ngxLoader: NgxUiLoaderService,
     private route: ActivatedRoute,
     private router: Router,
     )
     {
      console.log("financialYear component called");
  }

  ngOnInit() {
    this.ngxLoader.start();
    this.route.paramMap.subscribe(
      params => {
        const pk = +params.get('pk');
        this.userPk = pk;
      });
    let loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
    let token = JSON.parse(localStorage.getItem("accessToken"));
    console.log(token);
    //this.financialYearList = JSON.parse(localStorage.getItem("financialYearList"));
    //console.log("token in fy ", token,loggedInUser,this.userPk);
    // this.getFinancialYear();
    let userDetail: any = {
      "userId": loggedInUser.userId,
      "password": loggedInUser.password,
      "refreshToken": token != null ? token.refresh_token : null
    };
    let tokenFlag = JSON.parse(sessionStorage.getItem('tokenFlag'));

    if (tokenFlag == null || !tokenFlag) {
      //this.getAuthentication(userDetail);
      sessionStorage.setItem('tokenFlag', JSON.stringify(true));
    } else {

      //this.globalService.startRefreshTokenTimer(token.expires_in);
    }
    this.getFinancialYear();

    this.ngxLoader.stop();
  }

  getFinancialYear() {
    // console.log("getfinancial year function");
    //alert("getfinancial year function");
    this.globalService.getAllFinancialYears()
    .subscribe(
      (data: any) => {
        console.log(data);
        if (data.data.length > 0) {
          this.financialYearList = data.data;
        }
        else {
          this.router.navigateByUrl(`/add-financial-year/${this.userPk}`)
        }

      }
     );
  }
  //
  //
  //
  //
  //
  //

  navigateToFacility(financialYear): void {
    console.log(financialYear);
    this.globalService.setFinancialYear(financialYear);

    this.router.navigate([`/facility/${this.userPk}`]);
  }
  //authToken: any = {};
  // getAuthentication(user: any) {
  //   var ref = this;
  //   this.globalService.setIsAuthCalledFromFy(true);
  //   this.appComp.isAuthSelected = true;
  //   this.globalService.authenticationToken(user).pipe(take(1)).subscribe((data) => {
  //     console.log(data);
  //     if (data.data != null) {
  //       this.authToken = data.data;
  //       this.globalService.setAccessToken(this.authToken);
  //       setTimeout(function () {
  //         ref.getFinancialYear();
  //       }, 300);

  //     }

  //   });
  // }
}
