<div id="MainContainer" class="container-fluid mobile-container-fluid">
  <app-header></app-header>
  <div class="container" style="margin-top:5rem">
    <div class="row justify-content-center"*ngIf="facilityList?.length != 0">
      <h3 style="text-align:center">Select Facility</h3>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 facility-container" *ngIf="facilityList?.length != 0">
        
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Name</th>
              <th>Type</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let facility of facilityList;let sl=index" (click)="navigateToDashboard(facility)"
              style="cursor: pointer;">
              <td>{{ sl+1 }}</td>
              <td> {{facility.name}}</td>
              <td>{{facility.typeName}}</td>
              <td>{{facility.stateName}},{{facility.countryName}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-8">
        <div class="alert alert-warning alert-dismissible" *ngIf="facilityList?.length == 0 && isSuperAdmin=='n'">
          <button type="button" class="close" data-dismiss="alert" [routerLink]="['/login']">&times;</button>
          <strong>Warning!</strong> {{msg}}
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="row">
      <div class="col-sm-12" style="text-align: center; margin-bottom: 10px;">
        <button type="button" (click)="back()" ejs-button cssClass=""
          class="cancel button_padding button_margin buttonSize">Cancel</button>
      </div>
    </div>
  </div>
</div>

<ngx-ui-loader></ngx-ui-loader>