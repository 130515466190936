<div class="container justify-content-center align-items-center">
    <form (ngSubmit)="onSubmit()" [formGroup]="companyForm" class="registerContainer">
        <h3 class="alignCentre" *ngIf="isOTP==false">Registration</h3>
        <div *ngIf="isOTP==false && isVerified==false">
            <div class="form-group">
                <div class="row justify-content-center align-items-center">
                    <div class="col-sm-3">
                        <label>Company Name</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="e-float-input">
                            <input class="form-control" placeholder="Enter Company Name" formControlName="companyName"
                                type="text">
                            <span class="e-float-line"></span>
                            <div ngIf="f['companyName'].errors" * class="invalid-feedback">
                                <div *ngIf="f['companyName'].hasError('required')">company Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row justify-content-center align-items-center">
                    <div class="col-sm-3">
                        <label>Domain Name</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="e-float-input">
                            <input class="form-control " placeholder="Enter Domain Name" formControlName="domainName"
                                pattern='^[a-zA-Z0-9_]*$' type="text">
                            <span class="e-float-line"></span><i [routerLink]="['/demo']"
                                class="fa fa-info-circle fa-2x domainIcon" aria-hidden="true"></i>
                            <div ngIf="f['domainName'].errors" * class="invalid-feedback">
                                <div *ngIf="f['domainName'].hasError('required')">Domain Name is required</div>
                                <div *ngIf="f['domainName'].hasError('pattern')">Domain must be in valid format.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>Address</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="e-float-input">
                            <textarea rows="4" cols="50" class="form-control" placeholder="Enter Address"
                                formControlName="companyAddress">
                 </textarea>
                            <span class="e-float-line"></span>
                            <div ngIf="f['companyAddress'].errors" * class="invalid-feedback">
                                <div *ngIf="f['companyAddress'].hasError('required')">Company Address is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>Country</label>
                    </div>
                    <div class="col-sm-9">
                        <!-- <div class="e-float-input">
                            <select class="form-control" formControlName="countryId"
                                (change)="onCountryChanged($event)">
                                <option value="" selected disabled>Choose a Country</option>
                                <option *ngFor="let country of countries" [value]="country.countryId"
                                    [selected]="countryId">
                                    {{ country.countryName }}</option>
                            </select>
                            <span class="e-float-line"></span>
                            <div ngIf="f['countryId'].errors" * class="invalid-feedback">
                                <div *ngIf="f['countryId'].hasError('required')">country is required</div>
                            </div>
                        </div> -->

                        <ejs-dropdownlist id='ddlelement' #samples [dataSource]='countries' [fields]='countryFields'
                            class="form-control" [placeholder]='placeholder1' [allowFiltering]='true'
                            formControlName="countryId" (change)='onCountryChanged($event)'
                            (click)="setAttribute()" [ngClass]="{'is-invalid': (f['countryId'].touched ||
              f['countryId'].dirty) &&
              !f['countryId'].valid || (submitted && !f['countryId'].valid)}">
                        </ejs-dropdownlist>

                        <div class="invalid-feedback">
                            <div *ngIf="f['countryId'].hasError('required')">Country is required.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>State</label>
                    </div>
                    <div class="col-sm-9">
                        <!-- <div class="e-float-input">
                            <select class="form-control" formControlName="stateId">
                                <option value="" selected disabled>Choose a State</option>
                                <option *ngFor="let state of states" [value]="state.stateId" [selected]="stateId">
                                    {{ state.stateName }}</option>
                            </select>
                            <span class="e-float-line"></span>
                            <div ngIf="f['stateId'].errors" * class="invalid-feedback">
                                <div *ngIf="f['stateId'].hasError('required')">state is required</div>
                            </div>
                        </div> -->
                        <div class="e-float-input">
                            <ejs-dropdownlist id='ddlelement' #samples [dataSource]='states' formControlName="stateId"
                                [fields]='stateFields' class="form-control" [placeholder]='placeholder2'
                                [allowFiltering]='true'
                                (click)="setAttribute()" [ngClass]="{'is-invalid': (f['stateId'].touched ||
                            f['stateId'].dirty) &&  !f['stateId'].valid || (submitted && !f['stateId'].valid)}">
                            </ejs-dropdownlist>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>City</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="e-float-input">
                            <input class="form-control" placeholder="Enter City " formControlName="city" type="text">
                            <span class="e-float-line"></span>
                            <div ngIf="f['city'].errors" * class="invalid-feedback">
                                <div *ngIf="f['city'].hasError('required')">city is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>Pin Code</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="e-float-input">
                            <input class="form-control" placeholder="Enter Pincode" formControlName="pinCode"
                                type="text">
                            <span class="e-float-line"></span>
                            <div ngIf="f['pinCode'].errors" * class="invalid-feedback">
                                <div *ngIf="f['pinCode'].hasError('required')">Pincode is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>Contact Person</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="e-float-input">
                            <input class="form-control" placeholder="Contact Person" formControlName="contactPerson"
                                type="text">
                            <span class="e-float-line"></span>
                            <div ngIf="f['contactPerson'].errors" * class="invalid-feedback">
                                <div *ngIf="f['contactPerson'].hasError('required')">contact Person is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>Mobile No.</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="e-float-input">
                            <input class="form-control" placeholder=" Enter Mobile No." formControlName="contactMobile"
                                type="text">
                            <span class="e-float-line"></span>
                            <div ngIf="f['contactMobile'].errors" * class="invalid-feedback">
                                <div *ngIf="f['contactMobile'].hasError('required')"> Mobile No. is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>Email</label>
                    </div>
                    <div class="col-sm-9">
                        <div class="e-float-input">
                            <input class="form-control" placeholder="Email Id" formControlName="companyEmail"
                                type="text">
                            <span class="e-float-line"></span>
                            <div ngIf="f['companyEmail'].errors" * class="invalid-feedback">
                                <div *ngIf="f['companyEmail'].hasError('required')"> Email is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-4">
                </div>
                <div class="col-sm-8 ">
                    <button type="button" ejs-button cssClass="" class="submit button_padding button_margin buttonSize"
                        [disabled]="!companyForm.valid" (click)="onNext()">Next</button>
                    <button type="button" ejs-button cssClass="" class="reset button_padding button_margin buttonSize"
                        (click)="resetCompanyForm()"><i class="fa fa-undo"></i>
                        Reset</button>
                    <button type="button" [routerLink]="['/landing']" ejs-button cssClass=""
                        class="back button_padding button_margin buttonSize"><i class="fa fa-arrow-left"></i>
                        back</button>
                </div>
            </div>

        </div>
        <div *ngIf="isVerified==true">
            <div class="row">

                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header ">
                            <label class="customLabel">Choose Module</label>
                        </div>
                        <div class="card-body">
                            <div *ngFor="let module of modules">
                                <div class=" row  customRow">
                                    <div class="col-sm-6 customLabel lRipple"><label for='switch{{module.pk}}'>
                                            {{module.moduleName}} </label></div>
                                    <div class="col-sm-6">
                                        <ejs-switch #switch{{module.pk}} id="switch{{module.pk}}" cssClass="custom-iOS"
                                            (change)="onCheckChange($event,module.pk)"></ejs-switch>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-check" *ngFor="let module of modules">
                        <label class="form-check-label">
                            <input type="checkbox" [value]="module.pk" (change)="onCheckChange($event)"
                                class="form-check-input" value="">{{module.moduleName}}
                        </label>
                    </div> -->
                            <div class="alignCentre" *ngIf="!f['moduleList'].valid">Atleast
                                one module must be selected.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2">

                </div>
                <div class="col-lg-12 control-section card-control-section basic_card_layout industry-part">
                    <div class="e-sample-resize-container e-card-resize-container">
                        <div class='row'>
                            <div class="row card-layout industry-cardLayout">
                                <ng-container *ngFor="let industryType of industryTypeList; let i=index">
                                    <div class="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                        <div tabindex="0" class="e-card" id="basic_card"
                                            (click)="setIndustryType(industryType)"
                                            [ngClass]="{'is-selected': companyForm.value.industryPk==industryType.pk}">
                                            <div class="e-card-header">
                                                <div class="e-card-header-caption">
                                                    <div class="e-card-header-title domain-title"
                                                        [ngClass]="{'is-selected': companyForm.value.industryPk==industryType.pk}">
                                                        <b>Industry Type</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="e-card-content text-possition"
                                                [ngClass]="{'is-selected': companyForm.value.industryPk==industryType.pk}">
                                                {{industryType.industryName}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col-sm-4">
                </div>
                <div class="col-sm-8">
                    <button type="submit" ejs-button cssClass="" class="submit button_padding button_margin buttonSize"
                        [disabled]="!f['moduleList'].valid">Create Account</button>
                    <button type="button" (click)="navigateToRegister()" ejs-button cssClass=""
                        class="back button_padding button_margin buttonSize"><i class="fa fa-arrow-left"></i>
                        back</button>
                </div>
            </div>
        </div>

    </form>
    <form (ngSubmit)="onVerify()" [formGroup]="verificationForm" class="registerContainer"
        style="padding-bottom: 100px">
        <div *ngIf="isOTP==true">
            <h3 class="alignCentre">Verification</h3>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>Mobile OTP</label>
                    </div>
                    <div class="col-sm-8">
                        <div class="e-float-input">
                            <input class="form-control" placeholder="Enter Mobile OTP" formControlName="mobileOtp"
                                type="text">
                            <span class="e-float-line"></span>
                            <div ngIf="f['mobileOtp'].errors" * class="invalid-feedback">
                                <div *ngIf="f['mobileOtp'].hasError('required')">Mobile OTP is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <button ejs-button cssClass="" class="btnResend submit button_padding button_margin buttonSize"
                            (click)="sendOtp('mobile')" type="button">Resend</button>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="row">
                    <div class="col-sm-3">
                        <label>Email OTP</label>
                    </div>
                    <div class="col-sm-8">
                        <div class="e-float-input">
                            <input class="form-control" placeholder="Enter Email OTP" formControlName="emailOtp"
                                type="text">
                            <span class="e-float-line"></span>
                            <div ngIf="f['emailOtp'].errors" * class="invalid-feedback">
                                <div *ngIf="f['emailOtp'].hasError('required')">Email OTP is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <button ejs-button cssClass="" class=" btnResend submit button_padding button_margin buttonSize"
                            (click)="sendOtp('email')" type="button">Resend</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                </div>
                <div class="col-sm-8">
                    <button ejs-button cssClass="" class="submit button_padding button_margin buttonSize" type="submit"
                        [disabled]="!verificationForm.valid">Verify</button>
                    <button type="button" ejs-button cssClass="" class="reset button_padding button_margin buttonSize"
                        (click)="resetVerificationForm()"><i class="fa fa-undo"></i>
                        Reset</button>
                    <button type="button" (click)="navigateToRegister()" ejs-button cssClass=""
                        class="back button_padding button_margin buttonSize"><i class="fa fa-arrow-left"></i>
                        back</button>
                </div>
            </div>
        </div>
    </form>
</div>
<ngx-ui-loader></ngx-ui-loader>
