import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Facility } from "../models/facility";
import { ErrorMsg } from '../../group/model/errormsg';
import { environment } from '../../../../environments/environment';
import { FacilityType } from '../models/facilityType';
import { Country } from '../models/country';
import { State } from '../../customer/models/state';
import { HeaderConfiguration } from 'src/app/headerConfiguration';
import { DataStateChangeEventArgs, Sorts, PredicateModel } from '@syncfusion/ej2-grids';
import { Predicate, DataResult } from '@syncfusion/ej2-data';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacilityService extends Subject<DataStateChangeEventArgs>{
  baseurl = environment.apiUrl;
  // requestHeader = HeaderConfiguration.headerConfg;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();
  constructor(private http: HttpClient) {
    console.log("service constructor called");
    super();
  }

  getAllFacilitys(): Observable<any | ErrorMsg> {
    return this.http.get<any>(`${this.baseurl}/common/allFacilityList/`, { headers: this.requestHeader })
      .pipe(
        catchError(err => this.handleHttpError(err))
      );
  }

  getFacilityById(facilityId: number): Observable<Facility> {
    return this.http.get<Facility>(`${this.baseurl}/common/getFacility/${facilityId}`, { headers: this.requestHeader });
  }


  addFacility(facility: Facility): Observable<Facility> {
    return this.http.post<Facility>(`${this.baseurl}/common/addFacility/`, facility, { headers: this.requestHeader });
  }


  getFacilityType(): Observable<FacilityType> {
    // let facilityType: string = 'FACILITY_TYPE';
    return this.http.get<FacilityType>(`${this.baseurl}/common/getFacilityType`, { headers: this.requestHeader })
  }

  getCategoryType(): Observable<FacilityType> {
    let categoryType: string = 'CATEGORY_TYPE';
    return this.http.get<FacilityType>(`${this.baseurl}/common/getMasterDataByType/${categoryType}`, { headers: this.requestHeader })
  }

  getCountry(): Observable<Country> {
    return this.http.get<Country>(`${this.baseurl}/common/getCountries`, { headers: this.requestHeader })
  }

  getStateByCountryId(countryId: number): Observable<State> {
    return this.http.get<State>(`${this.baseurl}/common/getStateByCountryId/${countryId}`, { headers: this.requestHeader })
  }

  facilityDropDownByType(facilityType: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/facilityDropDownByType/${facilityType}`, { headers: this.requestHeader })
  }

  private handleHttpError(error: HttpErrorResponse): Observable<ErrorMsg> {
    let dataError = new ErrorMsg();
    dataError.errorNumber = error.status;
    dataError.message = error.statusText;
    dataError.friendlyMsg = "An error occurred while retrieving data";
    return throwError(dataError);
  }


  public execute(state: any, grid: any): void {
    this.getFacilitySyncf(state, grid).subscribe(x => super.next(x as DataStateChangeEventArgs))
  }

  getFacilitySyncf(state: any, grid: any): Observable<any[]> {
    let sortQuery: String = '';
    const skipquery = state.skip ? `skip=${state.skip}` : null;
    let pageQuery = '';
    const takeQuery = state.take ? `top=${state.take}` : null;
    if (skipquery) {
      pageQuery = `${skipquery}&`;
    }
    if (takeQuery) {
      pageQuery = `${pageQuery}${takeQuery}`;
    }
    let filterQuery: String = '';
    if ((state.sorted || []).length) {
      sortQuery = `&orderby=` + state.sorted.map((obj: Sorts) => {
        return obj.direction === 'descending' ? `${obj.name} desc` : obj.name;
      }).reverse().join(',');
    }

    if (state.where) {
      filterQuery = `&filter=` + state.where.map((obj: PredicateModel) => {
        return (<Predicate>obj).predicates.map((predicate) => {
          console.log('1-Field => ' + predicate.field);
          console.log('2-Operator => ' + predicate.operator);
          console.log('3-Value => ' + predicate.value);
          if ((<any>predicate).predicates) {
            if (<any>grid.getColumnByField((<any>predicate).predicates[0].field).type === 'date') {
              return `((${(<any>predicate).predicates[0].field} gt ${(<any>predicate).predicates[0].value}) and (${(<any>predicate).predicates[1].field} lt ${(<any>predicate).predicates[1].value}))`;
            }
          }
          else {
            return predicate.field + '$' + predicate.operator + '$' + predicate.value;
          }
        }).reverse().join(',');
      });
    }
    console.log('==============================');
    console.log(`${this.baseurl}/common/allFacilityList${pageQuery}${sortQuery}${filterQuery}&$count=true`, { headers: this.requestHeader });
    let url = `${this.baseurl}/common/allFacilityList`;
    return this.http.get<any[]>(decodeURIComponent(url), { headers: this.requestHeader })
      .pipe(map((response: any) => response))
      .pipe(map((response: any) => {
        return state.dataSource === undefined ? (<DataResult>{
          result: response['data'],
          count: parseInt(response['count']),
        }) : response['data'];
      }))
      .pipe(map((data: any) => data));
  }


  getFacilityStatusRecoreds(state: any, grid: any): Observable<any[]> {
    let data = [
      {
        "createdBy": 0,
        "modifiedBy": 0,
        "id": 34,
        "name": "Amit-Steel(Warehouse)",
        "shortName": "",
        "address": "Chauhan Associates , Sector-168, Dostpur Mangroli,Noida",
        "gstNo": "BAVPC1572M",
        "statusId": 1,
        "statusName": "Active",
        "phone": "07042718427",
        "mobile": "07042718427",
        "countryId": 99,
        "stateId": 1505,
        "pincode": "201304",
        "typeId": 1,
        "typeName": "Warehouse",
        "email": "amit.tathata@gmail.com",
        "countryName": "India",
        "stateName": "Uttar Pradesh                                                                                       ",
        "cityName": "Noida",
        "allowedOperationValue": "Raw Material,Sub Assembly,Finish",
        "counter": 0,
        "allowedOperation": null,
        "isPrimary": 0,
        "roleMasterList": null,
        "linkedWarehouseId": 0,
        "selected": false
      },
      {
        "createdBy": 0,
        "modifiedBy": 0,
        "id": 7,
        "name": "sushil delhi",
        "shortName": "shu",
        "address": "Road No-16",
        "gstNo": "wewwqewwqw",
        "statusId": 2,
        "statusName": "Inactive",
        "phone": "08123843828",
        "mobile": "08123843828",
        "countryId": 1,
        "stateId": 1,
        "pincode": "800024",
        "typeId": 1,
        "typeName": "Warehouse",
        "email": "sushil0390@gmail.com",
        "countryName": "Afghanistan",
        "stateName": "Badakhshan                                                                                          ",
        "cityName": "Patna",
        "allowedOperationValue": "Raw Material",
        "counter": 0,
        "allowedOperation": null,
        "isPrimary": 0,
        "roleMasterList": null,
        "linkedWarehouseId": 0,
        "selected": false
      }
    ];
    return of(data);
  }

  getLinkedRawMaterialWh(typeId: number, currentFacilityId: number, linkedWhType: string): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/facilityDropDownByType/${typeId}/${currentFacilityId}/${linkedWhType}`);
  }
  getLinkedFinishWh(typeId: number, currentFacilityId: number, linkedWhType: string): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/facilityDropDownByType/${typeId}/${currentFacilityId}/${linkedWhType}`);
  }

  checkFacilityNameDuplicate(facilityName: any): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/checkFacilityNameDuplicate?facilityName=${facilityName}`);
  }

}
