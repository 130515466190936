import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Company } from "../models/company";
import { ErrorMsg } from "../models/errormsg"

import { Country } from "../models/country";
import { State } from "../models/state";
import { HeaderConfiguration } from 'src/app/headerConfiguration';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  baseurl = environment.apiUrl;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();

  constructor(private http: HttpClient) {
    console.log("company.service.ts")
   }

  private handleHttpError(error: HttpErrorResponse): Observable<ErrorMsg> {
    let dataError = new ErrorMsg();
    dataError.errorNumber = error.status;
    dataError.message = error.statusText;
    dataError.friendlyMsg = "An error occurred while retrieving data";
    return throwError(dataError);
  }

  getCountry(): Observable<Country> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<Country>(`${this.baseurl}/common/getCountries`, { headers: this.requestHeader });
  }
  addCompany(company: any): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.post<any>(`${this.baseurl}/common/updateCompanyMasterByDomainName/`, company, { headers: this.requestHeader });
  }
  getStateByCountryId(countryId: number): Observable<State> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<State>(`${this.baseurl}/common/getStateByCountryId/${countryId}`, { headers: this.requestHeader })
  }
  getCompanyByDomainName(domainName: string): Observable<Company> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<Company>(`${this.baseurl}/common/getCompanyMasterByDomainName/${domainName}`, { headers: this.requestHeader });
  }

  getPlanTypeModules(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getPlanTypeModules`);
  }
 // /common/listOfTimeZone
  listOfTimeZone(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/listOfTimeZone`);
  }
  ///common/listOfWeekDays
  listOfWeekDays(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/listOfWeekDays`);
  }
  //common/addOrUpdateCompanyOtherDetail 
  addOrUpdateCompanyOtherDetail(data): Observable<any> {
    return this.http.put<any>(`${this.baseurl}/common/addOrUpdateCompanyOtherDetail`,data)
  }

}
