<ul class="nav ace-nav header-menu-responsive" *ngIf="currentUser?.userId">

  <li id="upgrade-nav">
    <a (click)="redirectToSubscription()" *ngIf="isUpgradeAccess==true || isUpgradeView==true" class="btn btn-link"
      style="color: #ffcc00 !important;font-size: 15px;" role="button">
      Upgrade
    </a>
  </li>
  <li class="lang-translation" ngbDropdown>

    <div ngbDropdownToggle class="login">
      <span class="user-info" style="text-transform: uppercase;">{{currentLanguage}}
      </span>
      <i class="ace-icon fa fa-caret-down"></i>
    </div>

    <div ngbDropdownMenu class="user">
      <a class="dropdown-item language-item" *ngFor="let language of languages" (click)="switchLang(language)"
        style="text-transform: uppercase;">{{language}}</a>


    </div>
  </li>


  <li *ngIf="financialYear">
    <a class="btn btn-link btnColor" role="button" *ngIf="financialYear"
      (click)="selectFinancialYear()">{{financialYear}} <i class="fa fa-caret-down" aria-hidden="true"></i></a>
  </li>
  <li class="selected-facility-name" *ngIf="facilityName">
    <a class="btn btn-link btnColor" role="button" *ngIf="facilityName"
      (click)="selectFacility()"><span>{{facilityName}}</span> <i class="fa fa-caret-down" aria-hidden="true"></i></a>
  </li>
  <li data-bs-target="#myModal" data-bs-toggle="modal" class="notification-menu" style="display:block;"  [ngClass]="{'disable-notification': facilityId == 0 || fyId == 0 }">
    <a class="notification custom-navigation">
      <i class="ace-icon fa fa-bell"></i> <span class="badge badge-grey rounded-circle">{{notificationCount}}</span>
    </a>

  </li>
  <li class="full-screen">
    <i (click)="applyFullScreen()" *ngIf="!fullscreenFlag" title="FullScreen" class="fa fa-arrows-alt fa-lg"
      aria-hidden="true"></i>
    <i (click)="exitFullScreen()" *ngIf="fullscreenFlag" title="Exit FullScreen" class="fa fa-compress  fa-lg"
      aria-hidden="true"></i>
  </li>

  <li class="header-help-icon" ngbDropdown>
   <div ngbDropdownToggle class="login">
      <span class="user-info" style="text-transform: uppercase;">
        <i style="color: white; margin-left: 4px;" class="fa fa-question-circle fa-lg" aria-hidden="true"></i>
      </span>
      <i *ngIf="startedAndKnowledgeBaseFlag" class="ace-icon fa fa-caret-down"></i>
    </div>

    <div ngbDropdownMenu class="user">
    
      <a *ngIf="startedAndKnowledgeBaseFlag" class="dropdown-item" href="{{gettingStarted}}" target="_blank"><i
          class="fa fa-star" aria-hidden="true"></i>
        {{'Help-'+planVersionName}}</a>

    </div>

  </li>

  <li ngbDropdown>


    <div ngbDropdownToggle class="login">
      <span class="user-info">{{currentUser?.userName}}</span>
      <i class="ace-icon fa fa-caret-down"></i>
    </div>

    <div ngbDropdownMenu class="user">
      <a class="dropdown-item" (click)="updateProfile()"><i class="fa fa-user"></i> Update Profile</a>
      <a class="dropdown-item" (click)="changePassword()"><i class="fa fa-cog"></i> Change Password</a>
      
      <a *ngIf="startedAndKnowledgeBaseFlag" class="dropdown-item" href="{{gettingStarted}}" target="_blank"><i
          class="fa fa-star" aria-hidden="true"></i>
        {{'Help-'+planVersionName}}</a>

      <a class="dropdown-item" href="https://tathata-erp-development.atlassian.net/servicedesk/customer/portal/2"
        target="_blank"><i class="fa fa-question-circle-o" aria-hidden="true"></i> Customer Support</a>

      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="logout()"><i class="fa fa-power-off"></i> Log Out</a>
    </div>
  </li>
  <li class="logout-menu"><span class="logout-button" (click)="logout()"><i class="fa fa-power-off"></i> Logout</span>
  </li>
</ul>


<!--my code-->



<!--Popcode-->
<div class="modal fade" id="myModal"  tabindex="-1" aria-labelledby="myModal" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="row">
        <div class="col-sm-12">
          <i class="ace-icon fa fa-exclamation-triangle"></i> Notifications
        </div>
      </div>
      <div class="row" *ngFor="let n of notifications" (click)="navigateToNotification(n.pk)">
        <div class="col-sm-12">
          <span *ngIf="!n.read"><b>{{n.shortDesc}}</b></span> <span *ngIf="n.read">{{n.shortDesc}}</span>
        </div>
      </div>
      <div class="row" (click)="navigateToNotification(0)">
        <div class="col-sm-12"> See all
          notifications <i class="ace-icon fa fa-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>