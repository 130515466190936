<div class="container justify-content-center align-items-center">
    <div class="row justify-content-center align-items-center ">
        <div class="col-sm-12 ">
            <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="LoginContainer">
                <h2 class="loginTitle">Change Password</h2>
                <div class="form-group justify-content-center align-items-center">
                    <label for="password"> Old Password</label>
                    <input type="password" formControlName="oldPassword" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f['oldPassword'].errors }" />
                    <div *ngIf="submitted && f['oldPassword'].errors" class="invalid-feedback">
                        <div *ngIf="f['oldPassword'].hasError('required')"> Old Password is required</div>
                    </div>
                </div>
                <div class="form-group justify-content-center align-items-center">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': (f['password'].touched || f['password'].dirty) && !f['password'].valid || (submitted && !f['password'].valid) }" />
                    <div class="invalid-feedback">
                        <div *ngIf="f['password'].hasError('required')">Password is required</div>

                        <div
                            *ngIf="!f['password'].hasError('maxlength') && !f['password'].hasError('minlength') && f['password'].hasError('invalidPassword')">
                            Password is invalid (Must contain at least one
                            number and one uppercase and lowercase letter and one special
                            character(Except #) and Length should be 6 to 15 characters) </div>

                    </div>
                </div>
                <div class="form-group justify-content-center align-items-center">
                    <label for="password">Confirm Password</label>
                    <input type="password" formControlName="confirmPassword" class="form-control"
                        [ngClass]="{ 'is-invalid':  (f['confirmPassword'].touched || f['confirmPassword'].dirty) && !f['confirmPassword'].valid || (submitted && !f['confirmPassword'].valid) }" />
                    <div class="invalid-feedback">
                        <div *ngIf="f['confirmPassword'].hasError('required')">Confirm Password is required</div>

                        <div
                            *ngIf="!f['confirmPassword'].hasError('maxlength') && !f['confirmPassword'].hasError('minlength') && f['confirmPassword'].hasError('invalidPassword')">
                            Confirm Password is invalid (Must contain
                            at least one number and one uppercase and lowercase letter and one special character(Except #) and Length should be 6 to 15 characters)</div>
                        <div *ngIf="f['confirmPassword'].hasError('mustMatch')">Passwords must match</div>
                    </div>
                </div>
                <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-md">Change Password</button>
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
