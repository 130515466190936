
import { HttpClient, HttpEvent, HttpErrorResponse, HttpHeaders, HttpEventType } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
//import{Files} from "../../../_models/uploadFile";

import { ErrorMsg } from '../../group/model/errormsg';
import { environment } from '../../../../environments/environment';
import { UploadedFileDetail } from '../../sales-order/model/uploadedFileDetail';
import { HeaderConfiguration } from 'src/app/headerConfiguration';

const HttpUploadOptions = {
  headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
}
@Injectable({
  providedIn: 'root'
})
export class UpladServicesService {
  SERVER_URL: string = "http://52.66.102.75:3333/common/document";
  baseurl = environment.apiUrl;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();
  //requestHeader = HeaderConfiguration.headerConfg;
  //requestHeaderMultipartFormData = HeaderConfiguration.multiPartHeaderConfig;
  requestHeaderMultipartFormData = HeaderConfiguration.getHeaderConfigWithMultiPartConfiguration();

  constructor(private http: HttpClient, private httpClient: HttpClient) {
    console.log("service constructor called");
  }





  public upload(data, userId) {
    let uploadURL = `${this.SERVER_URL}`;
    this.requestHeaderMultipartFormData = HeaderConfiguration.getHeaderConfigWithMultiPartConfiguration();
    return this.httpClient.post<any>(this.baseurl + "/common/document/uploadMultipleFilesApi", data, {
      reportProgress: true,
      observe: 'events',
      headers: this.requestHeaderMultipartFormData
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  uploadMultipleFiles(files: any[], fileDesc: any[]): Observable<any> {
    this.requestHeaderMultipartFormData = HeaderConfiguration.getHeaderConfigWithMultiPartConfiguration();
    var params = '';
    for (var i = 0; i < fileDesc.length; i++) {
      if (i == 0) {
        params += "fileDesc=" + fileDesc[i];
      } else {
        params += "&fileDesc=" + fileDesc[i];
      }

    }
    //console.log(files);
    let data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append('files', files[i]);
    }

    return this.http.post(this.baseurl + "/common/document/uploadMultipleFilesApi" + "?" + params, data, { headers: this.requestHeaderMultipartFormData }).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );
  }

  uploadMultipleImages(images: any[], imgOrder: any[]): Observable<any> {

    this.requestHeaderMultipartFormData = HeaderConfiguration.getHeaderConfigWithMultiPartConfiguration();

    var params = '';
    /* for(var i=0; i< imgOrder.length;i++){
      if(i==0){
        params+="imgOrder="+imgOrder[i];
      }else{
        params+="&imgOrder="+imgOrder[i];
      }
      } */

    let data = new FormData();
    for (var i = 0; i < images.length; i++) {
      data.append('imgOrder', imgOrder[i]);
      data.append('images', images[i]);
    }
    return this.http.post(this.baseurl + "/common/document/uploadMultipleImagesApi", data, { headers: this.requestHeaderMultipartFormData }).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );


  }



  downloadFile(fileKey): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.post(this.baseurl + "/common/document/downloadFilesApi?keyName=" + fileKey, { headers: this.requestHeader })
      .pipe(
        retry(1),
        catchError(this.handleHttpError)
      );
  }

  saveUploadedFileDtails(fileDetails: UploadedFileDetail[]): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.post(this.baseurl + "/common/document/saveFilesApi", fileDetails, { headers: this.requestHeader })
      .pipe(
        retry(1),
        catchError(this.handleHttpError)
      );
  }

  saveUploadedFileDtailsMoSide(fileDetails: UploadedFileDetail[]): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.post(this.baseurl + "/common/document/saveFilesMoSideApi", fileDetails, { headers: this.requestHeader })
      .pipe(
        retry(1),
        catchError(this.handleHttpError)
      );
  }

  saveUploadedImagesDtails(fileDetails: UploadedFileDetail[]): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.post(this.baseurl + "/common/document/saveImagesApi", fileDetails, { headers: this.requestHeader })
      .pipe(
        retry(1),
        catchError(this.handleHttpError)
      );
  }

  getUploadedFileDetails(formTypeId, formPk): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get(this.baseurl + "/common/document/getFilesApi?formTypeId=" + formTypeId + "&formPk=" + formPk, { headers: this.requestHeader }).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );
  }

  getUploadedFileDetailsMoSide(formTypeId, formPk): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get(this.baseurl + "/common/document/getFilesMoSideApi?formTypeId=" + formTypeId + "&formPk=" + formPk, { headers: this.requestHeader }).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );
  }


  getUploadedImageDetails(formTypeId, formPk): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get(this.baseurl + "/common/document/getImagesApi?formTypeId=" + formTypeId + "&formPk=" + formPk, { headers: this.requestHeader }).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );
  }

  getAllImagesURLByItemId(formTypeId, itemId): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get(this.baseurl + "/common/document/getImagesUrl?formTypeId=" + formTypeId + "&formPk=" + itemId, { headers: this.requestHeader }).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );
  }

  updateCompanyMasterLogo(companyLogo: string, companyPk: number): Observable<any> {
    return this.http.get(this.baseurl + "/common/updateCompanyMasterLogo?companyLogo=" + companyLogo + "&companyPk=" + companyPk).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );
  }
  /*Error handling*/
  private handleHttpError(error: HttpErrorResponse): Observable<ErrorMsg> {
    let dataError = new ErrorMsg();
    dataError.errorNumber = error.status;
    dataError.message = error.statusText;
    dataError.friendlyMsg = "An error occurred while retrieving data";
    return throwError(dataError);
  }

  updateCompanyMasterLogoByte(companyPk: number, files: any[]): Observable<any> {
    this.requestHeaderMultipartFormData = HeaderConfiguration.getHeaderConfigWithMultiPartConfiguration();
    let data = new FormData();

    data.append('companyLogo', files[0]);


    return this.http.post(this.baseurl + "/common/updateCompanyMasterLogoByte?companyPk=" + companyPk, data, { headers: this.requestHeaderMultipartFormData }).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );
  }

  downloadBulkuploadResult(companyCode: any, token: any) {

    window.open(this.baseurl + "/master/tempurl/downloadBulkuploadResult/" + companyCode + "/" + token, "new");
    //this.http.get(this.baseurl + "/master/downloadBulkuploadResult/" + id);

  }
 // common/getEbillVehicleType
 getEbillVehicleType(): Observable<any>{
 return this.http.get<any>(`${this.baseurl}/common/getEbillVehicleType`)
 }
 getEbillTransportMode(): Observable<any>{
  return this.http.get<any>(`${this.baseurl}/common/getEbillTransportMode`)
  }

}
