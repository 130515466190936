import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppComponent } from '../app.component';
import { ToastrService } from 'ngx-toastr';
import { User } from '../modules/core/model/model';
import { CustomValidator } from '../custom-validator';
import { SessionUpdateService } from '../session-update-confirmation/session-update.service';


@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  passwordForm: FormGroup;
  submitted: boolean = false;
  sub: any;
  userId: string = '';
  companyCode: string = '';
  currentUser: any = {};
  public fieldTextType: boolean = false;
  public confirmFieldTextType: boolean = false;
  constructor(public appComp: AppComponent, private toasterService: ToastrService, private formBuilder: FormBuilder, private ngxLoader: NgxUiLoaderService,
    private globalService: GlobalServiceService, private sessionUpdateService: SessionUpdateService,
     private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.ngxLoader.start();

    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.userId = params['userId'];
        this.companyCode = params['domainName'];
      });

    console.log("domain name: ", this.companyCode);
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, CustomValidator.passWordValidator,]],
      confirmPassword: ['', [Validators.required, CustomValidator.passWordValidator,]]
    }, {
      validator: this.mustMatch('password', 'confirmPassword')
    });
    this.ngxLoader.stop();
  }

  get f() { return this.passwordForm.controls; }


  onSubmit() {
    this.ngxLoader.start();
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      this.ngxLoader.stop();
      return;
    }

    this.globalService.changePassword(this.userId, this.passwordForm.value.password, this.companyCode)
      .pipe(first())
      .subscribe(
        data => {
          this.ngxLoader.stop();
          console.log("password changed ", data);

          this.globalService.logout().subscribe(
            (data: any) => {
              this.globalService.logoutSession();
              //this.router.navigate(['/login']);
              this.loginUser();
            },
            (err: any) => {
              console.log(err);
            }
          );

        },
        error => {
          this.ngxLoader.stop();
          alert('Please enter a valid username and password.');
        });
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.hasError('mustMatch')) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  loginUser() {
    var ref = this;
    this.currentUser.companyCode = this.companyCode;
    this.currentUser.userId = this.userId;
    this.currentUser.password = this.passwordForm.value.password;
    this.globalService.loginV2(this.currentUser)
      .subscribe(
        (data: any) => {

          console.log("login Data ", data);
          this.appComp.loggedInUser = data.data;
          let companyDetail: any = data.data;
          if (data.statusCode == 200) {
            localStorage.setItem('isCompanyExpired', data.data.companyExpired);

            if (data.data.changePassword == 1) {
              this.router.navigate(['/password-reset'], { queryParams: { userId: data.data.userId, domainName: data.data.companyCode } });
            }
            else {

              this.globalService.setCurrentUser2(data.data);
              let loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
              let userDetail: any = {
                "userId": loggedInUser.userId,
                "password": loggedInUser.password,
                "refreshToken": loggedInUser.tokenBean.refresh_token
              };

              sessionStorage.setItem('tokenFlag', JSON.stringify(true));
              this.globalService.setAccessToken(loggedInUser.tokenBean);

              setTimeout(function () {
                ref.router.navigate([`/financialYear/${data.data.pk}`]);
                setTimeout(function () {
                  //window.location.reload();
                }, 200);

              }, 400);

            }
          } else if (data.statusCode == 201) {
            try {
              this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
              this.ngxLoader.stop();
            } catch (e) {
              this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
              this.ngxLoader.stop();
            }
          } else if (data.statusCode == 202) {
            try {
              this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
              this.ngxLoader.stop();
            } catch (e) {
              this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
              this.ngxLoader.stop();
            }

          } else if (data.statusCode == 203) {
            try {
              this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
              this.ngxLoader.stop();
            } catch (e) {
              this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
              this.ngxLoader.stop();
            }

          } else if (data.statusCode == 204) {

            try {

              this.ngxLoader.stop();
              this.sessionUpdateService.confirm('Please confirm!', `${data.msg}`, `Do you really want continue ?`)
                .then((confirmed) => {
                  if (confirmed) {
                    this.ngxLoader.start();

                    this.globalService.updateUserSession(companyDetail.pk, companyDetail.companyCode).subscribe((data2) => {
                      console.log("updated session ", data2);

                      let updateSession = data2.data;

                      companyDetail.sessionId = updateSession.sessionId;

                      this.globalService.setCurrentUser2(companyDetail);
                      let loggedInUser = JSON.parse(localStorage.getItem("currentUser"));


                      sessionStorage.setItem('tokenFlag', JSON.stringify(true));
                      this.globalService.setAccessToken(loggedInUser.tokenBean);

                      setTimeout(function () {
                        ref.router.navigate([`/financialYear/${data.data.pk}`]);
                        setTimeout(function () {
                          //window.location.reload();
                        }, 200);

                      }, 400);
                    })

                  }
                })
                .catch(() => console.log('User dismissed the dialog'));

            } catch (e) {
              this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
              this.ngxLoader.stop();
            }

          }
          this.ngxLoader.stop();
        },
        (err) => {
          console.log(err);
          try {
            this.toasterService.error(err.statusCode, err.msg, { positionClass: 'toast-top-right' });
            this.ngxLoader.stop();
          } catch (e) {
            this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
            this.ngxLoader.stop();
          }
          this.ngxLoader.stop();
        }
      )
  }

  showPassword() {
    if (this.fieldTextType) {
      this.fieldTextType = false;
    } else {
      this.fieldTextType = true;
    }

  }
  confirmShowPassword() {
    if (this.confirmFieldTextType) {
      this.confirmFieldTextType = false;
    } else {
      this.confirmFieldTextType = true;
    }

  }

}
