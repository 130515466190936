<div class="modal-header">
  <h4 class="modal-title">Tathata</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body ">
  <p>Successfully Saved.&hellip;</p>
</div>
<div class="modal-footer" style="text-align: right;">
  <button ejs-button class="save button_padding button_margin buttonSize" type="button" (click)="closeModal()">OK</button>
</div>
