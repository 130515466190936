import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';

import { Menu } from '../modules/navbar/models/menu';
import { environment } from 'src/environments/environment';
import { HeaderConfiguration } from '../headerConfiguration';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  baseurl = environment.apiUrl;
  serverIp = environment.apiUrl;
  //requestHeader = HeaderConfiguration.headerConfg;

  requestHeader = HeaderConfiguration.getHeaderConfiguration();

  rowData: any;

  updateval;
  _url = this.baseurl;
  _url1 = this.baseurl;
  //CHANGE FOR ADD COLOR  _url ="http://13.234.36.44:7777/";




  constructor(private http: HttpClient) {
    console.log("dataservice.service.ts")
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
  }

  // enroll(user: User, url, basicurl) {
  //   return this.http.post<any>(this._url + basicurl, user)

  //     .pipe(catchError(this.errorHandler))
  // }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }

  getMenu(menuVal1) {

    return fetch(this.serverIp + menuVal1)

  }
  getGrid(url) {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    this.updateval = this.serverIp + url;
    this.rowData = this.http.get(this.updateval, { headers: this.requestHeader });
    return this.rowData
  }

  getCatagory(baseUrl) {
    fetch(this.baseurl + "/" + baseUrl);
  }
  //Sidebar Implementation
  getAllMenu(userPk:number): Observable<Menu[]> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    console.log(this.requestHeader.keys());
    return this.http.get<Menu[]>(`${this.baseurl}/common/role/moduleListByUserPk?userPk=${userPk}`,{ headers: this.requestHeader});
  }

  getAllSubmenu(menuId,userPk): Observable<any[]> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();

    return this.http.get<any>(`${this.baseurl}/common/role/subModuleListByRole?modulePk=${menuId}`, { headers: this.requestHeader });
  }



}
