import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SuccessMsgModalComponent } from './success-msg-modal/success-msg-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SizeConfigComponent } from './size-config/size-config.component';
import { StateSalePopupComponent } from './state-sale-popup/state-sale-popup.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { CustomListboxModule } from "../shared/custom-listbox/custom-listbox.module";
import { SalesOrderPopupComponent } from './sales-order-popup/sales-order-popup.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalContentComponent } from './modal-content/modal-content.component';
import { DataTablesModule } from 'angular-datatables';
import { ChooseOperationComponent } from './choose-operation/choose-operation.component';
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import { ModalManufactureContentPopupComponent } from './modal-manufacture-content-popup/modal-manufacture-content-popup.component';
import { ModalInventoryContentPopupComponent } from './modal-inventory-content-popup/modal-inventory-content-popup.component';
import { ModalMultipleFileuploadPopupComponent } from './modal-multiple-fileupload-popup/modal-multiple-fileupload-popup.component';
import { CommonCommentsComponent } from './common-comments/common-comments.component';
import { BomPopupComponent } from './bom-popup/bom-popup.component';
import { ModalMultipleImageuploadPopupComponent } from './modal-multiple-imageupload-popup/modal-multiple-imageupload-popup.component';
import { ModalInventoryBundlePopupComponent } from './modal-inventory-bundle-popup/modal-inventory-bundle-popup.component';
import { ModalInventoryVendorPriceListPopUpComponent } from './modal-inventory-vendor-price-list-pop-up/modal-inventory-vendor-price-list-pop-up.component';
import { IssueForJobWorkPopComponent } from './issue-for-job-work-pop/issue-for-job-work-pop.component';
import { ReturnVendorPopupComponent } from './return-vendor-popup/return-vendor-popup.component';
import { ModalWithConfigitemComponent } from './modal-with-configitem/modal-with-configitem.component';
import { ModelcontentPopComponent } from './modelcontent-pop/modelcontent-pop.component';
import { VendorPriceComponent } from './vendor-price/vendor-price.component';
import { ModalVariantsComponent } from './modal-variants/modal-variants.component';
import { TreeGridAllModule, TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import { GridModule, GridAllModule } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { DropDownListModule, MultiSelectModule, ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { ChipListModule, CheckBoxModule, ButtonModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { ModalContentComponentManufecturingComponent } from './modal-content-component-manufecturing/modal-content-component-manufecturing.component';
import { UpdateShippingDetailComponent } from './update-shipping-detail/update-shipping-detail.component';
import { ListBoxAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { ReqManufacturingPopupComponent } from './req-manufacturing-popup/req-manufacturing-popup.component';
import { TabModule, ToolbarAllModule, ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ResMoVariantCreationPopupComponent } from './res-mo-variant-creation-popup/res-mo-variant-creation-popup.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ResMoModalColorVariantComponent } from './res-mo-modal-color-variant/res-mo-modal-color-variant.component';
import { ResMoModalVariantComponent } from './res-mo-modal-variant/res-mo-modal-variant.component';
import { PdfSizeOrientationPopupComponent } from './pdf-size-orientation-popup/pdf-size-orientation-popup.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { VirtualStockPopupComponent } from './virtual-stock-popup/virtual-stock-popup.component';
import { LogoUploadPopupComponent } from './logo-upload-popup/logo-upload-popup.component';
import { CopyItemPopupComponent } from './copy-item-popup/copy-item-popup.component';
import { UserProfileUploadPopupComponent } from './user-profile-upload-popup/user-profile-upload-popup.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ItemDeleteErrorPopupComponent } from './item-delete-error-popup/item-delete-error-popup.component';
import { PdfPackingdetailHierarchicalPopupComponent } from './pdf-packingdetail-hierarchical-popup/pdf-packingdetail-hierarchical-popup.component';
import { PdfSizeOrientationCollapseExpandPopupComponent } from './pdf-size-orientation-collapse-expand-popup/pdf-size-orientation-collapse-expand-popup.component';
import { FotgetPasswordComponent } from './fotget-password/fotget-password.component';
import { NewModalContentComponent } from './new-modal-content/new-modal-content.component';
import { ZohoConfirmComponent } from './zoho-confirm/zoho-confirm.component';
import { VariantsPopupComponent } from './variants-popup/variants-popup.component';
import { BulkUploadPopupComponent } from './bulk-upload/bulk-upload.component';
import { ShowBulkDataComponent } from './show-bulk-data/show-bulk-data.component';
import { ItemLocationDropdownPopupComponent } from './item-location-dropdown-popup/item-location-dropdown-popup.component';
import { ResetPasswordPopupComponent } from './reset-password-popup/reset-password-popup.component';
import { PdfSizePopupComponent } from './pdf-size-popup/pdf-size-popup.component';
import { ReqManufacturingErrorPopupComponent } from './req-manufacturing-error-popup/req-manufacturing-error-popup.component';
import { MailSuccessMsgComponent } from './mail-success-msg/mail-success-msg.component';
import { ModalAuth500errorComponent } from './modal-auth500error/modal-auth500error.component';
import { MailPopupComponent1 } from './mail-popup/mail-popup.component';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ItemWithBarcodePopupComponent } from './item-with-barcode-popup/item-with-barcode-popup.component';
import { ModalMultipleProductionDocUploadComponent } from './modal-multiple-production-doc-upload/modal-multiple-production-doc-upload.component';
import { BulkUploadErrorDisplayComponent } from './bulk-upload-error-display/bulk-upload-error-display.component';
import { ObservationPopupComponent } from './observation-popup/observation-popup.component';
import { WooCommercePopupComponent } from './woo-commerce-popup/woo-commerce-popup.component';
import { ErrorInterceptorProviders, fakeBackendProvider, httpInterceptorProviders } from 'src/app/_helpers';
import { CommonModalItemPopupComponent } from './common-modal-item-popup/common-modal-item-popup.component';
import { CommonModelPopupItemMfrgComponent } from './common-model-popup-item-mfrg/common-model-popup-item-mfrg.component';
import { TaxAndExtrachargeCalculationComponent } from './tax-and-extracharge-calculation/tax-and-extracharge-calculation.component';
import { TaxPopupComponent } from './tax-popup/tax-popup.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { QuantityDecimalValidatorDirective } from './qty-and-amount-validator-directive/quantity-decimal-validator.directive';
import { RateAndDiscountDecimalValidatorDirective } from './qty-and-amount-validator-directive/rate-and-discount-decimal-validator.directive';
@NgModule({
  declarations:
    [
      SuccessMsgModalComponent,
      SizeConfigComponent,
      StateSalePopupComponent,
      SalesOrderPopupComponent,
      ModalContentComponent,
      ChooseOperationComponent,
      CommentModalComponent,
      ModalManufactureContentPopupComponent,
      ModalInventoryContentPopupComponent,
      ModalMultipleFileuploadPopupComponent,
      CommonCommentsComponent,
      BomPopupComponent,
      ModalMultipleImageuploadPopupComponent,
      ModalContentComponent,
      ModalInventoryBundlePopupComponent,
      ModalInventoryVendorPriceListPopUpComponent,
      IssueForJobWorkPopComponent,
      ReturnVendorPopupComponent,
      ModalWithConfigitemComponent,
      ModelcontentPopComponent,
      VendorPriceComponent,
      ModalVariantsComponent,
      ModalContentComponentManufecturingComponent,
      UpdateShippingDetailComponent,
      ReqManufacturingPopupComponent,
      ResMoVariantCreationPopupComponent,
      ResMoModalColorVariantComponent,
      ResMoModalVariantComponent,
      PdfSizeOrientationPopupComponent,
      VirtualStockPopupComponent,
      LogoUploadPopupComponent,
      NewModalContentComponent,
      CopyItemPopupComponent,
      UserProfileUploadPopupComponent,
      ItemDeleteErrorPopupComponent,
      PdfPackingdetailHierarchicalPopupComponent,
      PdfSizeOrientationCollapseExpandPopupComponent,
      FotgetPasswordComponent,
      NewModalContentComponent,
      ZohoConfirmComponent,
      VariantsPopupComponent,
      BulkUploadPopupComponent,
      ShowBulkDataComponent,
      ItemLocationDropdownPopupComponent,
      ResetPasswordPopupComponent,
      PdfSizePopupComponent,
      ReqManufacturingErrorPopupComponent,
      MailSuccessMsgComponent,
      ModalAuth500errorComponent,
      MailPopupComponent1,
      ItemWithBarcodePopupComponent,
      ModalMultipleProductionDocUploadComponent,
      BulkUploadErrorDisplayComponent,
      ObservationPopupComponent,
      WooCommercePopupComponent,
      CommonModalItemPopupComponent,
      CommonModelPopupItemMfrgComponent,
      TaxAndExtrachargeCalculationComponent,
      TaxPopupComponent,
      QuantityDecimalValidatorDirective,
      RateAndDiscountDecimalValidatorDirective
    ],

  imports: [
    CommonModule,
    CustomListboxModule,
    DataTablesModule,
    NgbModule,
    UiSwitchModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AngularDualListBoxModule,
    GridModule,
    GridAllModule, DropDownListModule,
    MultiSelectModule,
    ChipListModule,
    BsDatepickerModule.forRoot(),
    ListBoxAllModule,
    TreeGridModule,
    TreeGridAllModule, CheckBoxModule,
    ComboBoxModule,
    DropDownListModule,
    ButtonModule,
    GridModule,
    GridAllModule, MultiSelectModule,
    ChipListModule,
    TabModule,
    RadioButtonModule,
    SwitchModule,
    ToolbarModule,
    ToolbarAllModule,
    DatePickerModule,
    NgxUiLoaderModule,
    RichTextEditorModule,
    PipesModule,
    PipesModule
  ],

  providers: [
    PageService, SortService, FilterService, GroupService,
    httpInterceptorProviders,
    ErrorInterceptorProviders,
    fakeBackendProvider],

  exports: [
    SuccessMsgModalComponent,
    ModalContentComponentManufecturingComponent,
    IssueForJobWorkPopComponent,
    AngularDualListBoxModule,
    NgbModule, FormsModule,
    NgSelectModule,
    SizeConfigComponent,
    SalesOrderPopupComponent,
    BsDatepickerModule,
    ModalContentComponent,
    VendorPriceComponent,
    ChooseOperationComponent,
    CommentModalComponent,
    ModalManufactureContentPopupComponent,
    UpdateShippingDetailComponent,
    ModalInventoryContentPopupComponent,
    ShowBulkDataComponent,
    ModalMultipleFileuploadPopupComponent,
    BomPopupComponent,
    ReqManufacturingPopupComponent,
    CommonCommentsComponent,
    ModalMultipleImageuploadPopupComponent,
    ModalContentComponent,
    ModalInventoryBundlePopupComponent,
    VariantsPopupComponent,
    ModalInventoryVendorPriceListPopUpComponent,
    ModalWithConfigitemComponent,
    ModelcontentPopComponent,
    ResMoVariantCreationPopupComponent,
    ResMoModalColorVariantComponent,
    ResMoModalVariantComponent,
    PdfSizeOrientationPopupComponent,
    VirtualStockPopupComponent,
    NewModalContentComponent,
    LogoUploadPopupComponent,
    CopyItemPopupComponent,
    UserProfileUploadPopupComponent,
    ItemDeleteErrorPopupComponent,
    PdfPackingdetailHierarchicalPopupComponent,
    PdfSizeOrientationCollapseExpandPopupComponent,
    FotgetPasswordComponent,
    ZohoConfirmComponent,
    BulkUploadPopupComponent,
    ResetPasswordPopupComponent,
    ItemLocationDropdownPopupComponent,
    PdfSizePopupComponent,
    ReqManufacturingErrorPopupComponent,
    MailSuccessMsgComponent,
    ModalAuth500errorComponent,
    ItemWithBarcodePopupComponent,
    ModalMultipleProductionDocUploadComponent,
    ItemWithBarcodePopupComponent,
    BulkUploadErrorDisplayComponent,
    ObservationPopupComponent,
    CommonModalItemPopupComponent,
    TaxAndExtrachargeCalculationComponent,
    TaxPopupComponent,
    QuantityDecimalValidatorDirective,
    RateAndDiscountDecimalValidatorDirective
  ],

  entryComponents: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
