<div class="modal-dialog modal-confirm">
<div class="modal-content">
  <div class="modal-header">
    <div class="icon-box">
      <i class="material-icons"></i>
    </div>				
    <h4 class="modal-title">Awesome!</h4>	
  </div>
  <div class="modal-body">
    <p class="text-center">{{pageMessage}}</p>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success btn-block" (click)="okBtn()">OK</button>
  </div>
</div>
</div>