import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HeaderConfiguration } from 'src/app/headerConfiguration';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientCompanyService {

  baseurl = environment.apiUrl;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();
  constructor(private http: HttpClient) {
    console.log("client-company.service.ts");
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
  }

  getCompanyList(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/companyList`);
  }

  getUserListByDomainName(domainName: string): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/allUserListForAdminLogin?companyCode=${domainName}`);
  }

  checkLoginFromAdmin(companyCode: string, userId: string): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/checkLoginFromAdmin?userId=${userId}&companyCode=${companyCode}`);
  }
  getCompanyListForAdmin(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/companyListForAdmin`);
  }
  checkLoginV2FromAdmin(companyCode: string, userId: string): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/v2/checkLoginFromAdmin?userId=${userId}&companyCode=${companyCode}`);
  }
}
