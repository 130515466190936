<div class="modal-header z-conform">
  <h4 class="modal-title" style="text-align: center;margin: 0px auto;">
    Change Password
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body z-conform">

  <div class="container-fluid zoho-confirm-box">
    <form [formGroup]="userForm" class="LoginContainer">
      <h2 class="loginTitle" style="text-align: center;font-size: 25px;">Enter User Details</h2>
      <div class="form-group" style="margin:0px; padding-bottom:10px">

          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-6">
              <div class="form-group justify-content-center align-items-center e-float-input" style="background:#eef4f7;">
                <label for="userName">Domain Name</label>
                <input type="text" formControlName="companyCode" class="form-control" style="background: #fff !important;"
                  placeholder="Please enter domain name "
                  [ngClass]="{'is-invalid': (f['companyCode'].touched || f['companyCode'].dirty) && !f['companyCode'].valid }" />
                <span class="e-float-line width300"></span>
                <div class="invalid-feedback">
                  <div *ngIf="f['companyCode'].hasError('required')">Username is required</div>
                  <div *ngIf="f['companyCode'].hasError('maxlength')">Maximum 50 characters are allowed.</div>
                </div>
              </div>
            </div>
            <div class="col-sm-3"></div>
          </div>

          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-6">
              <fieldset>
                <div class="form-group justify-content-center align-items-center e-float-input" style="background:#eef4f7;">
                  <label for="userId">User Id / Login Id</label>
                  <input type="text" formControlName="userId" class="form-control"
                    placeholder="Please enter emailid for userId " style="background: #fff !important;"
                    [ngClass]="{'is-invalid': (f['userId'].touched || f['userId'].dirty) && !f['userId'].valid }"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" autocomplete="off">
                  <span class="e-float-line width300" ></span>
                  <div class="invalid-feedback">
                    <div *ngIf="f['userId'].hasError('required')">User Id is required.</div>
                    <div *ngIf="f['userId'].hasError('minlength')">User Id must at least 2 characters.</div>
                    <div *ngIf="f['userId'].hasError('maxlength')">User Id allowed maximum 100 characters.</div>
                    <div *ngIf="f['userId'].hasError('pattern')">User Id must be in valid format.</div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-sm-3"></div>
          </div>


      </div>

    </form>
  </div>
</div>
<div class="modal-footer z-conform">

  <div class="container-fluid">

    <div class="form-group">
      <div class="row">
        <div class="col-sm-12" style="text-align: center;">
          <button ejs-button cssClass="" class="done button_padding button_margin buttonSizeZ " type="button"
            (click)="done()">Submit</button>
          <button ejs-button cssClass="" class="back button_padding button_margin buttonSizeZ " type="button"
            (click)="closeModal()"> Back</button>
        </div>
      </div>
    </div>



  </div>

</div>
