
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { UpladServicesService } from "../../../modules/shared/sharedServices/uplad-services.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-user-profile-upload-popup',
  templateUrl: './user-profile-upload-popup.component.html',
  styleUrls: ['./user-profile-upload-popup.component.css'],
  providers: [NgxUiLoaderService],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileUploadPopupComponent implements OnInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  @Input() public modalData;
  @Output() itemData: EventEmitter<any> = new EventEmitter();
  selectedItem: any = [];
  documentUploadForm: FormGroup;
  formTypeId: number = 0;

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private uploadService: UpladServicesService, private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit() {
    // Image Uploading Form
    this.ngxLoader.start();
    this.documentUploadForm = this.formBuilder.group({
      documents: this.formBuilder.array([
        this.adddocumentsFormGroup()
      ])
    });
    this.ngxLoader.stop();

  }

  adddocumentsFormGroup(): FormGroup {
    return this.formBuilder.group({
      docDescription: [1],
      document: ['']
    });
  }

  ngAfterViewInit(): void {

  }

  closeModal() {
    this.activeModal.close();
    this.documentUploadForm.reset();
  }

  updateIconsClass(ext) {

    if (ext.toLowerCase() == ".png" || ext.toLowerCase() == ".jpeg" || ext.toLowerCase() == ".jpg" || ext.toLowerCase() == ".gif" || ext.toLowerCase() == ".tiff") {
      return "fa-file-image-o";
    } else if (ext.toLowerCase() == ".pdf") {
      return "fa-file-pdf-o"
    } else if (ext.toLowerCase() == ".xlsx") {
      return "fa-file-excel-o"
    } else if (ext.toLowerCase() == ".docx") {
      return "fa-file-word-o"
    } else if (ext.toLowerCase() == ".sql") {
      return "fa-database";
    } else if (ext.toLowerCase() == ".txt") {
      return "fa-file-text-o"
    }

  }

  getFileTitle(ext) {

    if (ext.toLowerCase() == ".png" || ext.toLowerCase() == ".jpeg" || ext.toLowerCase() == ".jpg" || ext.toLowerCase() == ".gif" || ext.toLowerCase() == ".tiff") {
      return "Image file";
    } else if (ext.toLowerCase() == ".pdf") {
      return "Pdf file"
    } else if (ext.toLowerCase() == ".xlsx") {
      return "Excel file"
    } else if (ext.toLowerCase() == ".docx") {
      return "Forld file"
    } else if (ext.toLowerCase() == ".sql") {
      return "Sql file";
    } else if (ext.toLowerCase() == ".txt") {
      return "Test file"
    }

  }

  /*Image Uploading Demo*/
  fileList: any[] = [];
  fileSizeValidations: any[] = [{ "size": 0 }];
  imageSizeValidationFlag: boolean = false;
  fileType: string = '';
  imageTypevalidation: boolean = false;
  uploadFileOnChange(event, index: number) {
    this.imageSizeValidationFlag = false;
    this.imageTypevalidation = false;
    this.fileList.push(event.target.files[0]);
    console.log("image ", event.target.files[0]);
    var fileSize = event.target.files[0].size / 1024 / 1024;
    this.fileSizeValidations[index].size = fileSize;
    console.log("file size ", this.fileSizeValidations[index].size);
    this.fileType = event.target.files[0].type;

    (<FormArray>this.documentUploadForm.get('documents')).at(index).value.document = event.target.files[0];
    if (this.fileType == "image/png" || this.fileType == "image/jpeg" || this.fileType == "IMAGE/PNG" || this.fileType == "IMAGE/JPEG") {
      this.imageTypevalidation = false;
      console.log("file type ", this.fileType);
    } else {
      this.imageTypevalidation = true;
      console.log("file type2 ", this.fileType);
    }
    this.fileSizeValidations.forEach(element => {
      if (element.size > 10) {
        this.imageSizeValidationFlag = true;
      }
    });
  }



  addDocument(): void {
    (<FormArray>this.documentUploadForm.get('documents')).push(this.adddocumentsFormGroup());
    this.fileSizeValidations.push({ "size": 0 });
  }

  deleteFile(index: number) {
    this.imageSizeValidationFlag = false;
    (<FormArray>this.documentUploadForm.get('documents')).removeAt(index);
    this.fileSizeValidations.splice(index, 1);
    this.fileSizeValidations.forEach(element => {
      if (element.size > 10) {
        this.imageSizeValidationFlag = true;
      }
    });

    if ((<FormArray>this.documentUploadForm.get('documents')).length == 0) {
      (<FormArray>this.documentUploadForm.get('documents')).push(this.adddocumentsFormGroup());
    }
  }

  addDescriptionToImage(index: number) {
    (<FormArray>this.documentUploadForm.get('documents')).at(index).value.document = this.fileList[index];
  }

  files: any[] = [];
  fileDesc: any[] = [];
  tempFileCollection: any[] = [];
  multipleFilesRes: any[] = [];
  fileDownLoadUrl: string;
  validation: any[] = [];
  numValid: any[] = [];
  fileValid: any[] = [];
  isValid: boolean;
  uploadFilesOnServer(docsForm: FormGroup) {
    this.ngxLoader.start();
    this.files = [];
    this.fileDesc = [];
    this.isValid = true;
    this.tempFileCollection = docsForm.get('documents').value;

    for (var i = 0; i < this.tempFileCollection.length; i++) {
      this.validation[i] = false;
      this.numValid[i] = false;
      this.fileValid[i] = false;

      if (this.tempFileCollection[i].docDescription.length == 0) {
        this.validation[i] = true;
        this.isValid = false;
      }

      if (this.tempFileCollection[i].docDescription.length > 0) {
        this.checkNumeric(this.tempFileCollection[i].docDescription, i);
      }

      if (this.tempFileCollection[i].document == undefined) {
        this.fileValid[i] = true;
        this.isValid = false;
      }

      this.files.push(this.tempFileCollection[i].document);
      this.fileDesc.push(this.tempFileCollection[i].docDescription);
    }

    if (this.multipleFilesRes.length == 0) {
      this.multipleFilesRes = [];
    }



    if (this.isValid) {
      this.uploadService.uploadMultipleImages(this.files, this.fileDesc).subscribe((data) => {
        console.log("upload res ", data);
        this.ngxLoader.stop();
        data.forEach(element => {
          this.multipleFilesRes.push(element);
          this.itemData.emit(this.multipleFilesRes);
          this.activeModal.close();
        });

      }, (error) => {
        console.log(error);
        this.ngxLoader.stop();

      });
    } else {
      this.ngxLoader.stop();
    }
  }

  checkNumeric(value: String, index: number) {
    for (let x = 0; x < value.length; x++) {
      if ((value.charCodeAt(x) < 47 || value.charCodeAt(x) > 58)) {
        this.numValid[index] = true;
        this.isValid = false;
      }
    }
  }

  downloadUploadedFile(fileKey) {
    this.uploadService.downloadFile(fileKey).subscribe((data) => {
      this.fileDownLoadUrl = data;

      window.open(this.fileDownLoadUrl, "new");
    }, (error) => {
      console.log(error);
    });
  }

  deleteFileDetail(index) {
    this.multipleFilesRes.splice(index, 1);
  }

  done() {
    this.itemData.emit(this.multipleFilesRes);
    this.activeModal.close();
  }

  getUploadedFileDetails(formTypeId, formPk) {
    this.uploadService.getUploadedImageDetails(formTypeId, formPk).subscribe((data) => {
      if (data.data != null) {
        this.multipleFilesRes = data.data;
      } else {
        this.multipleFilesRes = [];
      }
    }, (error) => {
      console.log(error);
    });
  }


  getAllControls(form) {
    return form.get('documents').controls;
  }


}

