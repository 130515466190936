<div class="modal-header">
  <h4 class="modal-title">Auth Token Error</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="color:red; text-align: center ;">
  There is some error in getting token. Please try again later. To raise an issue with us, <a
    href="https://tathata-erp-development.atlassian.net/servicedesk/customer/portal/2/group/3/create/17"
    target="new">click here.</a>
</div>
<div class="modal-footer">
  <button ejs-button cssClass="" class="close1 button_padding button_margin buttonSize right" type="button"
    (click)="closeModal()">Close</button>
  
</div>