import { Injectable } from "@angular/core";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CanDeactivateConfirmDialogComponent } from "./can-deactivate-confirm-dialog.component";



@Injectable({ providedIn: "root" })
export class CanConfirmationDialogService {
    constructor(private modalService: NgbModal) { }

    public confirm(
        title: string,
        message: string,
        btnOkText: string = "OK",
        btnCancelText: string = "Cancel",
        dialogSize: "sm" | "lg" = "sm"
    ): Promise<boolean> {
        const modalRef = this.modalService.open(CanDeactivateConfirmDialogComponent, {
            size: dialogSize
        });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;

        return modalRef.result;
    }
}
