import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { HeaderConfiguration } from 'src/app/headerConfiguration';
import { DataStateChangeEventArgs, Sorts, PredicateModel } from '@syncfusion/ej2-grids';
import { Predicate, DataResult } from '@syncfusion/ej2-data';
import { map } from 'rxjs/operators';
import { CommonVariables } from 'src/app/commonVariables';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PoFullfilmentService extends Subject<DataStateChangeEventArgs>{
  baseurl = environment.apiUrl;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();
  constructor(private http: HttpClient) {
    console.log("service constructor called");
    super();
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
  }


  getPurchaseOrderFulfillmentDateById(id: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/inventory/getPurchaseOrderFulfillmentDateById?id=${id}`);
  }

  createPurchaseOrderFulfillmentDate(fullfilmentData: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/inventory/createPurchaseOrderFulfillmentDate`, fullfilmentData);
  }

  public execute(state: any, grid: any, choiceFilter: boolean): void {
    this.getPoFullFilmentDateGrid(state, grid, choiceFilter).subscribe(x => super.next(x as DataStateChangeEventArgs));
  }
  mailTemplateMasterByPk(pk): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseurl}/inventory/filledMailTemplatePurchaseOrder?id=${pk}`);
  }

  sendMailPurchaseOrder(id,mailId): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseurl}/inventory/sendMailPurchaseOrder?id=${id}&emailIds=${mailId}`);
  }

  generateShortUrl(url,mailId): Observable<any[]> {
    return this.http.post<any[]>(`${this.baseurl}/notification/url/generateShortUrl?url=${url}&emailIds=${mailId}`,0);
  }

  sendMail( formId, email, bean): Observable<any[]> {
    let reqParam = {'formId' : formId,'emailIds' : email};
    return this.http.post<any[]>(`${this.baseurl}/notification/mail/sendMail`,bean, { params: reqParam });
  }

  getShortUrlDetails(formId ,urlToken,emailIds): Observable<any[]> {
    let reqParam = {'urlToken' : urlToken,'formId' : formId ,'emailIds' : emailIds};
    return this.http.get<any[]>(`${this.baseurl}/notification/url/getShortUrlDetails`,{ params: reqParam });
  }

  public childDataSource: any[] = [];
  getPoFullFilmentDateGrid(state: any, grid: any, choiceFilter): Observable<any[]> {
    let childDataSource = [];
    let sortQuery: String = '';
    const skipquery = state.skip ? `skip=${state.skip}` : null;
    let pageQuery = '';
    const takeQuery = state.take ? `top=${state.take}` : null;
    if (skipquery) {
      pageQuery = `${skipquery}&`;
    }
    if (takeQuery) {
      pageQuery = `${pageQuery}${takeQuery}`;
    }
    let filterQuery: String = '';
    if ((state.sorted || []).length) {
      sortQuery = `&orderby=` + state.sorted.map((obj: Sorts) => {
        return obj.direction === 'descending' ? `${obj.name} desc` : obj.name;
      }).reverse().join(',');
    }

    if (state.where) {
      filterQuery = `&filter=` + state.where.map((obj: PredicateModel) => {
        return (<Predicate>obj).predicates.map((predicate) => {
          console.log('1-Field => ' + predicate.field);
          console.log('2-Operator => ' + predicate.operator);
          console.log('3-Value => ' + predicate.value);
          console.log("predicates:", (<any>predicate).predicates);

          if ((<any>predicate).predicates) {
            var returnStr = '';

            for (var i = 0; i < (<any>predicate).predicates.length; i++) {
              if ((<any>predicate).predicates[i].condition != undefined && (<any>predicate).predicates[i].condition == "and") {
                for (var j = 0; j < (<any>predicate).predicates[i].predicates.length; j++) {
                  if (!(<any>predicate).predicates[i].predicates[j].field || !(<any>predicate).predicates[i].predicates[j].operator || !(<any>predicate).predicates[i].predicates[j].value) {
                    returnStr += "";
                  } else {
                    returnStr += (<any>predicate).predicates[i].predicates[j].field + "$" + (<any>predicate).predicates[i].predicates[j].operator + "$" + (<any>predicate).predicates[i].predicates[j].value;
                    returnStr += ",";
                  }

                }
              } else {
                returnStr += (<any>predicate).predicates[i].field + "$" + (<any>predicate).predicates[i].operator + "$" + (<any>predicate).predicates[i].value;
                if (i != (<any>predicate).predicates.length - 1) {
                  returnStr += ",";
                }
              }
            }
            console.log("strReturn: " + returnStr);
            return returnStr;
            /* if (<any>grid.getColumnByField((<any>predicate).predicates[0].field).type === 'date') {
              return `((${(<any>predicate).predicates[0].field} gt ${(<any>predicate).predicates[0].value}) and (${(<any>predicate).predicates[1].field} lt ${(<any>predicate).predicates[1].value}))`;
            } */
          }
          else {
            return predicate.field + '$' + predicate.operator + '$' + predicate.value;

          }
        }).reverse().join(',');
      });
    }
    console.log('==============================');
    console.log(`${this.baseurl}/inventory/PurchaseOrderFulfillmentDateGrid?${pageQuery}${sortQuery}${filterQuery}&$count=true&choiceFilter=${choiceFilter}`, { headers: this.requestHeader });
    let url = `${this.baseurl}/inventory/PurchaseOrderFulfillmentDateGrid?${pageQuery}${sortQuery}${filterQuery}&$count=true&choiceFilter=${choiceFilter}`;
    return this.http.get<any[]>(decodeURIComponent(url), { headers: this.requestHeader })
      .pipe(map((response: any) => response.data))
      .pipe(map((response: any) => {
        // console.log("SO collection in service: ", response);
        let i = 0;
        let totalOrderAmount = 0;
        if (response != null) {

          response["data"].forEach((item) => {
            item['soId'] = item.id;
            item.deliveryDate = CommonVariables.convertStrToDate(item.deliveryDate);
            item.purchaseOrderDate = CommonVariables.convertStrToDate(item.purchaseOrderDate);
            if (item.vendorConfirmedDD == null) {
              item.vendorConfirmedDD = '';
            } else {
              item.vendorConfirmedDD = CommonVariables.convertStrToDate(item.vendorConfirmedDD);
            }
            if (item.soOrderDate == null) {
              item.soOrderDate = '';
            } else {
              item.soOrderDate = CommonVariables.reverseStrDate(item.soOrderDate);
              item.soOrderDate = CommonVariables.convertStrToDate(item.soOrderDate);
            }

            item.purchaseOrderItemBean.forEach((element) => {
              element["soId"] = item.id;
              if (element.itemType != 'Configurable') {
                element['atrributes'] = element.itemType;
              } else {
                element['atrributes'] = CommonVariables.attributesForPdf(element);
              }
              childDataSource.push(element);
            });
            totalOrderAmount += item.orderAmount;
            // item.orderAmount = CommonVariables.convertNumberInIndianFormat(item.orderAmount);

          });

          this.setChildDataSource(childDataSource);
          this.setOrderAmount(totalOrderAmount);
          this.setTotalOrderAmount(response['totalOrderAmount']);
        }
        console.log("po fullfilment collection in service: ", response);
        return state.dataSource === undefined ? (<DataResult>{
          result: response['data'],
          count: parseInt(response['count']),
        }) : response['data'];
      }))
      .pipe(map((data: any) => data));
  }

  getchildDataSouce() {
    return this.childDataSource;
  }
  setChildDataSource(data) {
    this.childDataSource = data;
  }


  grandTotal: number = 0;
  setTotalOrderAmount(total) {
    this.grandTotal = total;
  }

  getTotoalOrderAmount() {

    let total = Number(this.grandTotal).toFixed(2);
    var n1, n2;
    total = total + '' || '';
    // works for integer and floating as well
    n1 = total.split('.');
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    total = n2 ? n1 + '.' + n2 : n1;
    return total;

    /* let total = Number((this.grandTotal).toFixed(2));
    return Intl.NumberFormat('en-IN').format(total); */
  }

  TotalOrderAmount: number = 0;
  setOrderAmount(total) {
    this.TotalOrderAmount = total;
  }

  getOrderAmount() {

    let total = Number(this.TotalOrderAmount).toFixed(2);
    var n1, n2;
    total = total + '' || '';
    // works for integer and floating as well
    n1 = total.split('.');
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    total = n2 ? n1 + '.' + n2 : n1;
    return total;


  }

  sendCompanyMail(formId, email, bean): Observable<any[]> {
    let reqParam = { 'formId': formId, 'emailIds': email };
    return this.http.post<any[]>(`${this.baseurl}/notification/mail/sendCompanyMail`, bean, { params: reqParam });
  }


}
