<form [formGroup]="finacilayearForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <div class="row">
      <div class="col-sm-12">
        <h3 style="text-align: center;">Create Financial Year</h3>
      </div>
    </div>
  </div>
  <div class="form-group">

    <div class="row">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-4" style="text-align: right;">
        <label><b>Choose start month:</b></label>
      </div>
      <div class="col-sm-7">
        <div class="e-float-input">
          <ejs-dropdownlist id='ddlelement' #samples [dataSource]='months' formControlName="month"
            [fields]='stateFields' class="form-control" [placeholder]='placeholder2' [allowFiltering]='true'
            (click)="setAttribute()" (change)="changeMonth($event)">
          </ejs-dropdownlist>

          <div ngIf="f['stateId'].errors" * class="invalid-feedback">
            <div *ngIf="f['stateId'].hasError('required')">State is required</div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card-header">

    <button ejs-button cssClass="" class="submit button_padding button_margin buttonSize"
      [disabled]="!finacilayearForm.valid" type="submit">Submit</button>
    <button type="button" (click)="navigateToFinacilayear()" ejs-button cssClass=""
      class="back button_padding button_margin buttonSize"><i class="fa fa-arrow-left"></i>
      back</button>

  </div>

</form>
<ngx-ui-loader></ngx-ui-loader>
