import { Component, OnInit } from '@angular/core';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { AppComponent } from '../app.component';
import { ConfirmationDialogService } from '../confirmation-dailog/confirmation-dialog.service';
import { ExpiryDialogBoxService } from '../expiry-account-dialog-box/expiry-dialog-box.service';
import { DataService } from '../dataservices/dataservice.service';
@Component({
  selector: 'app-user-facility',
  templateUrl: './user-facility.component.html',
  styleUrls: ['./user-facility.component.css']
})
export class UserFacilityComponent implements OnInit {
  facilityList: any = [];
  financialYearId: number;
  isSuperAdmin: string;
  msg: string;
  decimalPlaces: any = {};
  decimalPlaceNumber: any;
  decimalPlacesRateAndDiscount: any = {};
  decimalPlaceNumberRatesAndDiscount: any;
  public submenus: any[];
  overviewFlag: boolean = false;
  constructor(public appComp: AppComponent, private globalService: GlobalServiceService, private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute, private router: Router, private location: Location, private expiryDialogBoxService: ExpiryDialogBoxService
    , private dataService: DataService) { }

  ngOnInit() {
    this.ngxLoader.start();
    this.globalService.setIsAuthCalledFromFy(true);
    this.appComp.isAuthSelected = true;
    this.globalService.financialYear.subscribe(financialYear => {
      if (financialYear)
        this.financialYearId = financialYear.id;
    });

    this.route.paramMap.subscribe(
      params => {
        const pk = +params.get('pk');
        //this.moduleListByUserPk(pk);
        //this.subModuleListAutomatic();
        // const financialYearId=+params.get('finacialYearId');
        this.globalService.getFacilityByUser(pk, this.financialYearId).subscribe(
          (data: any) => {
            this.isSuperAdmin = data.isSuperAdmin;
            if (data.facilityList.length > 0) {
              this.facilityList = data.facilityList;
            }
            else {
              if (this.isSuperAdmin == 'n') {
                this.msg = "Currently, there is no facility assign to logged In  user. Please contact administrator."
              }
              else
                this.router.navigateByUrl(`/add-facility/${pk}`)
            }
          }
        )

      });

    this.ngxLoader.stop();
  }
  public companyExpired: boolean = false;
  navigateToDashboard(facility: any) {
    let setFacility = {
      id: facility.id,
      name: facility.name
    }
    this.globalService.setFacility(setFacility);
    this.submodule(facility.modules[0].pk, facility);
    this.isCompanyExpire();
  }

  back() {
    this.location.back();
  }

  companyStatusMsg: any = {};
  getCompanyStatusMessage(facility: any) {
    this.ngxLoader.start();
    this.globalService.getCompanyStatusMessage().subscribe((data) => {
      this.companyStatusMsg = data;
      this.ngxLoader.stop();
      if (this.companyStatusMsg.statusCode == 202) {

        this.expiryDialogBoxService.confirm('Plan has expired', this.companyStatusMsg.msg)
          .then((confirmed) => {
            this.router.navigate([`/settings/6/subscription`]);
          }
          )
          .catch(() => {
            this.router.navigate([`/settings/6/subscription`]);
          });

      } else if (this.companyStatusMsg.statusCode == 201) {
        this.expiryDialogBoxService.confirm('Plan has expired', this.companyStatusMsg.msg)
          .then((confirmed) => {
            this.router.navigate([`/settings/6/subscription`]);
          }
          )
          .catch(() => {
            this.router.navigate([`/settings/6/subscription`]);
          });
      } else if (this.companyStatusMsg.statusCode == 200) {
        if (facility.modules[0].moduleName == "Inventory") {
          this.getDecimalValue("Inventory");
          if (this.overviewFlag) {
            this.router.navigateByUrl(`/inventory/${facility.modules[0].pk}/inv-overview`);
          } else {
            this.router.navigateByUrl(`/inventory/${facility.modules[0].pk}`);
          }
        } else if (facility.modules[0].pk == 23 || facility.modules[0].moduleName == "Dealer Portal") {
          this.getDecimalValue("Inventory");
          if (this.overviewFlag) {
            this.router.navigateByUrl(`/dealer_portal/${facility.modules[0].pk}/inv-overview`);
          } else {
            this.router.navigateByUrl(`/dealer_portal/${facility.modules[0].pk}`);
          }
        } else if (facility.modules[0].moduleName == "Inventory Pro") {
          this.getDecimalValue("Inventory");
          if (this.overviewFlag) {
            this.router.navigateByUrl(`/inventory_pro/${facility.modules[0].pk}/inv-overview`);
          } else {
            this.router.navigateByUrl(`/inventory_pro/${facility.modules[0].pk}`);
          }
        } else if (facility.modules[0].moduleName == "Manufacturing") {
          this.getDecimalValue("Manufacturing");
          if (this.overviewFlag) {
            this.router.navigateByUrl(`/manufacturing/${facility.modules[0].pk}/mfrg-overview`);
          } else {
            this.router.navigateByUrl(`/manufacturing/${facility.modules[0].pk}`);
          }
        } else if (facility.modules[0].moduleName == "Manufacturing Pro") {
          this.getDecimalValue("Manufacturing");
          if (this.overviewFlag) {
            this.router.navigateByUrl(`/manufacturing_pro/${facility.modules[0].pk}/mfrg-overview`);
          } else {
            this.router.navigateByUrl(`/manufacturing_pro/${facility.modules[0].pk}`);
          }
        } else if (facility.modules[0].moduleName == "Manufacturing Free") {
          this.getDecimalValue("Manufacturing");
          if (this.overviewFlag) {
            this.router.navigateByUrl(`/manufacturing_free/${facility.modules[0].pk}/mfrg-overview`);
          } else {
            this.router.navigateByUrl(`/manufacturing_free/${facility.modules[0].pk}`);
          }
        } else if (facility.modules[0].moduleName == "Order Processing") {
          this.getDecimalValue("Orderprocessing");
          if (this.overviewFlag) {
            this.router.navigateByUrl(`/order-processing/${facility.modules[0].pk}/processing-overview`);
          } else {
            this.router.navigateByUrl(`/order-processing/${facility.modules[0].pk}`);
          }
        } else if (facility.modules[0].moduleName == "Dealer Central Orders") {
          this.getDecimalValue("Orderprocessing");
          if (this.overviewFlag) {
            this.router.navigateByUrl(`/dealer_central_orders/${facility.modules[0].pk}/processing-overview`);
          } else {
            this.router.navigateByUrl(`/dealer_central_orders/${facility.modules[0].pk}`);
          }

        }
      }

    })
  }

  isCompanyExpire() {
    this.globalService.getIsCompanyExpired().subscribe((data) => {
      localStorage.setItem('isCompanyExpired', data.data);
      this.companyExpired = data.data;
      this.appComp.isCompanyExired = data.data;
    });
  }

  moduleData: any[] = [];
  modulePk = 0;
  moduleListByUserPk(userPk) {
    this.globalService.moduleListByUserPk(userPk).subscribe((data) => {
      if (data.data != null) {
        this.moduleData = data.data;
        this.moduleData.forEach(item => {
          if (item.pk == 7 || item.pk == 4) {
            this.modulePk = item.pk;
          }
        })
        this.modulePk = this.modulePk > 0 ? this.modulePk : 4;
      }
    }, (error) => {
      console.log("Error -->", error);
    });
  }

  submenus1: any[] = [];
  subModuleListAutomatic() {
    this.globalService.subModuleListAutomatic().subscribe(
      (data: any) => {
        this.submenus1 = data.data;
      },
      (err: any) => {
        console.log(err);
      }
    )
  }

  public configurationCollection: any[] = [];
  getDecimalValue(applicableModule: any) {
    this.getDealerData();
    this.globalService.getDecimalPlaceAllowed(applicableModule).subscribe((data) => {

      if (data != null) {
        this.configurationCollection = data.listConfigurationMenu;
        for (var i = 0; i < this.configurationCollection.length; i++) {
          if (this.configurationCollection[i].functionDescription.trim() == "Decimal Points for Items") {
            this.decimalPlaces = this.configurationCollection[i];
          } else if (this.configurationCollection[i].functionDescription.trim() == "Decimal Points for Rates & Discounts") {
            this.decimalPlacesRateAndDiscount = this.configurationCollection[i];
          }
        }

        localStorage.removeItem('decimalPlace');
        localStorage.setItem('decimalPlace', this.decimalPlaces.parameterValue == "" ? '0' : parseInt(this.decimalPlaces.parameterValue).toString());
        localStorage.removeItem('decimalPlaceRatesAndDiscount');
        localStorage.setItem('decimalPlaceRatesAndDiscount', this.decimalPlacesRateAndDiscount.parameterValue == "" ? '0' : parseInt(this.decimalPlacesRateAndDiscount.parameterValue).toString());
      }
    });

  }
  submodule(pk, facility: any) {
    this.ngxLoader.start()
    this.dataService.getAllSubmenu(pk, pk).subscribe((data: any) => {
      console.log(data);
      if (data.data != null && data.data != undefined) {
        this.submenus = data.data;
        this.submenus.forEach((element) => {
          if (element.subModuleName == "Overview") {
            this.overviewFlag = true;
          }
        })
        this.getCompanyStatusMessage(facility);
      }
      this.ngxLoader.stop()
    }, (err: any) => {
      console.log(err);
      this.ngxLoader.stop()

    }
    )
  }

  getDealerData(){
    this.globalService.getDealerFlag().subscribe((data:any)=>{
      if(data?.data!= null){
      this.globalService.setDealerFlag(data.data.dealer);
      }
      if(data?.data?.info!= null){
      this.globalService.setDealerData({flag: data.data.dealer, dealerData: data.data.info});
      }else if(data?.data?.info == null){
        this.globalService.setDealerData({flag: data.data.dealer, dealerData: null});
      }
    })
  }
}
