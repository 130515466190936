import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { HeaderConfiguration } from '../headerConfiguration';
@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {

  requestHeader = HeaderConfiguration.getHeaderConfiguration();


  constructor(private _http: HttpClient) {
    console.log("enrollment.service.ts")
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
   }




  errorHandler(error: HttpErrorResponse) {
    return throwError(error)
  }
}
