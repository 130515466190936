<div class="modal-header">
  <h5 class="modal-title" id="fileuploadModalLabel">Upload & Save Profile Picture</h5>
  <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form method="POST" class="PopupForm" [formGroup]="documentUploadForm"
    (ngSubmit)="uploadFilesOnServer(documentUploadForm)">
    <div class="form-group" formArrayName="documents">
      <div [formGroupName]="0">


        <input type="file" [id]="'uploadDocs'+0" name="'uploadDocs'+0" formControlName="document"
          [ngClass]="{'is-invalid':fileSizeValidations[0] != undefined && fileSizeValidations[0].size>10}"
          (change)="uploadFileOnChange($event, 0)"><br>
        <small *ngIf="fileValid[0]" style="color: rgb(248, 26, 26);">Please choose file.</small>
        <small *ngIf="fileSizeValidations[0] != undefined && fileSizeValidations[0].size>10"
          style="color: rgb(248, 26, 26);">File size limit exceeded, You can upload max 10MB file</small>
        <small *ngIf="imageTypevalidation" style="color: rgb(248, 26, 26);">Only JPEG and PNG Images are allowed</small>
      </div>
    </div>

    <div class="form-group" style="text-align: right;margin-bottom: 0px;">

      <button type="submit" *ngIf="!imageSizeValidationFlag && !imageTypevalidation" ejs-button cssClass=""
        class="submit button_padding button_margin buttonSize">
        Upload & Save
      </button>

    </div>
  </form>

</div>
<div class="modal-footer">
  <button ejs-button cssClass="" class="back button_padding button_margin buttonSize" type="button"
    (click)="closeModal()">back</button>
  <!-- <button ejs-button cssClass="" class="save button_padding button_margin buttonSize" type="button"
    (click)="done()">Save</button> -->
</div>

<ngx-ui-loader></ngx-ui-loader>