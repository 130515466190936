import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomValidator } from '../custom-validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: FormGroup;
  submitted: boolean = false;
  sub: any;
  userId: string;
  constructor(private formBuilder: FormBuilder, private ngxLoader: NgxUiLoaderService,
    private globalService: GlobalServiceService, private route: ActivatedRoute, private router: Router) {
    this.globalService.currentUser.subscribe(user => {
      if (user) {
        this.userId = user.userId;
      }
    });
  }

  ngOnInit() {
    this.ngxLoader.start();
    // this.sub = this.route
    //   .queryParams
    //   .subscribe(params => {
    //     // Defaults to 0 if no query param provided.
    //     this.userId = params['userId'];
    //   });

    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', [Validators.required, CustomValidator.passWordValidator,]],
      confirmPassword: ['', [Validators.required, CustomValidator.passWordValidator,]]
    }, {
      validator: this.mustMatch('password', 'confirmPassword')
    });
    this.ngxLoader.stop();
  }

  get f() { return this.passwordForm.controls; }


  onSubmit() {
    this.ngxLoader.start();
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      this.ngxLoader.stop();
      return;
    };

    this.globalService.changeOldPassword(this.userId, this.passwordForm.value.password, this.passwordForm.value.oldPassword)
      // .pipe(first())
      .subscribe(data => {
          this.ngxLoader.stop();
          if(data.statusCode == 200){
            alert('Password change sucessfully.Please login again.');
            this.globalService.logout().subscribe(
              (data: any) => {
                this.globalService.logoutSession();
                this.router.navigate(['/login']);
              },
              (err: any) => {
                console.log(err);
              });
          }else if(data.statusCode == 500){
            alert('Server Response: '+ data.statusCode +', Msg: ' + data.msg);
          }
        },(err: any) => {
          console.log(err);
          this.ngxLoader.stop();
          // alert('Please enter a valid username and password.');
        })


      }



  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.hasError('mustMatch')) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };


  }
}
