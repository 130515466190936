import { Component, OnInit } from '@angular/core';
import { User } from './modules/core/model/model';

import { GlobalServiceService } from './modules/core/services/global-service.service';
import * as moment from 'moment';
import { initializeApp } from 'firebase/app'
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  currentUser: User;
  users: User[] = [];
  message: any = {};
  public tt = 500;
  public topmsgFlag: boolean = false;
  public loggedInUser: any = {};
  public financialYearStatus: string = '';
  public isCompanyExired: boolean = false;
  isCompanyUpgraded: boolean = false;
  public isAuthSelected: boolean = false;
  code: any = null;
  constructor(private router: Router, private location: Location, private globalService: GlobalServiceService, public translate: TranslateService) {
    this.globalService.currentUser.subscribe(x => this.currentUser = x);
    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('en');
    this.loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
    var ref = this;
    document.addEventListener("visibilitychange", function () {
     
      if (document.hidden) {
        //do whatever you want
       
      }
      else {
        let user = JSON.parse(localStorage.getItem("currentUser"));
        const url = window.location.href;
        const scanOrderIndex = url.indexOf("scan-orders");
        let companyExpired = JSON.parse(localStorage.getItem("isCompanyExpired"));
        ref.isCompanyExired = companyExpired;
        if (user != null) {
          //sessionStorage.clear();
          //localStorage.clear();
        }

        if (ref.loggedInUser != null && user != null && user.userId != ref.loggedInUser.userId) {
          sessionStorage.removeItem('facilityId');
          sessionStorage.removeItem('facility');
          sessionStorage.removeItem('financialYear');
          localStorage.removeItem('facilityId');
          localStorage.removeItem('facility');
          localStorage.removeItem('financialYear');
          if (scanOrderIndex == -1) {
            ref.router.navigateByUrl(`/financialYear/${user.pk}`);
            window.location.href = "/";
          }

        } else if (ref.loggedInUser == null && user != null) {
          sessionStorage.removeItem('facilityId');
          sessionStorage.removeItem('facility');
          sessionStorage.removeItem('financialYear');
          localStorage.removeItem('facilityId');
          localStorage.removeItem('facility');
          localStorage.removeItem('financialYear');
          if (scanOrderIndex == -1) {
            ref.router.navigateByUrl(`/financialYear/${user.pk}`);
            window.location.href = "/";
          }



        } else if ((ref.loggedInUser != null) && (user == undefined || user == null)) {
          if (ref.router.url != "/login" && ref.router.url.indexOf("password-reset") == -1) {
            if (scanOrderIndex == -1) {
              window.location.href = "/";
            }

          }

        }
        ref.financialYearStatus = sessionStorage.getItem("financialYear");
        if (ref.financialYearStatus == undefined || ref.financialYearStatus == '' || ref.financialYearStatus == null) {
          if (user != null) {
            if (scanOrderIndex == -1) {
              ref.router.navigateByUrl(`/financialYear/${user.pk}`);
            }

          }
        }
      }
    });

    this.globalService.getCompanyUpgrade().pipe(take(1)).subscribe(value => {
      this.isCompanyUpgraded = value;
      if (this.isCompanyUpgraded == true) {
        this.isCompanyExpire();
      }
    });


    //const paramValue = localStorage.getItem("zohoCode");
    let userzoho = JSON.parse(localStorage.getItem("currentUser"));
    if (this.loggedInUser != null && userzoho.userId == this.loggedInUser.userId) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code3 = urlParams.get('code');
      if (code3 != undefined && code3 != null) {
        ref.router.navigateByUrl(`/integration/101/zoho-config-information?code=${code3}`);
      }

    }

  }



  ngOnInit() {
    const time = moment.utc();
    const firebaseConfig = {};
    initializeApp(firebaseConfig);
    let user2 = JSON.parse(localStorage.getItem("currentUser"));
    this.financialYearStatus = sessionStorage.getItem("financialYear");
    if (this.financialYearStatus == undefined || this.financialYearStatus == '' || this.financialYearStatus == null) {
      if (user2 != null) {
        const url = window.location.href;
        const scanOrderIndex = url.indexOf("scan-orders");
        if (scanOrderIndex == -1) {
        this.router.navigateByUrl(`/financialYear/${user2.pk}`);
        }
      }
    }
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  
  }
  setFlag() {
   
  }

  isCompanyExpire() {
    this.globalService.getIsCompanyExpired().subscribe((data) => {
      localStorage.setItem('isCompanyExpired', data.data);
      this.isCompanyExired = data.data;
    }
    );
  }


}
