<div class="card">
  <div class="card-body thanks-page-body">
    <div class="thank-page-header" style="margin-bottom: 0px;">
      <h2>Thank You For Subscribing</h2>
      <img src="../assets/image/white-logo-easemyprod.png" />

    </div>

    <div class="description" *ngIf="paymentDetails != null && paymentDetails != ''">
      Your are currently subscribed to {{paymentDetails.data.subscription.name}} starting on {{planStartDate}} and end
      date {{planEndDate}} with {{subscribedPlanDetails.zohoUserCount}} to be billed {{billedon}}.
      <hr />
      <button type="button" (click)="contactUs()" ejs-button cssClass="" class="need-help button_padding button_margin">
        Need more Help? Contach Us
      </button>
    </div>


  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>