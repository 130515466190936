export class CommonVariables {
  static submenuId = 0;
  static submenues: any[] = [];
  static setVariavbleValue(id) {
    CommonVariables.submenuId = id;
  }

  static getVariavbleValue() {
    return CommonVariables.submenuId;
  }

  static setSubmenus(arr: any[]) {
    CommonVariables.submenues = arr;
  }

  static getSubmenus() {
    return CommonVariables.submenues;
  }

  /** convert numbers to words */
  static numericRs(amount) {
    var words = new Array();
    words[0] = 'Zero'; words[1] = 'One'; words[2] = 'Two'; words[3] = 'Three'; words[4] = 'Four'; words[5] = 'Five'; words[6] = 'Six'; words[7] = 'Seven'; words[8] = 'Eight'; words[9] = 'Nine'; words[10] = 'Ten'; words[11] = 'Eleven'; words[12] = 'Twelve'; words[13] = 'Thirteen'; words[14] = 'Fourteen'; words[15] = 'Fifteen'; words[16] = 'Sixteen'; words[17] = 'Seventeen'; words[18] = 'Eighteen'; words[19] = 'Nineteen'; words[20] = 'Twenty'; words[30] = 'Thirty'; words[40] = 'Forty'; words[50] = 'Fifty'; words[60] = 'Sixty'; words[70] = 'Seventy'; words[80] = 'Eighty'; words[90] = 'Ninety'; var op;
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    var value = null;
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j].toString());
            n_array[i] = 0;
          }
        }
      }
      value = null;
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split(" ").join(" ");
    }
    return words_string;
  }
  static changeToWord(n) {
    var nums = n.toString().split('.')
    var op = '';
    var whole = CommonVariables.numericRs(nums[0])
    if (nums[1] == null) nums[1] = 0;
    if (nums[1].length == 1) nums[1] = nums[1] + '0';
    if (nums[1].length > 2) { nums[1] = nums[1].substring(2, length - 1) }
    if (nums.length == 2) {
      if (nums[0] <= 9) { nums[0] = nums[0] * 10 } else { nums[0] = nums[0] };
      var fraction = CommonVariables.numericRs(nums[1])
      if (whole == '' && fraction == '') { op = 'Zero only'; }
      if (whole == '' && fraction != '') { op = 'and ' + fraction + 'Paise only'; }
      if (whole != '' && fraction == '') { op = '' + whole + ' only'; }
      if (whole != '' && fraction != '') { op = '' + whole + 'and ' + fraction + ' Paise only'; }
      var amt = n;
      if (amt > 999999999.99) { op = 'Oops!!! The amount is too big to convert'; }
      if (isNaN(amt) == true) { op = 'Error : Amount in number appears to be incorrect. Please Check.'; }
      return op;
    }
  }

  /** attribute for Pdf */
  static attributesForPdf(item) {

    let returnStr = '';
    if ((item.xLen != null && item.xLen != 0) || (item.yLen != null && item.yLen != 0) || (item.zLen != null && item.zLen != 0) || item.color1 != null || item.color2 != null || item.color3 != null || item.color4 != null || item.color5 != null || item.other1 != null || item.other2 != null) {
      returnStr += "";
    }
    if ((item.xLen != null && item.xLen != 0) || (item.yLen != null && item.yLen != 0) || (item.zLen != null && item.zLen != 0)) {
      returnStr += "(";
      if (item.xLen != null && item.xLen != 0) {
        returnStr += "" + item.xLabel + ": " + item.xLen + ", ";
      }
      if (item.yLen != null && item.yLen != 0) {
        returnStr += "" + item.yLabel + ": " + item.yLen + ", ";
      }
      if (item.zLen != null && item.zLen != 0) {
        returnStr += "" + item.zLabel + ": " + item.zLen + "";
      }
      returnStr += "), ";
    }

    if ((item.color1 != undefined && item.color1 != null) || (item.color2 != undefined && item.color2 != null) || (item.color3 != undefined && item.color3 != null) || (item.color4 != undefined && item.color4 != null) || (item.color5 != undefined && item.color5 != null)) {
      returnStr += "(";
      if (item.color1 != undefined && item.color1 != null) {
        returnStr += "" + item.color1Label + ": " + item.color1 + ", ";
      }
      if (item.color2 != undefined && item.color2 != null) {
        returnStr += "" + item.color2Label + ": " + item.color2 + ", ";
      }
      if (item.color3 != undefined && item.color3 != null) {
        returnStr += "" + item.color3Label + ": " + item.color3 + ", ";
      }
      if (item.color4 != undefined && item.color4 != null) {
        returnStr += "" + item.color4Label + ": " + item.color4 + ", ";
      }
      if (item.color5 != undefined && item.color5 != null) {
        returnStr += "" + item.color5Label + ": " + item.color5 + " ";
      }
      returnStr += ")";
    }

    if ((item.other1 != undefined && item.other1 != null) || (item.other2 != undefined && item.other2 != null)) {
      returnStr += "(";
      if (item.other1 != undefined && item.other1 != null && item.other2 != undefined && item.other2 != null) {
        returnStr += "" + item.other1Label + ": " + item.other1 + ", ";
      } else if ((item.other1 != undefined && item.other1 != null) && (item.other2 == undefined || item.other2 == null)) {
        returnStr += "" + item.other1Label + ": " + item.other1 + ", ";
      }

      if (item.other2 != undefined && item.other2 != null) {
        returnStr += "" + item.other2Label + ": " + item.other2 + "";
      }

      returnStr += ")";
    }
    return returnStr;
  }

  static convertStrToDate(date) {
    if (date != undefined && date != null) {

      var parts = date.split('/');

      var mydate = new Date(parts[2], parts[1] - 1, parts[0]);

      return mydate;
    }
  }

  static convertStrToDatewithHifon(date) {
    if (date != undefined && date != null) {

      var parts = date.split('-');

      var mydate = new Date(parts[2], parts[1] - 1, parts[0]);
      return mydate;
    }

  }

  static convertStrToDatewithHifonYYYYMMDD(date) {
    if (date != undefined && date != null) {

      var parts = date.split('-');

      var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
      return mydate;
    }

  }

  static reverseStrDate(date) {
    if (date != undefined && date != null) {

      var parts = date.split('-');

      var mydate = parts[2] + "/" + parts[1] + "/" + parts[0]

      return mydate;
    }
  }
  /**convert integer into hour:mint */
  /**convert integer into hour:mint */


  static convertStrTohourMinut(Time) {

    var myTime
    let hours = new Date(Time).getHours()
    var minutes = new Date(Time).getMinutes()
    let i = "";
    i = hours.toString();
    let j = ""
    j = minutes.toString();
    if ((i.length < 2) || (j.length < 2)) {
      if (i.length < 2) {
        i = "0" + hours;
      }

      if (minutes == 0) {
        j = "00";
      } else if (minutes > 0 && j.length < 2) {
        j = 0 + j;
      }


    }
    else if (j.length == 0) {
      j = String(minutes) + "00";


    } else {
      if (minutes < 10) {
        j = 0 + String(minutes);
      } else {
        j = String(minutes);
      }
    }
    myTime = i + ":" + j
    return myTime;
  }



  /** add attributes starts*/
  static displayItemAttributesFormate(item) {
    // console.log(item);
    let returnStr = '';
    if ((item.xLen != null && item.xLen != 0) || (item.yLen != null && item.yLen != 0) || (item.zLen != null && item.zLen != 0) || item.color1 != null || item.color2 != null || item.color3 != null || item.color4 != null || item.color5 != null || item.other1 != null || item.other2 != null) {
      returnStr += "";
    }
    if ((item.xLen != null && item.xLen != 0) || (item.yLen != null && item.yLen != 0) || (item.zLen != null && item.zLen != 0)) {
      returnStr += "";
      if (item.xLen != null && item.xLen != 0) {
        returnStr += "<span class='attributeBoxWLH'>" + item.xLabel + ": " + item.xLen + "</span>";
      }
      if (item.yLen != null && item.yLen != 0) {
        returnStr += "<span class='attributeBoxWLH'>" + item.yLabel + ": " + item.yLen + "</span>";
      }
      if (item.zLen != null && item.zLen != 0) {
        returnStr += "<span class='attributeBoxWLH'>" + item.zLabel + ": " + item.zLen + "</span>";
      }
      returnStr += "";
    }

    if ((item.color1 != undefined && item.color1 != null) || (item.color2 != undefined && item.color2 != null) || (item.color3 != undefined && item.color3 != null) || (item.color4 != undefined && item.color4 != null) || (item.color5 != undefined && item.color5 != null)) {
      returnStr += "";
      if (item.color1 != undefined && item.color1 != null) {
        returnStr += "<span class='attributeBox'>" + item.color1Label + ": " + item.color1 + "</span>";
      }
      if (item.color2 != undefined && item.color2 != null) {
        returnStr += "<span class='attributeBox'>" + item.color2Label + ": " + item.color2 + "</span>";
      }
      if (item.color3 != undefined && item.color3 != null) {
        returnStr += "<span class='attributeBox'>" + item.color3Label + ": " + item.color3 + "</span>";
      }
      if (item.color4 != undefined && item.color4 != null) {
        returnStr += "<span class='attributeBox'>" + item.color4Label + ": " + item.color4 + "</span>";
      }
      if (item.color5 != undefined && item.color5 != null) {
        returnStr += "<span class='attributeBox'>" + item.color5Label + ": " + item.color5 + "</span>";
      }
      returnStr += "";
    }

    if ((item.other1 != undefined && item.other1 != null) || (item.other2 != undefined && item.other2 != null)) {
      returnStr += "";
      if (item.other1 != undefined && item.other1 != null && item.other2 != undefined && item.other2 != null) {
        returnStr += "<span class='attributeBoxOther'>" + item.other1Label + ": " + item.other1 + "</span>";
      } else if ((item.other1 != undefined && item.other1 != null) && (item.other2 == undefined || item.other2 == null)) {
        returnStr += "<span class='attributeBoxOther'>" + item.other1Label + ": " + item.other1 + "</span>";
      }

      if (item.other2 != undefined && item.other2 != null) {
        returnStr += "<span class='attributeBoxOther'>" + item.other2Label + ": " + item.other2 + "</span>";
      }

      returnStr += "";
    }
    return returnStr;
  }


  static currentDateDMY() {
    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let day = date.getDate();
    let month2 = '';
    let day2 = '';
    if (month < 10) {
      month2 = 0 + "" + month
    } else {
      month2 = month.toString();
    }

    if (day < 10) {
      day2 = 0 + "" + day
    } else {
      day2 = day.toString();
    }

    return day2 + "/" + month2 + "/" + year;
  }
  /** add attributes ends*/

  static convertNumberInIndianFormat(amount) {
    let total = Number(amount).toFixed(2);
    var n1, n2;
    total = total + '' || '';
    // works for integer and floating as well
    n1 = total.split('.');
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    total = n2 ? n1 + '.' + n2 : n1;
    return total;
  }

  static dateString2Date(dateString) {
    var dt = dateString.split(/\/|\s/);
    console.log("DT ", dt);
    return new Date(dt.slice(0, 3).reverse().join('-') + ' ' + dt[3]);
  }


  static dateFormateYyyymmdd(date) {
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let day = date.getDate();
    let month2 = '';
    let day2 = '';
    if (month < 10) {
      month2 = 0 + "" + month
    } else {
      month2 = month.toString();
    }

    if (day < 10) {
      day2 = 0 + "" + day
    } else {
      day2 = day.toString();
    }

    return year + "-" + month2 + "-" + day2;
  }

  static add_months(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  static currentDateDMYHMS(endDate: Date) {
    let date = endDate;
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let month2 = '';
    let day2 = '';
    let hour2 = '';
    let minute2 = '';
    let second2 = '';
    if (month < 10) {
      month2 = 0 + "" + month
    } else {
      month2 = month.toString();
    }

    if (day < 10) {
      day2 = 0 + "" + day
    } else {
      day2 = day.toString();
    }
    if (hour < 10) {
      hour2 = 0 + "" + hour;
    } else {
      hour2 = hour.toString();
    }
    if (minute < 10) {
      minute2 = 0 + "" + minute;
    } else {
      minute2 = minute.toString();
    }
    if (second < 10) {
      second2 = 0 + "" + second;
    } else {
      second2 = second.toString();
    }

    return day2 + "/" + month2 + "/" + year + " " + hour2 + ":" + minute2 + ":" + second2;
  }

  static dateString2DateJoinHifon(dateString) {
    var dt = dateString.split(/\/|\s/);
    console.log("DT ", dt);
    return new Date(dt.slice(0, 3).reverse().join('-'));
  }

  static dateTransformForValidation(strDate) {
    let date = strDate;
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let day = date.getDate();
    let month2 = '';
    let day2 = '';
    let hour2 = '';
    let minute2 = '';
    let second2 = '';
    if (month < 10) {
      month2 = 0 + "" + month
    } else {
      month2 = month.toString();
    }

    if (day < 10) {
      day2 = 0 + "" + day
    } else {
      day2 = day.toString();
    }

    return day2 + "/" + month2 + "/" + year;
  }

  static getTransformDate(date): string {
    let parts: any[] = date.split('/');
    return (`${parts[1]}/${parts[0]}/${parts[2]}`);
  }

  static componentQtyABCValidation1(itemBoms: any[]) {
    let ref = this;
    let flag = false;
    let returnValue: boolean = true;
    for (let i in itemBoms) {
      if (itemBoms[i].action != 1) {


        let itemQty = itemBoms[i].itemQty != null ? itemBoms[i].itemQty.toString() : '';
        let componentQty = itemBoms[i].componentQty != null ? itemBoms[i].componentQty.toString() : '';
        let lengthValueA = itemBoms[i].xVal != null ? itemBoms[i].xVal.toString() : '';
        let widthvalueB = itemBoms[i].yVal != null ? itemBoms[i].yVal.toString() : '';
        let heightValueC = itemBoms[i].zVal != null ? itemBoms[i].zVal.toString() : '';

        if (itemQty != null || itemQty != '') {
          let valLowerCase = itemQty.toLowerCase();
          if (lengthValueA == null || lengthValueA == '') {

            if (valLowerCase.indexOf("a") != -1) {
              flag = true;
            }
          }
          if (widthvalueB == null || widthvalueB == '') {
            if (valLowerCase.indexOf("b") != -1) {
              flag = true;
            }
          }
          if (heightValueC == null || heightValueC == '') {
            if (valLowerCase.indexOf("c") != -1) {
              flag = true;
            }
          }


        }

        if (componentQty != null || componentQty != '') {
          let valLowerCase1 = componentQty.toLowerCase();
          if (lengthValueA == null || lengthValueA == '') {

            if (valLowerCase1.indexOf("a") != -1) {
              flag = true;
            }
          }
          if (widthvalueB == null || widthvalueB == '') {
            if (valLowerCase1.indexOf("b") != -1) {
              flag = true;
            }
          }
          if (heightValueC == null || heightValueC == '') {
            if (valLowerCase1.indexOf("c") != -1) {
              flag = true;
            }
          }


        }



      }
      if (flag) {

        returnValue = false;

        break;
      }

    }
    return returnValue;
  }

  static colorAttrFormate(item) {
    let returnStr = '';
    if ((item.color1 != undefined && item.color1 != null) || (item.color2 != undefined && item.color2 != null) || (item.color3 != undefined && item.color3 != null) || (item.color4 != undefined && item.color4 != null) || (item.color5 != undefined && item.color5 != null)) {
      returnStr += "(";
      if (item.color1 != undefined && item.color1 != null) {
        returnStr += "" + item.color1Label + ": " + item.color1 + ", ";
      }
      if (item.color2 != undefined && item.color2 != null) {
        returnStr += "" + item.color2Label + ": " + item.color2 + ", ";
      }
      if (item.color3 != undefined && item.color3 != null) {
        returnStr += "" + item.color3Label + ": " + item.color3 + ", ";
      }
      if (item.color4 != undefined && item.color4 != null) {
        returnStr += "" + item.color4Label + ": " + item.color4 + ", ";
      }
      if (item.color5 != undefined && item.color5 != null) {
        returnStr += "" + item.color5Label + ": " + item.color5 + " ";
      }
      returnStr += ")";
    }
    return returnStr;
  }
  static otherAttrFormate(item) {
    let returnStr = '';
    if ((item.other1 != undefined && item.other1 != null) || (item.other2 != undefined && item.other2 != null)) {
      returnStr += "(";
      if (item.other1 != undefined && item.other1 != null && item.other2 != undefined && item.other2 != null) {
        returnStr += "" + item.other1Label + ": " + item.other1 + ", ";
      } else if ((item.other1 != undefined && item.other1 != null) && (item.other2 == undefined || item.other2 == null)) {
        returnStr += "" + item.other1Label + ": " + item.other1 + ", ";
      }

      if (item.other2 != undefined && item.other2 != null) {
        returnStr += "" + item.other2Label + ": " + item.other2 + "";
      }

      returnStr += ")";
    }
    return returnStr;
  }

  static getAmountFormat(totalAmount: number) {

    let total = Number(totalAmount).toFixed(2);
    var n1, n2;
    total = total + '' || '';
    // works for integer and floating as well
    n1 = total.split('.');
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    total = n2 ? n1 + '.' + n2 : n1;
    return total;
  }

  static maintainScroll(scrollAmount: number) {
    setTimeout(() => {
      window.scroll({
        top: scrollAmount,
        left: 0,
      });
    }, 350);
  }

}