<p-breadcrumb [home]="home" [model]="menuItems" class="custom-breadcrumb display-bread-crumb"></p-breadcrumb>

<div id="mySidenav" class="sidenav container-fluid small-screen-sidetop-bar mobile-menu-tab-conatiner"
    *ngIf="currentUser?.userId">
    <div class="row">
        <div class="col-sm-12">
            <ul class="a mobile-menu-tab">
                <li *ngFor="let menu of menus" onclick="closeNav()"
                    [ngClass]="{'active':menu.pk==menuId, 'mobile-dispay-class':(menu.pk==3 || menu.pk==4 || menu.pk==5|| menu.pk==7|| menu.pk==9 ||menu.pk==13 ||menu.pk==17||menu.pk==19||menu.pk==23||menu.pk==25||menu.pk==33 || menu.pk==43)}">
                    <a (click)="navigateToModule(menu);selectMenu($event, menu)" class="dflex "
                        [ngClass]="{'active':menu.pk==menuId}"> <i class="{{menu.moduleIcon}}" container='body'
                            ngbTooltip="{{menu.moduleName}}"></i>
                        <span class="spn"> {{menu.moduleName}} </span>
                    </a>
                </li>

            </ul>

        </div>
    </div>

</div>