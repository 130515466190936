import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
//import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

//used to create fake backend
import { ErrorInterceptorProviders, fakeBackendProvider, httpInterceptorProviders } from './_helpers';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { NavigationComponent } from './navigation/navigation.component';
import { AsidebarComponent } from './asidebar/asidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ManufacturingComponent } from './manufacturing/manufacturing.component';
import { OrderProcessingComponent } from "./order-processing/order-processing.component";
import { ChannelsComponent } from "./channels/channels.component";
import { EnrollmentService } from "./dataservices/enrollment.service";
import { ConfirmationDialogComponent } from './confirmation-dailog/confirmation-dailog.component';
import { ConfirmationDialogService } from './confirmation-dailog/confirmation-dialog.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingsComponent } from './settings/settings.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { LandingComponent } from './landing/landing.component'
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainContentComponent } from './main-content/main-content.component';
import { UserFacilityComponent } from './user-facility/user-facility.component';
import { AddUserFacilityComponent } from './add-user-facility/add-user-facility.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './modules/shared/shared.module';
import { FinancialYearComponent } from './financial-year/financial-year.component'
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { TathataAdminComponent } from './tathata-admin/tathata-admin.component'
import { TreeGridAllModule, TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import { GridAllModule, GridModule } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { ToolbarModule, ToolbarAllModule } from '@syncfusion/ej2-angular-navigations';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { SuccessMessageComponent } from './success-message/success-message.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { enableRipple } from '@syncfusion/ej2-base';
import { DemoComponent } from './demo/demo.component';
import { UserSignupComponent } from './user-signup/user-signup.component';
import { ZohoConfirmDailogComponent } from './zoho-confirm-dailog/zoho-confirm-dailog.component';
import { ZohoConfirmDialogService } from './zoho-confirm-dailog/zoho-confirm-dialog.service';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { OuterServiceStatusComponent } from './outer-service-status/outer-service-status.component';
import { ExistingUserLogoutComponent } from './existing-user-logout/existing-user-logout.component';
import { LogoutDialogService } from './existing-user-logout/logout-dialog.service';
import { APP_BASE_HREF } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ExpiryAccountDialogBoxComponent } from './expiry-account-dialog-box/expiry-account-dialog-box.component';
import { ExpiryDialogBoxService } from './expiry-account-dialog-box/expiry-dialog-box.service';
import { UserSignup2Component } from './user-signup2/user-signup2.component';
import { UserSignup3Component } from './user-signup3/user-signup3.component';
import { AddFinancialYearFirtTimeComponent } from './add-financial-year-firt-time/add-financial-year-firt-time.component';
import { TransactionSavedComponent } from './transaction-saved/transaction-saved.component';
import { ManufacturingFreeComponent } from './manufacturing-free/manufacturing-free.component';
import { MailNavigateComponent } from './mail-navigate/mail-navigate.component';
import { PoFullfilmentPoDetailsComponent } from './po-fullfilment-po-details/po-fullfilment-po-details.component';
import { SuccessMessageMailNavigateComponent } from './success-message-mail-navigate/success-message-mail-navigate.component';
import { SessionUpdateConfirmationComponent } from './session-update-confirmation/session-update-confirmation.component';
import { SessionUpdateService } from './session-update-confirmation/session-update.service';
import { CanDeactivateConfirmDialogComponent } from './can-deactivate-confirm-dialog/can-deactivate-confirm-dialog.component';
import { PipesModule } from './pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { FinancialyearCopyComponent } from './financialyear-copy/financialyear-copy.component';
import { ScanQrcodeOrdersComponent } from './scan-qrcode-orders/scan-qrcode-orders.component';

enableRipple(true);
@NgModule({
    imports: [

        AngularDualListBoxModule,
        UiSwitchModule,
        NgSelectModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        DataTablesModule,
        routing,
        FormsModule,
        NgbModule,
        BrowserAnimationsModule,
        CarouselModule.forRoot(),
        NgxUiLoaderModule,
        ToastrModule.forRoot(),
        SharedModule,
        TreeGridModule,
        TreeGridAllModule, GridModule,
        GridAllModule, DropDownListModule,
        MultiSelectModule,
        ChipListModule,
        TabModule,
        RouterModule,
        ButtonModule,
        CheckBoxModule,
        RadioButtonModule,
        SwitchModule,
        ToolbarModule,
        ToolbarAllModule,
        TextBoxModule,
        DialogModule,
        DatePickerModule,
        BreadcrumbModule,
        PipesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        AsidebarComponent,
        HeaderComponent,
        FooterComponent,
        BreadcrumbComponent,
        OrderProcessingComponent,
        ManufacturingComponent,
        InventoryComponent,
        ChannelsComponent,
        ConfirmationDialogComponent,
        DashboardComponent,
        SettingsComponent,
        NavigationComponent,
        LandingComponent,
        PasswordResetComponent,
        PageNotFoundComponent,
        MainContentComponent,
        UserFacilityComponent,
        AddUserFacilityComponent,
        ForgotPasswordComponent,
        FinancialYearComponent,
        TathataAdminComponent,
        UserProfileComponent,
        ChangePasswordComponent,
        SuccessMessageComponent,
        ErrorMessageComponent,
        DemoComponent,
        UserSignupComponent,
        ZohoConfirmDailogComponent,
        OuterServiceStatusComponent,
        ExistingUserLogoutComponent,
        ExpiryAccountDialogBoxComponent,
        UserSignup2Component,
        UserSignup3Component,
        AddFinancialYearFirtTimeComponent,
        TransactionSavedComponent,
        ManufacturingFreeComponent,
        MailNavigateComponent,
        PoFullfilmentPoDetailsComponent,
        SuccessMessageMailNavigateComponent,
        SessionUpdateConfirmationComponent,
        CanDeactivateConfirmDialogComponent,
        FinancialyearCopyComponent,
        ScanQrcodeOrdersComponent
    ],

    providers: [
        AuthGuard,
        ConfirmationDialogService,
        ZohoConfirmDialogService,
        LogoutDialogService,
        EnrollmentService,
        ExpiryDialogBoxService,
        SessionUpdateService,
        PageService,
        SortService,
        FilterService, TranslateService,
        GroupService, ToolbarService,
        httpInterceptorProviders,
        ErrorInterceptorProviders,
        fakeBackendProvider,
        { provide: APP_BASE_HREF, useValue: window['_app_base'] || '/' },
    ],
    bootstrap: [AppComponent],
    exports: [],
    entryComponents: [
        ConfirmationDialogComponent,
        ExistingUserLogoutComponent,
        ZohoConfirmDailogComponent,
        AppComponent,
        HeaderComponent, //MainContentComponent,
        AsidebarComponent,
        ExpiryAccountDialogBoxComponent,
        SessionUpdateConfirmationComponent, CanDeactivateConfirmDialogComponent
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],

})

export class AppModule { }
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
