import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { User } from '../modules/core/model/model';

import { GlobalServiceService } from '../modules/core/services/global-service.service';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})
export class MainContentComponent implements OnInit {

  currentUser: User;
  users: User[] = [];
  message: any = {};
  public topmsgFlag: boolean = false;
  moduleName: any;
  constructor(public appCom: AppComponent, private globalService: GlobalServiceService) {
    this.globalService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    console.log("Flag iiiiii ", this.topmsgFlag);
  }

  setFlag() {
    console.log("Flag mm", this.topmsgFlag);
  }
}
