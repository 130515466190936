<div class="modal-header z-conform">
  <h4 class="modal-title" style="text-align: center;margin: 0px auto;">You are subscribing to following plans and add on
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body z-conform">

  <div class="container-fluid zoho-confirm-box">

    <div class="form-group" style="margin:0px; padding-bottom:10px">
      <div class="row">

        <div class="col-sm-12 plan-name">

          1. {{planData.name}}

        </div>
      </div>
    </div>
    <ng-container *ngFor="let addon of planData.addons;let i=index">
      <div class="form-group" style="margin-bottom: 0px;" *ngIf="addon.isSelected">
        <div class="row">

          <div class="col-sm-12 addons">

            {{i+2}}. {{addon.name}}

          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>
<div class="modal-footer z-conform">

  <div class="container-fluid">

    <div class="form-group">
      <div class="row">
        <div class="col-sm-12" style="text-align: center;">
          <button ejs-button cssClass="" class="confirmz button_padding button_margin buttonSizeZ " type="button"
            (click)="done()">Confirm</button>
          <button ejs-button cssClass="" class="backz button_padding button_margin buttonSizeZ " type="button"
            (click)="closeModal()"> Back</button>
        </div>
      </div>
    </div>

    <div class="form-group" style="margin:0px">
      <div class="row">
        <div class="col-sm-12" style="color: green; text-align: center;">
          'Please confirm!', 'Do you really create a company and activate the plan ?'
        </div>
      </div>
    </div>

  </div>

</div>