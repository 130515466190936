import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './confirmation-dailog.component';
import { HeaderConfiguration } from '../headerConfiguration';


@Injectable()
export class ConfirmationDialogService {
  //requestHeader = HeaderConfiguration.headerConfg;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();

  constructor(private modalService: NgbModal) {
    console.log("confimation-dialog.service.ts")
  }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'Proceed',
    btnCancelText: string = 'Discard',
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

}
