<div class="card">
  <div class="card-header" style="margin-bottom:0px">
    <span><b>PO Fullfilment Date</b></span>
  </div>
  <div class="card-body">
    <div class="comment-headline">
      Please Submit expected fulfilment date for this Purchase order. For submitting any
      document please use Upload/View
      Document Button. </div>
    <form class="double-row" [formGroup]="poFullfilmentForm" (ngSubmit)="onSubmit()">
      <fieldset>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>PO No:</label>
                </div>
                <div class="col-sm-8">
                  {{poFullfilmentData.voucherNo}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>PO Date:</label>
                </div>
                <div class="col-sm-8">
                  {{poFullfilmentData.purchaseOrderDate}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Vendor Name:</label>
                </div>
                <div class="col-sm-8">
                  {{poFullfilmentData.vendorName}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">

                </div>
                <div class="col-sm-8">




                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="row">
              <div class="col-sm-12">
                <div class="control-section polist">
                  <ejs-grid #grid id="po_grid" [dataSource]='poFulfillmentDates' allowSorting='true'>
                    <e-columns>
                      <e-column field='createdDate' headerText='Update Date' width='150' textAlign='Center'></e-column>
                      <e-column field='fulfillmentDate' headerText='Committed Delivery Date' width='150'
                        textAlign='Center'>
                      </e-column>
                      <e-column field='comments' headerText='Remarks' width='300' textAlign='Center'>
                      </e-column>

                    </e-columns>
                  </ejs-grid>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <fieldset>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label style="text-align: right;">Update new commitment Date:</label>
                </div>
                <div class="col-sm-8">
                  <div class="col-sm-8">
                    <ejs-datepicker formControlName="fulfillmentDate" [min]='minDate' placeholder="DD/MM/YYYY"
                      format='dd/MM/yyyy' placeholder='Enter date' class="form-control" (change)="changeDate()"
                      [ngClass]="{'is-invalid': (f['fulfillmentDate'].touched ||
                                              f['fulfillmentDate'].dirty) && !f['fulfillmentDate'].valid || (submitted && !f['fulfillmentDate'].valid)}">
                    </ejs-datepicker>
                    <div class="invalid-feedback">
                      <div *ngIf="f['fulfillmentDate'].hasError('required')">Date is required.</div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  <label>Upload Doc/Images:</label>
                </div>
                <div class="col-sm-8">

                  <button type="button" ejs-button cssClass="" class="documentUpload button_padding button_margin"
                    (click)="openMultipleFileUploadFormPopup()">Upload/View Documents</button>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">

                </div>
                <div class="col-sm-8">

                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-4">
                  Remarks:
                </div>
                <div class="col-sm-8">
                  <div class="e-float-input">
                    <textarea class="form-control" placeholder="Remark" formControlName="comments" rows="2">
                                </textarea>
                    <span class="e-float-line"></span>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>


      <div class="col-sm-12  card-header">
        <div class="col-sm-12 ">


          <button ejs-button cssClass="" class="submit button_padding button_margin buttonSize" type="submit">
            Submit</button>
          <!--  <button type="button" *ngIf="!editForm" ejs-button cssClass=""
            class="edit button_padding button_margin buttonSize" (click)="edit()">Edit</button> -->
          <button type="button" (click)="navigateToPurchase()" ejs-button cssClass=""
            class="back button_padding button_margin buttonSize">
            <i class="fa fa-arrow-left"></i>Back</button>
        </div>
      </div>



    </form>
  </div>
</div>



<ngx-ui-loader></ngx-ui-loader>
