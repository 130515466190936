import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../modules/core/model/model';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppComponent } from '../app.component';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { CustomValidator } from '../custom-validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordPopupComponent } from '../modules/shared/reset-password-popup/reset-password-popup.component';
import { ConfirmationDialogService } from '../confirmation-dailog/confirmation-dialog.service';
import { UserService } from '../modules/user-roles/service/user.service';
import { FotgetPasswordComponent } from '../modules/shared/fotget-password/fotget-password.component';
import { SessionUpdateService } from '../session-update-confirmation/session-update.service';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css'],

})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    currentUser: User;
    loggedInUser: any = {};
    userName: string;
    public size: number;
    public square: number;
    logo: any;
    logoMobile: any;
    isDirty = false;
    constructor(public appComp: AppComponent, private location: Location,
        private formBuilder: FormBuilder, private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router, private ngxLoader: NgxUiLoaderService,
        private globalService: GlobalServiceService, private toasterService: ToastrService,
        private confirmationDialogService: ConfirmationDialogService,
        private sessionUpdateService: SessionUpdateService,
        private userService: UserService,) {
        this.currentUser = {
            companyCode: '',
            userId: '',
            password: '',
            changePassword: null,
            pk: null,
            userName: ''
        }
    }

    ngOnInit() {
        this.loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
        console.log("current User ", this.loggedInUser);

        if (this.loggedInUser != undefined && this.loggedInUser != '' && this.loggedInUser != null) {
            this.router.navigateByUrl(`/`);
        }

        this.ngxLoader.start();
        this.logo = "assets/image/easemyprod-transp2.png";
        this.logoMobile = "assets/image/easemyprod1-Mobile.png";
        this.loginForm = this.formBuilder.group({
            companyCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20), CustomValidator.domainNameValidator,]],
            username: ['', [Validators.required,]],
            password: ['', [Validators.required,]]
        });
        this.loginForm.valueChanges.subscribe(e => this.isDirty = true);
        this.ngxLoader.stop();
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.ngxLoader.start();
        var ref = this;
        this.submitted = true;
        if (this.loginForm.invalid) {
            this.ngxLoader.stop();
            return;
        }
        let value = this.loginForm.value;
        this.currentUser.companyCode = value.companyCode;
        this.currentUser.userId = value.username;
        this.currentUser.password = value.password;
        this.loading = true;
        this.globalService.loginV2(this.currentUser)
            .subscribe((data: any) => {
                console.log(data);
                this.appComp.loggedInUser = data.data;
                let companyDetail: any = data.data;
                if (data.statusCode == 200) {
                    localStorage.setItem('isCompanyExpired', data.data.companyExpired);
                    this.isDirty = false;
                    if (data.data.changePassword == 1) {
                        this.router.navigate(['/password-reset'], { queryParams: { userId: data.data.userId, domainName: data.data.companyCode } });
                    } else {
                        this.isDirty = false
                        this.globalService.setCurrentUser2(data.data);
                        let loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
                        let userDetail: any = {
                            "userId": loggedInUser.userId,
                            "password": loggedInUser.password,
                            "refreshToken": loggedInUser.tokenBean.refresh_token
                        };

                        sessionStorage.setItem('tokenFlag', JSON.stringify(true));
                        this.globalService.setAccessToken(loggedInUser.tokenBean);

                        setTimeout(function () {
                            ref.router.navigate([`/financialYear/${data.data.pk}`]);
                            setTimeout(function () {
                                //window.location.reload();
                            }, 200);

                        }, 400);

                    }
                } else if (data.statusCode == 201) {
                    try {
                        this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
                        this.ngxLoader.stop();
                    } catch (e) {
                        this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
                        this.ngxLoader.stop();
                    }
                } else if (data.statusCode == 202) {
                    try {
                        this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
                        this.ngxLoader.stop();
                    } catch (e) {
                        this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
                        this.ngxLoader.stop();
                    }

                } else if (data.statusCode == 203) {
                    try {
                        this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
                        this.ngxLoader.stop();
                    } catch (e) {
                        this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
                        this.ngxLoader.stop();
                    }

                } else if (data.statusCode == 204) {
                    this.isDirty = false;
                    let loginObj: any = data.data;
                    if (loginObj.changePassword == 1) {
                        this.router.navigate(['/password-reset'], { queryParams: { userId: data.data.userId, domainName: data.data.companyCode } });
                    }
                    else {
                        try {

                            this.ngxLoader.stop();
                            this.isDirty = false
                            this.sessionUpdateService.confirm('Please confirm!', `${data.msg}`, `Do you really want continue ?`)
                                .then((confirmed) => {
                                    if (confirmed) {
                                        this.ngxLoader.start();

                                        this.globalService.updateUserSession(companyDetail.pk, companyDetail.companyCode).subscribe((data2) => {
                                            console.log("updated session ", data2);
                                            this.isDirty = false
                                            let updateSession = data2.data;

                                            companyDetail.sessionId = updateSession.sessionId;

                                            this.globalService.setCurrentUser2(companyDetail);
                                            let loggedInUser = JSON.parse(localStorage.getItem("currentUser"));


                                            sessionStorage.setItem('tokenFlag', JSON.stringify(true));
                                            this.globalService.setAccessToken(loggedInUser.tokenBean);

                                            setTimeout(function () {
                                                ref.router.navigate([`/financialYear/${data.data.pk}`]);
                                                console.log("navigated to financialYear")
                                                setTimeout(function () {
                                                    window.location.reload();
                                                }, 200);

                                            }, 400);
                                        }

                                        )

                                    }
                                })
                                .catch(() => console.log('User dismissed the dialog'));

                        } catch (e) {
                            this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
                            this.ngxLoader.stop();
                        }
                    }


                }
                this.ngxLoader.stop();
            })
    }

    forgetPassword() {
        const modalRef = this.modalService.open(ResetPasswordPopupComponent, { size: 'lg' });
        modalRef.componentInstance.userDataConfirm.subscribe((data: any) => {
            console.log("userData ", data);
            //this.changePassword(data);
        });
    }

    changePassword(data) {

        event.preventDefault();
        this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to change password ?')
            .then((confirmed: any) => {
                if (confirmed) {
                    this.ngxLoader.start();
                    this.userService.forgetPasswordWithoutLogin(data.userId, data.companyCode).subscribe((data) => {
                        console.log("forget password data ", data);
                        this.ngxLoader.stop();
                        if (data != null && data.statusCode == 200) {
                            this.modalService.open(FotgetPasswordComponent, { size: 'sm' });
                        } else if (data != null && data.statusCode == 201) {
                            this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
                        } else {
                            this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
                        }
                        //this.modalService.open(FotgetPasswordComponent, { size: 'sm' });
                    }, error => {
                        console.log("error ", error);
                        this.ngxLoader.stop();
                    });
                }

            }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));


    }

    canDeactivate() {
        return this.isDirty;
    }

}




