
import { Component, OnInit } from '@angular/core';
import { Facility } from '../modules/facility/models/facility';
import { FormGroup, FormBuilder, Validators, ValidatorFn, FormArray, FormControl } from '@angular/forms';
import { FacilityType } from '../modules/facility/models/facilityType';
import { Country } from '../company/models/country';
import { State } from '../company/models/state';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacilityService } from '../modules/facility/services/facility.service';
import { ConfirmationDialogService } from '../confirmation-dailog/confirmation-dialog.service';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-add-user-facility',
  templateUrl: './add-user-facility.component.html',
  styleUrls: ['./add-user-facility.component.css']
})
export class AddUserFacilityComponent implements OnInit {
  facility: Facility;
  submitted: boolean = false;
  isEdit: boolean = false;
  facilityForm: FormGroup;
  facilityTypes: FacilityType[];
  countries: Country[];
  states: State[];
  allowedOperation: any[];
  chkval: boolean = false;
  userPk: number;
  isManufacture: boolean = false;
  warehouseList: any[] = [];
  public faciliTyfields: Object = { text: "descr", value: "id" };
  public countryFields: Object = { text: "countryName", value: "countryId" };
  public statusFields: Object = { text: "statusName", value: "statusId" };
  public statefields: Object = { text: "stateName", value: "stateId" };
  public statusDropDn: { [key: string]: Object }[] = [
    { statusId: 1, statusName: 'Active' },
    { statusId: 2, statusName: 'Inactive' }
  ];
  constructor(private route: ActivatedRoute, private ngxLoader: NgxUiLoaderService,
    private modalService: NgbModal, private facilityService: FacilityService, private router: Router, private confirmationDialogService: ConfirmationDialogService, private formBuilder: FormBuilder,) {
    this.facility = {
      id: 0,
      name: '',
      typeId: 0,
      address: '',
      gstNo: '',
      phone: '',
      mobile: '',
      countryId: 0,
      stateId: 0,
      pincode: '',
      cityName: '',
      email: "",
      allowedOperation: '',
      statusId: 1,
      shortName: '',
      linkedWarehouseId:0,
      finishWhId:0
    }
  }

  get f() { return this.facilityForm.controls; }
  onSubmit() {
    this.ngxLoader.start();
    this.submitted = true;
    if (this.facilityForm.invalid) {
      console.log("facility form data-",this.facilityForm.value);
      this.ngxLoader.stop();
      return;
    }
    let value: any = this.facilityForm.value
    this.facility.name = value.name;
    this.facility.statusId = value.statusId;
    this.facility.typeId = value.facilityTypeId;
    this.facility.address = value.address;
    this.facility.gstNo = value.gstNo;
    this.facility.phone = value.phone;
    this.facility.mobile = value.mobile;
    this.facility.countryId = value.countryId;
    this.facility.stateId = value.stateId;
    this.facility.cityName = value.cityName;
    this.facility.email = value.email;
    this.facility.shortName = value.shortName;
    this.facility.allowedOperation = value.allowedOperation
      .map((v, i) => v ? this.allowedOperation[i].id : null)
      .filter(v => v !== null);
    this.facility.pincode = value.pincode;
    this.facilityService.addFacility(this.facility).subscribe(
      (data: any) => {
        this.ngxLoader.stop();
        if (data.statusCode == 200) {
          this.router.navigateByUrl(`/facility/${this.userPk}`)
        } else alert('Something Went Wrong.\n' + "Server Response : " + data.msg);

      },
      (err) => {
        console.log(err);
        this.ngxLoader.stop();
      }
    )
  }

  ngOnInit() {
    this.ngxLoader.start();
    this.getAllowedOperation();
    this.getFacilityType();
    this.getCountry();

    this.facilityForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      facilityTypeId: ['', Validators.required],
      address: ['', [Validators.required, Validators.maxLength(250)]],
      countryId: ['', Validators.required],
      stateId: ['', Validators.required],
      cityName: ['', [Validators.required, Validators.maxLength(50)]],
      gstNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]],
      mobile: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
      phone: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
      pincode: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(8)]],
      email: ['', [Validators.required, Validators.maxLength(100)]],
      allowedOperation: this.formBuilder.array([], this.minSelectedCheckboxes(1)),
      statusId: [1, Validators.required],
      shortName: ['', [Validators.required, Validators.minLength(3), Validators.required, Validators.maxLength(5)]]
    })

    this.route.paramMap.subscribe(
      params => {
        this.userPk = +params.get('pk');
      }
    )
    this.facilityForm.get('facilityTypeId').valueChanges.subscribe(
      value => this.getManufacturingAllowedOperation(value)
    );
    this.ngxLoader.stop();
  }
  getManufacturingAllowedOperation(value) {
    if (value == 2) {
      this.isManufacture = true;
      this.facilityForm.patchValue({
        "allowedOperation": this.allowedOperation.map((x) => x = true)
      })
    }
    else {
      this.isManufacture = false;
      this.facilityForm.patchValue({
        "allowedOperation": this.allowedOperation.map((x) => x = false)
      })
    }

  }
  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  getAllowedOperation() {
    this.facilityService.getCategoryType().subscribe(
      (data: any) => {

        this.allowedOperation = data.data;
        this.addCheckboxes();

      }
    )
  }

  getFacilityType() {
    this.facilityService.getFacilityType().subscribe(
      (data: any) => {

        this.facilityTypes = data.data;

      }
    )
  }

  getCountry() {
    this.facilityService.getCountry().subscribe(
      (data: any) => {

        this.countries = data.data;

      }
    )
  }

  getState(countryId: number) {
    this.facilityService.getStateByCountryId(countryId).subscribe(
      (data: any) => {

        this.states = data.data;

      }
    )
  }

  getFacility(id: number): void {
    this.ngxLoader.start();
    this.facilityService.getFacilityById(id).subscribe(
      (data: any) => {
        this.ngxLoader.stop();
        this.facility = data.data;
        this.getState(this.facility.countryId);
        this.facilityForm.patchValue({
          "name": this.facility.name,
          "statusId": this.facility.statusId,
          "facilityTypeId": this.facility.typeId,
          "address": this.facility.address,
          "gstNo": this.facility.gstNo,
          "phone": this.facility.phone,
          "mobile": this.facility.mobile,
          "countryId": this.facility.countryId,
          "stateId": this.facility.stateId,
          "pincode": this.facility.pincode,
          "cityName": this.facility.cityName,
          "email": this.facility.email,
          "shortName": this.facility.shortName
        });
        if (this.allowedOperation.length > 0) {
          this.facilityForm.patchValue({
            "allowedOperation": this.allowedOperation.map((x) => this.chekval(x.id))
          })
        } else {
          this.facilityService.getCategoryType().subscribe(
            (data: any) => {
              this.allowedOperation = data.data;
              if (this.allowedOperation.length > 0) {
                this.facilityForm.patchValue({
                  "allowedOperation": this.allowedOperation.map((x) => this.chekval(x.id))
                })
              }
            }
          )
        }
        this.ngxLoader.stop();
      },
      (err: any) => {
        console.log(err);
        this.ngxLoader.stop();
      }
    )
  }

  chekval(x) {
    let y: boolean = false
    this.facility.allowedOperation.forEach(element => {
      if (element == x) { y = true; }
    })
    return y;
  }


  resetFacilityForm(): void {
    this.facilityForm.reset()
    this.facilityForm.patchValue({
      "name": "",
      "statusId": "",
      "facilityTypeId": '',
      "address": "",
      "gstNo": "",
      "phone": '',
      "mobile": '',
      "countryId": '',
      "stateId": '',
      "pincode": "",
      "email": "",
      "cityName": "",
      "shortName": ""
    });
  }

  onCountryChanged(event: any) {
    let countryId = event.target.value;
    this.getState(countryId);
  }

  changeCountry(event: any) {
    let countryId = event.value;
    console.log("countryId-",event);
      this.facilityForm.patchValue({
        "stateId": '',
        "pincode": null,
        "cityName": null
      });
      this.getState(countryId);
  }

  private addCheckboxes() {
    this.allowedOperation.map((x, y) => {
      const control = new FormControl(); // if first item set to true, else false
      (this.facilityForm.controls['allowedOperation'] as FormArray).push(control);
    });
  }

  setAttribute() {
    $("#ddlelement_popup .e-input").attr("placeholder", "Type here to search");
  }

}
