import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../modules/user-roles/service/user.service';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { SuccessMsgModalComponent } from '../modules/shared/success-msg-modal/success-msg-modal.component';
import { UserProfileUploadPopupComponent } from '../modules/shared/user-profile-upload-popup/user-profile-upload-popup.component';
import { UploadedImageDetail } from '../modules/item/models/uploadImageDetail';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userForm: FormGroup;
  submitted: boolean = false;
  user: any;
  userPk: any;
  isEdit: boolean = false;
  uploadedImageDetails: any[] = [];
  savedImageDetails: UploadedImageDetail[];
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private location: Location, private ngxLoader: NgxUiLoaderService, private userService: UserService, private globalService: GlobalServiceService) {
    this.user = {
      'userName': null,
      'pk': null,
      'mobileNumber': null
    }
  }
  get f() { return this.userForm.controls; }
  ngOnInit() {
    this.userForm = this.formBuilder.group({
      userName: ['', Validators.required],
      userId: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
    });

    this.getUser();
    this.getProfilePictureUrl();
  }

  navigateToUser(): void {
    this.location.back();
  }

  resetUserForm(): void {
    this.userForm.reset();
    this.userForm.patchValue({
      userName: [''],
      userId: [''],
      mobileNumber: [''],
    });
  }

  onSubmit() {
    this.ngxLoader.start();
    this.submitted = true;
    if (this.userForm.invalid) {
      this.ngxLoader.stop();
      return;
    }
    let value: any = this.userForm.value
    this.user.userName = value.userName;
    this.user.mobileNumber = value.mobileNumber;
    this.user.pk = this.userPk;
    this.userService.updateUser(this.user).subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.modalService.open(SuccessMsgModalComponent, { size: 'sm' });
      },
      (err) => {
        console.log(err);
        this.ngxLoader.stop();
      }
    )
  }

  getUser(): void {
    this.ngxLoader.start();
    this.globalService.currentUser.subscribe(
      (data: any) => {
        this.ngxLoader.stop();
        let userInfo: any = data;
        this.userPk = userInfo.pk;
        console.log("user", userInfo);
        this.userForm.patchValue({
          userName: userInfo.userName,
          userId: userInfo.userId,
          mobileNumber: userInfo.mobileNumber
        });
      },
      (err: any) => {
        console.log(err);
        this.ngxLoader.stop();
      }
    )
  }


  openMultipleImageUploadFormPopup() {

    const modalRef = this.modalService.open(UserProfileUploadPopupComponent, { size: 'sm' });

    if (this.userPk != 0) {
      modalRef.componentInstance.modalData = { "formPk": this.userPk };

    }

    modalRef.componentInstance.itemData.subscribe((imageDetails) => {

      if (imageDetails == null || imageDetails.length == 0) {
        this.savedImageDetails = [{
          "description": '',
          "extension": '',
          "fileKey": '',
          "fileName": '',
          "fileOrder": 0,
          "formPk": this.userPk,
          "formTypeId": 0,
          "pk": 0,
        }];
      } else {
        this.uploadedImageDetails = imageDetails;
        this.savedImageDetails = imageDetails;
      }

      if (this.savedImageDetails != undefined && this.savedImageDetails != null) {
        this.savedImageDetails.forEach(element => {
          element.formTypeId = 0;
          element.pk = 0;
          element.fileOrder = element.fileOrder;
          element.formPk = this.userPk;
        });

        console.log("saved image details ", this.savedImageDetails);
        //this.getCompany(this.domainName);
        //this.saveImageDetail(this.savedImageDetails);
        this.updateUserProfilePicture(this.savedImageDetails[0].fileKey);
      }

    });


  }

  updateUserProfilePicture(fileKey: any) {
    this.userService.updateUserProfilePicture(fileKey).subscribe((data) => {

      this.getProfilePictureUrl();
      console.log("picture response ", data);
    });
  }

  profilePic: any = '';
  getProfilePictureUrl() {
    this.userService.getProfilePicUrl().subscribe((data) => {
      console.log("Profile picture Url ", data);
      this.profilePic = data.data[0].imageUrl;
      if (this.profilePic != '' && this.profilePic != null) {
        this.globalService.setProfilePic(this.profilePic);
      }

    });
  }

}
