<div class="container-fluid">
    <div class="row ">
        <div class="col-sm-6 login-page-tathata left-logo-bg login-logo-mobile">
            <img src={{logo}} class="img-responsive login-page-mobile-res" id="customLogo">
            <img src={{logoMobile}} class="img-responsive" id="customLogoMobile">
        </div>
        <div class="col-sm-6 login-page-form">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="LoginContainer login-form-mobile">
                <h2 class="loginTitle login-title-mobile">Login</h2>
                <div class="form-group">
                    <label for="companyCode">Domain Name</label>
                    <div class="e-float-input">
                        <input type="text" formControlName="companyCode" class="form-control"
                            [ngClass]="{ 'is-invalid':(f['companyCode'].touched || f['companyCode'].dirty) && !f['companyCode'].valid || (submitted && !f['companyCode'].valid) }" />
                        <span class="e-float-line"></span>
                        <div *ngIf="f['companyCode'].errors" class="invalid-feedback">
                            <div *ngIf="f['companyCode'].hasError('required')">Company Code is required</div>
                            <div *ngIf="f['companyCode'].hasError('minlength')">Company Code should be minimum 3
                                characters.
                            </div>
                            <div *ngIf="f['companyCode'].hasError('maxlength')">Company Code should be maximum 20
                                characters.
                            </div>
                            <div *ngIf="f['companyCode'].hasError('invalidDomainName')">Special character and space are
                                not
                                allowed</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="username">Username</label>
                    <div class="e-float-input">
                        <input type="text" formControlName="username" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" />
                        <span class="e-float-line"></span>
                        <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                            <div *ngIf="f['username'].hasError('required')">Username is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <div class="e-float-input">
                        <input type="password" formControlName="password" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                        <span class="e-float-line"></span>
                        <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                            <div *ngIf="f['password'].hasError('required')">Password is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-10 marginleft">
                            <button ejs-button cssClass="" id="login-user"
                                class="login-button button_padding button_margin login-button-size"
                                type="submit">Login</button>
                            <button type="button" [routerLink]="['/landing']" ejs-button cssClass=""
                                class="login-back button_padding button_margin login-back-size"><i
                                    class="fa fa-arrow-left"></i>
                                back</button>
                            <div class="forgot-password">
                                <a class="forgot-password-link" href="javascript:void(0)"
                                    (click)="forgetPassword()">Forgot Password</a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>