import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmationDialogService } from 'src/app/confirmation-dailog/confirmation-dialog.service';
import { UserService } from '../../user-roles/service/user.service';
import { FotgetPasswordComponent } from '../fotget-password/fotget-password.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-reset-password-popup',
  templateUrl: './reset-password-popup.component.html',
  styleUrls: ['./reset-password-popup.component.css']
})
export class ResetPasswordPopupComponent implements OnInit {

  @Input() public changePwd;
  @Input() public userData;
  @Output() userDataConfirm: EventEmitter<any> = new EventEmitter();
  userForm: FormGroup;
  isEdit: false;
  constructor(public activeModal: NgbActiveModal, private http: HttpClient,
    private formBuilder: FormBuilder, private toasterService: ToastrService,
    private confirmationDialogService: ConfirmationDialogService, private userService: UserService,
    private modalService: NgbModal,) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      companyCode: ['', [Validators.required, Validators.maxLength(50)]],
      userId: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(2)]]
    });
  }

  done() {

    this.changePassword(this.userForm.value);

  }
  closeModal() {
    //this.userDataConfirm.emit(false);
    this.activeModal.close();
  }
  get f() { return this.userForm.controls; }


  changePassword(data) {

    event.preventDefault();
    this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to change password ?')
      .then((confirmed: any) => {
        if (confirmed) {
          //this.ngxLoader.start();
          this.userService.forgetPasswordWithoutLogin(data.userId, data.companyCode).subscribe((data) => {
            console.log("forget password data ", data);
            // this.ngxLoader.stop();
            if (data != null && data.statusCode == 200) {
              this.userDataConfirm.emit(this.userForm.value);
              this.activeModal.close();
              this.modalService.open(FotgetPasswordComponent, { size: 'sm' });
            } else if (data != null && data.statusCode == 201) {
              this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
            } else {
              this.toasterService.error(data.statusCode, data.msg, { positionClass: 'toast-top-right' });
            }
            //this.modalService.open(FotgetPasswordComponent, { size: 'sm' });
          }, error => {
            console.log("error ", error);
            //this.ngxLoader.stop();
          });
        }

      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));


  }

}
