import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { UpladServicesService } from "../../../modules/shared/sharedServices/uplad-services.service";

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalServiceService } from '../../core/services/global-service.service';
@Component({
  selector: 'app-modal-multiple-fileupload-popup',
  templateUrl: './modal-multiple-fileupload-popup.component.html',
  styleUrls: ['./modal-multiple-fileupload-popup.component.css'],
  providers: [NgxUiLoaderService],
  encapsulation: ViewEncapsulation.None
})
export class ModalMultipleFileuploadPopupComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: any = {};
  @Input() public modalData;
  @Output() itemData: EventEmitter<any> = new EventEmitter();
  selectedItem: any = [];
  documentUploadForm: FormGroup;
  buttonFlag: boolean = false;
  cancelledbtnFlag: boolean = false;
  //statusId:any;
  isDeleteAccess: boolean = true;
  serverDate: any;
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder, private globalService: GlobalServiceService,
    private uploadService: UpladServicesService, private ngxLoader: NgxUiLoaderService
  ) {

  }

  ngOnInit() {
    this.ngxLoader.start();
    /** document uploading form By Mukesh */
    this.documentUploadForm = this.formBuilder.group({
      documents: this.formBuilder.array([
        this.adddocumentsFormGroup()
      ])
    });
    if (this.modalData != undefined && this.modalData != null && this.modalData.isDeleteAccess != undefined) {
      this.isDeleteAccess = this.modalData.isDeleteAccess;
    }
    if (this.modalData != undefined && this.modalData != null && this.modalData.formPk != undefined && this.modalData.formTypeId != undefined) {
      console.log(this.modalData);
      this.getUploadedFileDetails(this.modalData.formTypeId, this.modalData.formPk);
    }

    if (this.modalData != undefined && this.modalData.statusId != undefined && this.modalData.statusId != null) {
      if ((this.modalData.statusId == 16) || (this.modalData.statusId == 0)) {
        this.buttonFlag = false;
      } else {
        this.buttonFlag = true;
      }
    }

    if (this.modalData != undefined && this.modalData.statusId != undefined && this.modalData.statusId != null) {
      if ((this.modalData.statusId == 6) || (this.modalData.statusId == 14)) {
        this.cancelledbtnFlag = true;
      } else {
        this.cancelledbtnFlag = false;
      }
    }
    this.getServerDate();
    this.ngxLoader.stop();
  }


  adddocumentsFormGroup(): FormGroup {
    return this.formBuilder.group({
      docDescription: [''],
      document: ['']
    });
  }
  ngAfterViewInit(): void {

  }
  closeModal() {
    this.activeModal.close();
    this.documentUploadForm.reset();
  }


  updateIconsClass(ext) {

    if (ext.toLowerCase() == ".png" || ext.toLowerCase() == ".jpeg" || ext.toLowerCase() == ".jpg" || ext.toLowerCase() == ".gif" || ext.toLowerCase() == ".tiff") {
      return "fa-file-image-o";
    } else if (ext.toLowerCase() == ".pdf") {
      return "fa-file-pdf-o";
    } else if (ext.toLowerCase() == ".xlsx") {
      return "fa-file-excel-o";
    } else if (ext.toLowerCase() == ".docx" || ext.toLowerCase() == ".doc") {
      return "fa-file-word-o";
    } else if (ext.toLowerCase() == ".sql") {
      return "fa-database";
    } else if (ext.toLowerCase() == ".txt") {
      return "fa-file-text-o";
    } else if (ext.toLowerCase() == ".pptx" || ext.toLowerCase() == ".ppt") {
      return "fa-file-powerpoint-o";
    }
    else if (ext.toLowerCase() == ".csv") {
      return "fa-file-excel-o";
    } else {
      return "fa-file-o";
    }
  }

  getFileTitle(ext) {
    if (ext.toLowerCase() == ".png" || ext.toLowerCase() == ".jpeg" || ext.toLowerCase() == ".jpg" || ext.toLowerCase() == ".gif" || ext.toLowerCase() == ".tiff") {
      return "Image file";
    } else if (ext.toLowerCase() == ".pdf") {
      return "Pdf file";
    } else if (ext.toLowerCase() == ".xlsx") {
      return "Excel file";
    } else if (ext.toLowerCase() == ".docx") {
      return "Forld file"
    } else if (ext.toLowerCase() == ".sql") {
      return "Sql file";
    } else if (ext.toLowerCase() == ".txt") {
      return "Text file";
    } else if (ext.toLowerCase() == ".pptx" || ext.toLowerCase() == ".ppt") {
      return "Power point File";
    }
    else if (ext.toLowerCase() == ".csv") {
      return "CSV File";
    } else {
      return "Simple File";
    }
  }
  /* file uplaoding Demo */
  fileList: any[] = [];
  fileSizeValidations: any[] = [{ "size": 0 }];
  imageSizeValidationFlag: boolean = false;
  uploadFileOnChange(event, index: number) {
    this.imageSizeValidationFlag = false;
    //console.log(event.target.files);
    this.fileList.push(event.target.files[0]);
    console.log("image ", event.target.files);

    var fileSize = event.target.files[0].size / 1024 / 1024;
    this.fileSizeValidations[index].size = fileSize;
    console.log("file size ", this.fileSizeValidations[index].size);

    (<FormArray>this.documentUploadForm.get('documents')).at(index).value.document = event.target.files[0];
    this.fileSizeValidations.forEach(element => {
      if (element.size > 10) {
        this.imageSizeValidationFlag = true;
      }
    });
  }


  addDocument(): void {
    (<FormArray>this.documentUploadForm.get('documents')).push(this.adddocumentsFormGroup());
    this.fileSizeValidations.push({ "size": 0 });
  }

  deleteFile(index: number) {
    this.imageSizeValidationFlag = false;
    (<FormArray>this.documentUploadForm.get('documents')).removeAt(index);
    this.fileSizeValidations.splice(index, 1);
    this.fileSizeValidations.forEach(element => {
      if (element.size > 10) {
        this.imageSizeValidationFlag = true;
      }
    });

    if ((<FormArray>this.documentUploadForm.get('documents')).length == 0) {
      (<FormArray>this.documentUploadForm.get('documents')).push(this.adddocumentsFormGroup());
      this.fileSizeValidations.push({ "size": 0 });
    }
  }

  addDescriptionToImage(index: number) {
    (<FormArray>this.documentUploadForm.get('documents')).at(index).value.document = this.fileList[index];
  }

  files: any[] = [];
  fileDesc: any[] = [];
  tempFileCollection: any[] = [];
  multipleFilesRes: any[] = [];
  fileDownLoadUrl: string;
  validation: any[] = [];
  fileValid: any[] = [];
  isValid: boolean
  uploadFilesOnServer(docsForm: FormGroup) {
    this.ngxLoader.start();
    this.files = [];
    this.fileDesc = [];
    this.isValid = true;

    this.tempFileCollection = docsForm.get('documents').value;
    for (var i = 0; i < this.tempFileCollection.length; i++) {
      this.validation[i] = false;
      this.fileValid[i] = false;

      if (this.tempFileCollection[i].docDescription != null && this.tempFileCollection[i].docDescription.length == 0) {
        this.validation[i] = true;
        this.isValid = false;
      }

      if (this.tempFileCollection[i].document == undefined) {
        this.fileValid[i] = true;
        this.isValid = false;
      }
      this.files.push(this.tempFileCollection[i].document);
      this.fileDesc.push(this.tempFileCollection[i].docDescription);
    }
    if (this.multipleFilesRes.length == 0) {
      this.multipleFilesRes = [];
    }

    if (this.isValid) {
      this.uploadService.uploadMultipleFiles(this.files, this.fileDesc).subscribe((data) => {
        this.ngxLoader.stop();
        data.forEach(element => {
          element.action = 1;
          if (this.modalData != undefined && this.modalData.fulfillmentFlag) {
            element.fulfillmentFlag = this.modalData.fulfillmentFlag;
            this.multipleFilesRes.push(element);
          } else {
            this.multipleFilesRes.push(element);
          }

        });

        for (var i = 0; i < (<FormArray>this.documentUploadForm.get('documents')).length; i++) {
          this.fileSizeValidations.splice(i, 1);
          (<FormArray>this.documentUploadForm.get('documents')).removeAt(i);

        }

        if ((<FormArray>this.documentUploadForm.get('documents')).length == 0) {
          (<FormArray>this.documentUploadForm.get('documents')).push(this.adddocumentsFormGroup());
          this.fileSizeValidations.push({ "size": 0 });
        }

        (<FormArray>this.documentUploadForm.get('documents')).reset();
        this.documentUploadForm.reset();

      }, (error) => {
        console.log(error);
        this.ngxLoader.stop();

      });
    } else {
      this.ngxLoader.stop();
    }
  }
  downloadUploadedFile(fileKey) {
    this.uploadService.downloadFile(fileKey).subscribe((data) => {
      this.fileDownLoadUrl = data;
      console.log(this.fileDownLoadUrl);

      window.open(this.fileDownLoadUrl, "new");
    }, (error) => {
      console.log(error);
    });
  }

  deleteFileDetail(index) {
    this.multipleFilesRes[index].action = 2;
    //this.multipleFilesRes.splice(index, 1);
  }

  done() {
    this.itemData.emit(this.multipleFilesRes);
    this.activeModal.close();
  }

  getUploadedFileDetails(formTypeId, formPk) {
    this.uploadService.getUploadedFileDetails(formTypeId, formPk).subscribe((data) => {
      console.log(data);
      if (data.data != null) {
        this.multipleFilesRes = data.data;
      } else {
        this.multipleFilesRes = [];
      }
    }, (error) => {
      console.log(error);
    });
  }

  getAllControls(form) {
    return form.get('documents').controls;
  }

  getServerDate() {
    this.globalService.getServerDate().subscribe(data => {
      this.serverDate = data.data;

    });
  }
}
