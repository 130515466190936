import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ZohoConfirmDailogComponent } from './zoho-confirm-dailog.component';
import { HeaderConfiguration } from '../headerConfiguration';

@Injectable()
export class ZohoConfirmDialogService {

  //requestHeader = HeaderConfiguration.headerConfg;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();

  constructor(private modalService: NgbModal) {
    console.log("service constructor called");
   }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'Proceed',
    btnCancelText: string = 'Enter later',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ZohoConfirmDailogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }
}
