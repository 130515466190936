import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-auth500error',
  templateUrl: './modal-auth500error.component.html',
  styleUrls: ['./modal-auth500error.component.css']
})
export class ModalAuth500errorComponent implements OnInit {

  @Input() public modalData;
  @Output() itemData: EventEmitter<any> = new EventEmitter();

  errorMessage: string = '';

  constructor(public activeModal: NgbActiveModal, private http: HttpClient) {

  }

  ngOnInit() {

    console.log("modalData", this.modalData)
    this.errorMessage = this.modalData.errorMsg;
  }
  done() {

    this.itemData.emit("OK");
    this.activeModal.close();
  }
  closeModal() {
    this.activeModal.close();
  }
  ngAfterViewInit(): void {

  }







}
