import { NgModule } from '@angular/core';
import { FormatDecimalRateDiscountNumberPipe } from './format-decimal-rate-discount-number.pipe';
import { FormatItemDecimalNumberPipe } from './format-item-decimal-number.pipe';
@NgModule({
    declarations: [
        FormatItemDecimalNumberPipe,
        FormatDecimalRateDiscountNumberPipe
    ],
    imports: [],
    exports: [
        FormatItemDecimalNumberPipe,
        FormatDecimalRateDiscountNumberPipe
    ]
})
export class PipesModule {

}