import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { AuthGuard } from './_guards';
import { LandingComponent } from './landing/landing.component';
import { PasswordResetComponent } from './password-reset';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainContentComponent } from './main-content/main-content.component';
import { UserFacilityComponent } from './user-facility/user-facility.component';
import { AddUserFacilityComponent } from './add-user-facility/add-user-facility.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FinancialYearComponent } from './financial-year/financial-year.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SuccessMessageComponent } from './success-message/success-message.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { DemoComponent } from './demo/demo.component';
import { UserSignupComponent } from './user-signup/user-signup.component';
import { OuterServiceStatusComponent } from './outer-service-status/outer-service-status.component';
import { UserSignup2Component } from './user-signup2/user-signup2.component';
import { UserSignup3Component } from './user-signup3/user-signup3.component';
import { AddFinancialYearFirtTimeComponent } from './add-financial-year-firt-time/add-financial-year-firt-time.component';
import { TransactionSavedComponent } from './transaction-saved/transaction-saved.component';
import { MailNavigateComponent } from './mail-navigate/mail-navigate.component';
import { PoFullfilmentPoDetailsComponent } from './po-fullfilment-po-details/po-fullfilment-po-details.component';
import { SuccessMessageMailNavigateComponent } from './success-message-mail-navigate/success-message-mail-navigate.component';
import { DirtyCheckGuard } from './can-deactivate/dirty-check.guard';
import { ScanQrcodeOrdersComponent } from './scan-qrcode-orders/scan-qrcode-orders.component';

const appRoutes: Routes = [
  {
    path: 'landing', component: LandingComponent,
    data: { breadcrumb: 'Landing page' }
  },
  {
    path: '', component: MainContentComponent, canActivate: [AuthGuard],
    data: { breadcrumb: '' },
    children: [
      {
        path: 'inventory/:id',
        loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),//../modules/navbar/navbar.module#NavbarModule
        data: { breadcrumb: 'Inventory' }
      },
      {
        path: 'dashboard/:id',
        loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        //loadChildren: './modules/navbar/navbar.module#NavbarModule',
        data: { breadcrumb: 'Dashboard' }
      },
      {
        path: 'manufacturing/:id', loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'Manufacturing' }
      },
      {
        path: 'order-processing/:id', loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'Order Processing' }
      },
      {
        path: 'settings/:id', loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'Setting' }
      },
      {
        path: 'channel/:id', loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'Channel' }
      },
      {
        path: 'admin/:id', loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'Tathata admin' }
      },
      {
        path: 'user-profile', component: UserProfileComponent,
        data: { breadcrumb: 'User profile' }
      },
      {
        path: 'change-password', component: ChangePasswordComponent,
        data: { breadcrumb: 'Change password' }
      },
      {
        path: 'manufacturing_free/:id', loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'Manufacturing Free' }
      },
      {
        path: 'inventory_pro/:id', loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'Inventory Pro' }
      },
      {
        path: 'manufacturing_pro/:id', loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'Manufacturing Pro' }
      },
      {
        path: 'integration/:id', loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'Integration' }
      },
      {
        path: 'dealer_portal/:id',
        loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'dealer_portal' }
      },
      {
        path: 'dealer_central_orders/:id',
        loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule),
        data: { breadcrumb: 'dealer_central_orders' }
      },

    ]
  },

  { path: 'login', component: LoginComponent, canDeactivate: [DirtyCheckGuard] },
  { path: 'register', component: RegisterComponent },
  { path: 'signup', component: UserSignupComponent },
  { path: 'signup2', component: UserSignup2Component },
  { path: 'signup3', component: UserSignup3Component },
  { path: 'trasaction-saved', component: TransactionSavedComponent },
  { path: 'service-status', component: OuterServiceStatusComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'success-message', component: SuccessMessageComponent },
  { path: 'success-message-mail', component: SuccessMessageMailNavigateComponent },
  { path: 'error-message', component: ErrorMessageComponent },
  { path: 'demo', component: DemoComponent },
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuard], data: { breadcrumb: 'User Profile' } },
  { path: 'password-reset', component: PasswordResetComponent, data: { breadcrumb: 'Password Reset' } },
  { path: 'financialYear/:pk', component: FinancialYearComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Financial year' } },
  { path: 'add-financial-year/:pk', component: AddFinancialYearFirtTimeComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Financial year' } },
  { path: 'facility/:pk', component: UserFacilityComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Facility' } },
  { path: 'add-facility/:pk', component: AddUserFacilityComponent, canActivate: [AuthGuard], data: { breadcrumb: 'Add facility' } },
  { path: 'tempurl', component: MailNavigateComponent },
  { path: 'po-fulfillment-date/po-details/:id', component: PoFullfilmentPoDetailsComponent },
  {
    path: '', component: ScanQrcodeOrdersComponent,
    children: [
      {
        path: 'scan-orders',
        loadChildren: () => import('./modules/navbar/navbar.module').then((m) => m.NavbarModule), data: { breadcrumb: 'Load order' }
      },
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

export const routing = RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: PreloadAllModules });
