import { HttpHeaders } from "@angular/common/http";

export class HeaderConfiguration {
    static currentUser = null;//JSON.parse(localStorage.getItem("currentUser"));
    static facilityId = null;//localStorage.getItem("facilityId");
    static headerConfg = null;//new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'facilityId': (HeaderConfiguration.facilityId != null) ? HeaderConfiguration.facilityId : '1', 'companyCode': (HeaderConfiguration.currentUser != null) ? HeaderConfiguration.currentUser.companyCode.toLowerCase() : '' });
    static multiPartHeaderConfig = null;
    static facility = null;



     static getHeaderConfiguration() {
        HeaderConfiguration.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        HeaderConfiguration.facility = JSON.parse(sessionStorage.getItem('facility'));
        HeaderConfiguration.headerConfg = new HttpHeaders({
         'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*',
         'facilityId': HeaderConfiguration.facility !== null ? HeaderConfiguration.facility.id.toString() : '1',
         'companyCode': (HeaderConfiguration.currentUser != null && HeaderConfiguration.currentUser.companyCode != undefined) ? HeaderConfiguration.currentUser.companyCode.toLowerCase() : '' });

         return HeaderConfiguration.headerConfg;
    }

    static getHeaderConfigurationGlobal(){
      HeaderConfiguration.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        HeaderConfiguration.facility = JSON.parse(sessionStorage.getItem('facility'));
        HeaderConfiguration.headerConfg = new HttpHeaders({
         'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*',
         'facilityId': HeaderConfiguration.facility !== null ? HeaderConfiguration.facility.id.toString() : '1',
         'userPk': HeaderConfiguration.currentUser != null ? HeaderConfiguration.currentUser.pk : '',
         'sessionId': (HeaderConfiguration.currentUser != null && HeaderConfiguration.currentUser.sessionId) ? HeaderConfiguration.currentUser.sessionId : '',
         'companyCode': (HeaderConfiguration.currentUser != null && HeaderConfiguration.currentUser.companyCode != undefined) ? HeaderConfiguration.currentUser.companyCode.toLowerCase() : '' });

         return HeaderConfiguration.headerConfg;
    }

    static getHeaderConfigWithMultiPartConfiguration() {
        HeaderConfiguration.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        HeaderConfiguration.facility = JSON.parse(sessionStorage.getItem('facility'));
        HeaderConfiguration.multiPartHeaderConfig = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'facilityId': (HeaderConfiguration.facility != null) ? HeaderConfiguration.facility.id.toString() : '1', 'companyCode': (HeaderConfiguration.currentUser != null && HeaderConfiguration.currentUser.companyCode != undefined) ? HeaderConfiguration.currentUser.companyCode.toLowerCase() : '' });
        return HeaderConfiguration.multiPartHeaderConfig;
    }

    static getFacilityId() {
        HeaderConfiguration.facility = JSON.parse(sessionStorage.getItem('facility'));
        return HeaderConfiguration.facility.id;
    }

    static getCompanyCode() {
        HeaderConfiguration.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        return HeaderConfiguration.currentUser.companyCode;
    }

    static getHeaderConfObject() {
        HeaderConfiguration.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        HeaderConfiguration.facility = JSON.parse(sessionStorage.getItem('facility'));

        HeaderConfiguration.headerConfg = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'facilityId': (HeaderConfiguration.facility != null) ? HeaderConfiguration.facility.id.toString() : '1', 'companyCode': (HeaderConfiguration.currentUser != null && HeaderConfiguration.currentUser.companyCode != undefined) ? HeaderConfiguration.currentUser.companyCode.toLowerCase() : '' });
        //return new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'facilityId': (HeaderConfiguration.facility != null) ? HeaderConfiguration.facility.id.toString() : '1', 'companyCode': (HeaderConfiguration.currentUser != null && HeaderConfiguration.currentUser.companyCode != undefined) ? HeaderConfiguration.currentUser.companyCode.toLowerCase() : '' });
        let Obj = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'facilityId': (HeaderConfiguration.facility != null) ? HeaderConfiguration.facility.id.toString() : '1', 'companyCode': (HeaderConfiguration.currentUser != null && HeaderConfiguration.currentUser.companyCode != undefined) ? HeaderConfiguration.currentUser.companyCode.toLowerCase() : '' }
        return Obj;
    }
}
