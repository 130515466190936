import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map, retry } from 'rxjs/operators';
import { HeaderConfiguration } from 'src/app/headerConfiguration';
import { ErrorMsg } from '../../group/model/errormsg';
import { UploadedFileDetail } from '../../sales-order/model/uploadedFileDetail';

@Injectable({
  providedIn: 'root'
})
export class GlobalTmpUrlBaseService {
  baseurl = environment.apiUrl;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();
  requestHeaderMultipartFormData = HeaderConfiguration.getHeaderConfigWithMultiPartConfiguration();
  constructor(private http: HttpClient, private router: Router) {
    console.log("global-tmp-url-base.service.ts");
   }

  private handleHttpError(error: HttpErrorResponse): Observable<ErrorMsg> {
    let dataError = new ErrorMsg();
    dataError.errorNumber = error.status;
    dataError.message = error.statusText;
    dataError.friendlyMsg = "An error occurred while retrieving data";
    return throwError(dataError);
  }

  getTmpServerDate(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/tempurl/getServerDate`);
  }

  getTmpPurchaseOrderFulfillmentDateById(id): Observable<any> {

    return this.http.get<any>(`${this.baseurl}/inventory/tempurl/getPurchaseOrderFulfillmentDateById?id=${id}`);
  }

  getTmpUploadedFileDetails(formTypeId, formPk): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get(this.baseurl + "/common/tempurl/document/getFilesApi?formTypeId=" + formTypeId + "&formPk=" + formPk, { headers: this.requestHeader }).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );
  }

  tmpUploadMultipleFiles(files: any[], fileDesc: any[]): Observable<any> {
    this.requestHeaderMultipartFormData = HeaderConfiguration.getHeaderConfigWithMultiPartConfiguration();
    var params = '';
    for (var i = 0; i < fileDesc.length; i++) {
      if (i == 0) {
        params += "fileDesc=" + fileDesc[i];
      } else {
        params += "&fileDesc=" + fileDesc[i];
      }

    }
    //console.log(files);
    let data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append('files', files[i]);
    }

    return this.http.post(this.baseurl + "/common/tempurl/document/uploadMultipleFilesApi" + "?" + params, data, { headers: this.requestHeaderMultipartFormData }).pipe(
      retry(1),
      catchError(this.handleHttpError)
    );
  }

  tmpCreatePurchaseOrderFulfillmentDate(fullfilmentData: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/inventory/tempurl/createPurchaseOrderFulfillmentDate`, fullfilmentData);
  }

  tmpSaveUploadedFileDtails(fileDetails: UploadedFileDetail[]): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.post(this.baseurl + "/common/tempurl/document/saveFilesApi", fileDetails, { headers: this.requestHeader })
      .pipe(
        retry(1),
        catchError(this.handleHttpError)
      );
  }

}
