import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { StatusService } from '../modules/service-status/status.service';
import { DashboardLayoutComponent } from '@syncfusion/ej2-angular-layouts';

@Component({
  selector: 'app-outer-service-status',
  templateUrl: './outer-service-status.component.html',
  styleUrls: ['./outer-service-status.component.css'], 
  encapsulation: ViewEncapsulation.None
})
export class OuterServiceStatusComponent implements OnInit {
  @ViewChild('default_dashboard')
  public dashboard: DashboardLayoutComponent;
  commonStatus: number = 0;
  masterStatus: number = 0;
  inventoryStatus: number = 0;
  manufacturingStatus: number = 0;
  reportingStatus: number = 0;
  zullStatus: number = 0;
  discoveryStatus: number = 0;
  public count: number = 8;
  public cellSpacing: number[] = [10, 10];

  constructor(private statusService: StatusService, private ngxLoader: NgxUiLoaderService) { }

  ngOnInit() {
    this.testService();
  }


  testService() {
    this.ngxLoader.start();

    this.statusService.common().subscribe(data => {
      if (data.statusCode == 200) {
        this.commonStatus = data.statusCode;
        console.log("1st time commonStatus < 2sec-", this.commonStatus);
      } else { this.commonStatus = 0 }
    }, (error) => {
      console.log(error);
    });

    this.statusService.inventory().subscribe(data => {
      if (data.statusCode == 200) {
        this.inventoryStatus = data.statusCode;
        console.log("1st time inventoryStatus < 2sec-", this.inventoryStatus);
      } else { this.inventoryStatus = 0 }
    }, (error) => {
      console.log(error);
    });

    this.statusService.master().subscribe(data => {
      if (data.statusCode == 200) {
        this.masterStatus = data.statusCode;
        console.log("1st time masterStatus < 2sec-");
      } else { this.masterStatus = 0 }
    }, (error) => {
      console.log(error);
    });

    this.statusService.manufacturing().subscribe(data => {
      if (data.statusCode == 200) {
        this.manufacturingStatus = data.statusCode;
      } else { this.manufacturingStatus = 0 }
    }, (error) => {
      console.log(error);
    });

    this.statusService.reporting().subscribe(data => {
      if (data.statusCode == 200) {
        this.reportingStatus = data.statusCode;
      } else { this.reportingStatus = 0 }
    }, (error) => {
      console.log(error);
    });

    this.statusService.discovery().subscribe(data => {
      if (data.statusCode == 200) {
        this.discoveryStatus = data.statusCode;
        console.log("1st time discovery new < 2sec-");
      } else { this.discoveryStatus = 0 }
    }, (error) => {
      console.log(error);
    });

    this.statusService.zuul().subscribe(data => {
      if (data.statusCode == 200) {
        this.zullStatus = data.statusCode;
        console.log("1st time reporting new < 2sec-");
      } else { this.reportingStatus = 0 }
    }, (error) => {
      console.log(error);
    });
    this.statusService.discovery().subscribe(data => {
      if (data.statusCode == 200) {
        this.discoveryStatus = data.statusCode;
        console.log("1st time reporting new < 2sec-");
      } else { this.reportingStatus = 0 }
    }, (error) => {
      console.log(error);
    });

    var ref = this;
    setTimeout(function () {
      ref.changeColor();
    }, 1000);
    setTimeout(function () {
      ref.changeColor();
    }, 2000);
    this.ngxLoader.stop();
  }

  changeColor() {
    var x;
    x = document.querySelectorAll(".card-padding");
    console.log("elemnt-", document.querySelector(".card-padding")[1], "-++++-", x[1]);

    if (this.commonStatus == 200) {
      x[0].style.backgroundColor = "#9fcc98";
    } else {
      x[0].style.backgroundColor = "#ff8080";
    }

    if (this.inventoryStatus == 200) {
      console.log("elemnt-", x[1]);
      x[1].style.backgroundColor = "#9fcc98";
    } else {
      x[1].style.backgroundColor = "#ff8080";
    }

    if (this.manufacturingStatus == 200) {
      x[2].style.backgroundColor = "#9fcc98";
    } else {
      x[2].style.backgroundColor = "#ff8080";
    }

    if (this.masterStatus == 200) {
      x[3].style.backgroundColor = "#9fcc98";
    } else {
      x[3].style.backgroundColor = "#ff8080";
    }

    if (this.reportingStatus == 200) {
      x[4].style.backgroundColor = "#9fcc98";
    } else {
      x[4].style.backgroundColor = "#ff8080";
    }

    if (this.zullStatus == 200) {
      x[5].style.backgroundColor = "#9fcc98";
    } else {
      x[5].style.backgroundColor = "#ff8080";
    }


    if (this.discoveryStatus == 200) {
      x[6].style.backgroundColor = "#9fcc98";
    } else {
      x[6].style.backgroundColor = "#ff8080";
    }
  }

  // else {
  // console.log(" not in 2 sec-");
  newfsfds() {
    var x, i;
    x = document.querySelectorAll(".card-padding");
    x[0].style.backgroundColor = "#9fcc98";
    // }

    if (this.inventoryStatus == 200) {
      console.log("elemnt-", document.querySelector(".card-padding")[1]);
      document.querySelector(".card-padding")[1].style.backgroundColor = "#9fcc98";
    }

    if (this.manufacturingStatus == 200) {
      document.querySelector(".card-padding")[2].style.backgroundColor = "#9fcc98";
    }

    if (this.masterStatus == 200) {
      document.querySelector(".card-padding")[3].style.backgroundColor = "#9fcc98";
    }

    if (this.reportingStatus == 200) {
      document.querySelector(".card-padding")[4].style.backgroundColor = "#9fcc98";
    }

    if (this.zullStatus == 200) {
      document.querySelector(".card-padding")[5].style.backgroundColor = "#9fcc98";
    }


    if (this.discoveryStatus == 200) {
      document.querySelector(".card-padding")[6].style.backgroundColor = "#9fcc98";
    }

    console.log(" in settimeout-");
    // if (data.statusCode == 200) {
    // for (let i = 0; i < x.length; i++) {
    x[2].style.backgroundColor = "#ffc107";
    // }
    console.log(" > 2sec-");
    // }else {
    for (let i = 0; i < x.length; i++) {
      x[i].style.backgroundColor = "#ef7f79";
    }
    console.log("Failed-");
    // }
  };

  // }

}
