import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FinacilayearType } from "../models/financiaYearType";
import { Finacilayear } from "../models/financialYear";
import { ErrorMsg } from '../../group/model/errormsg';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common'
import { HeaderConfiguration } from 'src/app/headerConfiguration';
@Injectable({
  providedIn: 'root'
})
export class FinancialYearService {
  baseurl = environment.apiUrl;
  datePipe = new DatePipe('en-US');
  //requestHeader = HeaderConfiguration.headerConfg;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();
  constructor(private http: HttpClient) {
    console.log("service constructor called");
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
  }

  getAllFinancialYears(): Observable<Finacilayear[] | ErrorMsg> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<Finacilayear[]>(`${this.baseurl}/common/financialYearList`)
      .pipe(
        catchError(err => this.handleHttpError(err))
      );
  }

  getFinancialYearById(finacilayearId: number): Observable<Finacilayear> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    if (finacilayearId === 0) {
      return of(this.initializeFinancialYear());
    }
    return this.http.get<Finacilayear>(`${this.baseurl}/common/getFinancialYear/${finacilayearId}`, { headers: this.requestHeader });
  }
  // form have not display stataus id so pass status id=1 inthese we pass status id=1 suggestion by rajandra
  addFinancialYear(financialYear: Finacilayear): Observable<Finacilayear> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();

    return this.http.post<Finacilayear>(`${this.baseurl}/common/addfinancialYear/`, financialYear, { headers: this.requestHeader });
  }



  private initializeFinancialYear(): Finacilayear {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return {
      id: 0,
      financialYear: "",
      startDate: "",
      endDate: "",
    };
  }

  private handleHttpError(error: HttpErrorResponse): Observable<ErrorMsg> {
    let dataError = new ErrorMsg();
    dataError.errorNumber = error.status;
    dataError.message = error.statusText;
    dataError.friendlyMsg = "An error occurred while retrieving data";
    return throwError(dataError);
  }
  getFinacilayearType(): Observable<FinacilayearType> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    let finacilayearType: string = 'FINANCIAL_YEAR';

    return this.http.get<FinacilayearType>(`${this.baseurl}/common/getMasterDataByType/${finacilayearType}`, { headers: this.requestHeader });
  }

  getListOfMonths(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/listOfMonths`);
  }

  createFyAtLoginTime(financialYear: any): Observable<any> {
    return this.http.post<Finacilayear>(`${this.baseurl}/common/financialYearMonthCreation`, financialYear);
  }

  getNextFinancialYear(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getFinancialYearNext`);
  }

}
