import { Component, OnInit, Renderer2, Inject, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { rippleMouseHandler } from '@syncfusion/ej2-buttons';
import {DOCUMENT} from '@angular/common';
import { CommonVariables } from '../commonVariables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessMsgModalComponent } from '../modules/shared/success-msg-modal/success-msg-modal.component';
import { ZohoConfirmComponent } from '../modules/shared/zoho-confirm/zoho-confirm.component';
import { ClientCompanyService } from '../modules/enter-client-company/client-company.service';
import { ZohoConfirmDialogService } from '../zoho-confirm-dailog/zoho-confirm-dialog.service';
import { CompanyService } from '../company/services/company.service';
import { Country } from '../company/models/country';
import { State } from '../company/models/state';
import { PaidPlanSubscribeService } from '../modules/subscription/paid-plan-subscribe.service';
import { LogoutDialogService } from '../existing-user-logout/logout-dialog.service';
import { AppComponent } from '../app.component';
import { CustomValidator } from '../custom-validator';

@Component({
  selector: 'app-user-signup2',
  templateUrl: './user-signup2.component.html',
  styleUrls: ['./user-signup2.component.css']
})
export class UserSignup2Component implements OnInit {

  userSignupForm: FormGroup;
  userPlanForm: FormGroup;
  otp: any;
  loading = false;
  public industryFields: Object = { text: "industryName", value: "pk" };
  public placeholderIndustry: string = "Search a Industry";

  public planFlag1: boolean = false;
  public planFlag2: boolean = false;
  public planFlag3: boolean = false;

  public submitted: boolean = false;
  userSignupData: any = {};
  allPlans: any[] = [];
  billingPlan: string = '';
  isChecked: boolean = false;

  public group_options = [
    { frequency: 'Trial', plan_type: 'Trial', frequency_recurrence_value: '1_months', plans: [], plan_active: false },
    { frequency: 'Monthly', plan_type: 'Monthly', frequency_recurrence_value: '1_months', plans: [], plan_active: false },
    { frequency: 'Quarterly', plan_type: 'Quaterly', frequency_recurrence_value: '3_months', plans: [], plan_active: false },
    { frequency: 'Bi-Yearly', plan_type: 'Half Yearly', frequency_recurrence_value: '6_months', plans: [], plan_active: false },
    { frequency: 'Yearly', plan_type: 'Yearly', frequency_recurrence_value: '12_months', plans: [], plan_active: false },
  ];

  public warehouseIcon: string = "../assets/images/icons/icons8-warehouse.png";
  public factoryIcon: string = "../assets/images/icons/icons8-factory.png";
  countries: Country[];
  states: State[];
  priceBooks: any[] = [
    {
      "pricebook_id": "2380097000000181166",
      "name": "INR PB",
      "description": "Indian Rupee Price Book",
      "currency_id": "2380097000000000099",
      "currency_code": "INR",
      "pricebook_items": [],
      "status": "active",
      "is_default": false,
      "pricebook_type": "per_item",
      "percentage": "",
      "is_increase": false,
      "rounding_type": "no_rounding",
      "sales_or_purchase_type": "sales",
      "decimal_place": ""
    },
    {
      "pricebook_id": "2380097000000181170",
      "name": "USD PB",
      "description": "United States Dollar Price Book",
      "currency_id": "2380097000000000097",
      "currency_code": "USD",
      "pricebook_items": [],
      "status": "active",
      "is_default": false,
      "pricebook_type": "per_item",
      "percentage": "",
      "is_increase": false,
      "rounding_type": "no_rounding",
      "sales_or_purchase_type": "sales",
      "decimal_place": ""
    }
  ];

  countryIndiaFlag: boolean = false;
  public loggedInUser: any = {};
  selectedCountry: string = '';
  currencyFormat: string = '';

  public tmpCountryCode: any = '';
  public tmpWorkEmail: any = '';
  public tmpContactMobile: any = '';

  isEmailVarify: boolean = true;
  isMobileNoVarify: boolean = true;
  emailPhoneNoVerifyMsg: string = "you have changes mobileNo. or Email please generate OTP";
  public zohoStateCode: string = '';
  public isFreeTrial: boolean = false;
  public selectedPlan: any = {};
  public sumOfAddOnsAmount: number = 0;
  public sumOfPlanAmount: number = 0;
  public sumOftaxesAmount: number = 0;
  public subTotal: number = 0;
  public grandTotal: number = 0;


  constructor(public appComp: AppComponent,
    private formBuilder: FormBuilder, private paidPlanService: PaidPlanSubscribeService, private companyService: CompanyService, private clientCompanyService: ClientCompanyService, private zohoConfirmDialogService: ZohoConfirmDialogService,
    private logoutDialogService: LogoutDialogService, private router: Router, private ngxLoader: NgxUiLoaderService, private modalService: NgbModal,
    private globalService: GlobalServiceService, private renderer: Renderer2, private el: ElementRef,
    @Inject(DOCUMENT) private _document) { }

  ngOnInit() {
    this.loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log("current User ", this.loggedInUser);

    if (this.loggedInUser != undefined && this.loggedInUser != '' && this.loggedInUser != null) {
      this.router.navigateByUrl(`/`);
    }

    this.ngxLoader.start();
    let user3: any = JSON.parse(localStorage.getItem("currentUser"));

    console.log("currently Logged in user: ", user3);
    this.userSignupForm = this.formBuilder.group({
      companyName: ['', [Validators.required,]],
      domainName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20), CustomValidator.domainNameValidator,]],
      industryPk: ['', [Validators.required,]],
      contactPerson: ['', [Validators.required,]],
      contactMobile: ['', [Validators.required, CustomValidator.mobileNumberValidator,]],
      companyEmail: ['', [Validators.required, CustomValidator.emailValidator,]],
      countryId: ['', [Validators.required,]],
      stateId: [''],
      otp: ['', [Validators.required,]],
      countryCode: ['', [Validators.required,]],
      isTurmsandCondistions: ['', [Validators.required,]]
    });

    this.userPlanForm = this.formBuilder.group({
      orderProcessing: ['']

    });

    this.userSignupForm.get('companyEmail').valueChanges.subscribe(
      value => {
        this.isEmailVarify = true;
        console.log("valid field ", this.userSignupForm.controls['companyEmail'].errors);
        if (this.userSignupForm.controls['companyEmail'].errors == null) {
          if (this.tmpWorkEmail != '' && this.tmpWorkEmail == value) {
            this.isEmailVarify = true;
          } else if (this.tmpWorkEmail != '') {
            this.isEmailVarify = false;
          }
        }

      }
    );

    this.userSignupForm.get('contactMobile').valueChanges.subscribe(
      value => {
        this.isMobileNoVarify = true;
        if (this.userSignupForm.controls['contactMobile'].errors == null) {
          if (this.tmpContactMobile != '' && this.tmpContactMobile == value) {
            this.isMobileNoVarify = true;
          } else if (this.tmpContactMobile != '') {
            this.isMobileNoVarify = false;
          }
        }
      }
    );

    this.userSignupForm.get('countryCode').valueChanges.subscribe(
      value => {
        this.isMobileNoVarify = true;
        if (this.userSignupForm.controls['countryCode'].errors == null) {
          if (this.tmpCountryCode != '' && this.tmpCountryCode == value) {
            this.isMobileNoVarify = true;
          } else if (this.tmpCountryCode != '') {
            this.isMobileNoVarify = false;
          }
        }
      }
    );

    this.getindustryTypeList();
    this.globalService.getAllPlan().subscribe((data) => {

      if (data != null && data.data != null) {
        data.data.plans.forEach(element => {

          element["features"] = [];
          if (element.store_markup_description != null && element.store_markup_description != '') {
            let features = element.store_markup_description.split("|");
            element["features"] = features;
          }


          element.addons.forEach(addOn => {
            addOn["isSelected"] = false;
          });
        });


        this.allPlans = data.data.plans;
        if (this.allPlans != null && this.allPlans.length > 0) {
          for (let i in this.group_options) {
            for (let j in this.allPlans) {
              if (this.allPlans[j].custom_fields.length > 0 && this.group_options[i].plan_type == this.allPlans[j].custom_fields[0].value && this.allPlans[j].custom_fields[0].value != "Trial") {
                let obj = { "plan_code": this.allPlans[j].plan_code };
                this.group_options[i].plans.push(obj);

              }
            }
          }
        }



      }
      //this.loadNextScript();
      //this.globalService.getAllPlanRedirectURI();
      console.log("plans data ", this.allPlans);
    }, error => {
      console.log("error -> ", error);
    });

    /* const s = this.renderer.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://js.zohostatic.com/books/zfwidgets/assets/js/zf-widget.js';
    s.text = ``;

    this.renderer.appendChild(this._document.body, s); */
    // var shell = new ActiveXObject("WScript.Shell");
    //shell.run("cmd /c dir & pause");
    //var run = new ActiveXObject('WSCRIPT.Shell').Run("commands to run");
    this.planFlag1 = true;
    this.planFlag2 = false;
    this.planFlag3 = false;
    this.getCountry();
    this.ngxLoader.stop();
  }

  /* loadNextScript() {
    var ref = this;
    const s = this.renderer.createElement('script');
    s.text = `
   // This would error, if previous script has not yet been loaded
    loadWidget()
`
    this.renderer.appendChild(this._document.body, s);
  }
 */


  get f() { return this.userSignupForm.controls; }

  onSubmit() {
    console.log("signup Data ", this.userSignupForm.value);
    this.userSignupData = this.userSignupForm.value;
    this.userSignupData.zohoCreationFlag = 0;
    this.userSignupData.pk = 0;
    this.userSignupData.contactMobile = this.userSignupData.countryCode + this.userSignupData.contactMobile;
    this.planFlag1 = false;
    this.planFlag2 = true;
    this.planFlag3 = false;
    this.planEndDateFlag = false;
    this.checkPlanAvailability();
    this.displayProperPlan2('Monthly');
    //this.addCompanyMaster(this.userSignupData);

  }
  industryTypeList: any[] = [];
  getindustryTypeList() {
    this.globalService.getIndustryTypeList().subscribe(
      (data: any) => {
        this.industryTypeList = data;
        console.log("industryTypes ", data);
      }
    )
  }

  setAttribute() {
    $("#ddlelement_popup .e-input").attr("placeholder", "Type here to search");
  }
  resetCompanyForm(): void {
    this.userSignupForm.reset();

  }

  sendOtp(req): void {
    let mobileNo: number = null;
    let email: string = ''

    mobileNo = this.userSignupForm.value.contactMobile;

    email = this.userSignupForm.value.companyEmail;
    this.ngxLoader.start();
    this.globalService.otpVerification(email, mobileNo).subscribe(
      (data: any) => {
        this.ngxLoader.stop();
        this.isOTP = true;
        this.mobileOtp = data.data.otpSms;
        this.emailOtp = data.data.otpMail;

      },
      error => {
        this.ngxLoader.stop();
        this.loading = false;
      }
    )
  }





  alreadyAccountLogin() {
    this.router.navigateByUrl(`/login`);
  }

  back() {
    this.planFlag1 = true;
    this.planFlag2 = false;
    this.planFlag3 = false;
  }

  back2() {
    this.planFlag1 = false;
    this.planFlag2 = true;
    this.planFlag3 = false;
  }

  validationUpdate() {
    console.log("form validation", this.userSignupForm);
  }

  selectedPlans: any[] = [];
  activeFlag: string = '';
  displayProperPlan(type) {
    this.selectedPlans = [];
    this.billingPlan = '';
    this.activeFlag = type;
    if (type == 'Trial') {

      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "";

    } else if (type == 'Monthly') {

      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "Billed Monthly";

    } else if (type == 'Quaterly') {

      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "Billed Quarterly";
    } else if (type == 'Half Yearly') {

      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "Billed Bi-Yearly";
    }
    else if (type == "Yearly") {
      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "Billed every 12 Months";
    }
  }

  displayProperPlan2(type) {
    this.selectedPlans = [];
    this.billingPlan = '';
    this.activeFlag = type;
    if (type == 'Trial') {

      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "";

    } else if (type == 'Monthly') {

      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "Billed Monthly";
      if (this.selectedPlans.length == 0) {
        this.displayProperPlan2('Quaterly');
      }
    } else if (type == 'Quaterly') {

      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "Billed Quarterly";
      if (this.selectedPlans.length == 0) {
        this.displayProperPlan2('Half Yearly');
      }

    } else if (type == 'Half Yearly') {

      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "Billed Bi-Yearly";
      if (this.selectedPlans.length == 0) {
        this.displayProperPlan2('Yearly');
      }
    }
    else if (type == "Yearly") {
      for (let i in this.allPlans) {
        if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == type) {
          if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
            let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
            this.selectedPlans.push(copyObj);
            this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
            copyObj = {};
          }
        }
      }
      this.billingPlan = "Billed every 12 Months";
    }
  }

  subscriptionData: any = {};
  subsResponseData: any = {};

  addSubscription(subscriptionData, plan) {
    this.ngxLoader.start();
    this.globalService.addSubscription(subscriptionData).subscribe((data) => {
      this.ngxLoader.stop();
      console.log("Response data of add Subscription", data);
      this.subsResponseData = data.data;

      if (this.subsResponseData.subscription != null) {
        this.onSubmitFinalCoSave(plan)
      }


    }, error => {
      console.log("error -> ", error);
      this.ngxLoader.stop();
    });

  }


  public plantEndDate: string = '';
  planEndDateFlag: boolean = false;
  public planName: string = '';
  public addOnQtyFieldFlag: boolean = false;
  public addons: any[] = [];

  selectPlan(plan) {
    console.log("selected Plan ", plan);
    this.addOnQtyFieldFlag = false;
    console.log("selected Plan ", plan);

    console.log("company details ", this.companyDetail);
    this.planFlag1 = false;
    this.planFlag2 = false;
    this.planFlag3 = true
    plan["quantity"] = 0;
    plan["amount"] = 0;
    plan["price"] = 0;

    if (plan.custom_fields != undefined && plan.custom_fields != null) {
      this.addOnQtyFieldFlag = false;
      for (var i = 0; i < plan.custom_fields.length; i++) {
        if (plan.custom_fields[i].label == "Enable Qty in addon" && plan.custom_fields[i].value == "yes") {
          this.addOnQtyFieldFlag = true;
        }
      }

    }

    plan.addons.forEach(element => {
      if (this.addOnQtyFieldFlag && element.type == 'one_time') {
        element["amount"] = 0;
        element.amount = element.price;
      } else {
        element["amount"] = 0;
        element.amount = element.price * plan.interval;
      }

    });

    this.selectedPlan = plan;
    this.addons = plan.addons.map(x => Object.assign({}, x));
    if (this.selectedPlan.item_tax_preferences != null && this.selectedPlan.item_tax_preferences.length > 0) {
      this.selectedPlan.item_tax_preferences.forEach(element => {
        element["isActive"] = false;
        if (this.selectedCountry == "India") {
          if (this.selectedState == "Uttar Pradesh" && element.tax_specification == "intra") {
            element["isActive"] = true;
          } else if (this.selectedState != "Uttar Pradesh" && element.tax_specification == "inter") {
            element["isActive"] = true;
          }
        }
      });
    }

  }


  addupdateResponseData: any = {};
  public pk: number = 0;

  addCompanyMaster(signupData) {
    this.ngxLoader.start();
    this.globalService.addOrUpdateCompanyMaster(signupData).subscribe((data) => {
      console.log("response Data add update ", data);
      console.log("Form filled data", signupData);
      this.ngxLoader.stop();
      this.addupdateResponseData = data;
      this.pk = data.data;

      this.planEndDateFlag = false;

      this.getCompanyDetalsByDomain(signupData.domainName);

      //this.getCompanySubscribeDetails(signupData.domainName);
      //this.modalService.open(SuccessMsgModalComponent, { size: 'sm' });
    }, (error) => {
      this.ngxLoader.stop();
      console.log("error --> ", error);
    });
  }

  updateCompanyMaster(signupData) {
    this.ngxLoader.start();
    signupData.subscriptionStatus = "trial";
    this.globalService.addOrUpdateCompanyMaster(signupData).subscribe((data) => {
      console.log("response Data add update ", data);
      this.ngxLoader.stop();
      this.addupdateResponseData = data;
      this.pk = data.data;

      //this.modalService.open(SuccessMsgModalComponent, { size: 'sm' });
      this.loginUser(data.data);
    }, (error) => {
      this.ngxLoader.stop();
      console.log("error --> ", error);
    });
  }

  modulePk: number = 0;
  onSubmitFinalCoSave(plan) {

    //this.userSignupData.pk = this.pk;
    this.userSignupData.zohoCreationFlag = 1;
    this.userSignupData.subscriptionId = this.subsResponseData.subscription.subscription_id;
    this.userSignupData.subscriptionStatus = this.subsResponseData.subscription_status;
    this.userSignupData.customerId = this.subsResponseData.subscription.customer_id;
    let endDate = new Date(this.subsResponseData.subscription.current_term_ends_at);
    this.plantEndDate = CommonVariables.currentDateDMYHMS(endDate);
    this.userSignupData.trialEndDate = this.plantEndDate;
    this.userSignupData.planStartDate = CommonVariables.reverseStrDate(this.subsResponseData.subscription.current_term_starts_at) + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds();


    this.userSignupData.plan = this.planName;
    this.userSignupData.trialDays = this.subsResponseData.subscription.trial_remaining_days;
    this.userSignupData.zohoUserCount = plan.quantity;
    //this.userSignupData.planExpiryDate = this.plantEndDate;
    this.userSignupData.planExpiryDate = this.plantEndDate;

    this.userSignupData.planActivationDate = CommonVariables.reverseStrDate(this.subsResponseData.subscription.activated_at) + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds();
    this.userSignupData.planCreateDate = CommonVariables.reverseStrDate(this.subsResponseData.subscription.created_at) + " " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds();

    this.userSignupData["moduleList"] = [];
    let moduleList = [];

    for (var i = 0; i < plan.custom_fields.length; i++) {
      if (plan.custom_fields[i].label == "module main" || plan.custom_fields[i].label == "module main no" || plan.custom_fields[i].placeholder == "cf_module_main") {
        if (plan.custom_fields[i].value == 9) {
          // moduleList.push({ "pk": plan.custom_fields[i].value });
          //moduleList.push({ "pk": 3 });
          //moduleList.push({ "pk": 4 });
          this.modulePk = plan.custom_fields[i].value;
        } else {
          //moduleList.push({ "pk": plan.custom_fields[i].value });
          this.modulePk = plan.custom_fields[i].value;
        }
      }
    }

    this.globalService.getModulesByModuleGroup(this.modulePk).subscribe((data) => {
      moduleList = data.data;
      for (var i = 0; i < plan.addons.length; i++) {
        if ((plan.addons[i].name == "Order processing" || plan.addons[i].name == "Central Order processing") && (plan.addons[i].isSelected == true)) {
          moduleList.push({ "pk": 5 });
        } else if (plan.addons[i].name == "Inventory" && plan.addons[i].isSelected == true) {
          moduleList.push({ "pk": 3 });
        } else if (plan.addons[i].name == "Manufacturing" && plan.addons[i].isSelected == true) {
          moduleList.push({ "pk": 4 });
        } else if (plan.addons[i].name == "Manufacturing Free" && plan.addons[i].isSelected == true) {
          moduleList.push({ "pk": 7 });
        } else if ((plan.addons[i].name == "Integration Tally/Zoho" || plan.addons[i].name == "Integration Tally" || plan.addons[i].name == "Integration") && (plan.addons[i].isSelected == true)) {
          moduleList.push({ "pk": 101 });
        }
        else if (plan.addons[i].name !== "Integration" && plan.addons[i].name !== "Integration Tally" && plan.addons[i].name !== "Integration Tally/Zoho" && plan.addons[i].name !== "Central Order processing" && plan.addons[i].name !== "Order processing" && plan.addons[i].name !== "Inventory" && plan.addons[i].name !== "Manufacturing" && plan.addons[i].name !== "Manufacturing Free" && plan.addons[i].isSelected == true) {
          moduleList.push({ "pk": 0 });
        }
      }
      this.userSignupData["moduleList"] = moduleList;
      this.updateCompanyMaster(this.userSignupData);

    });


  }

  istrmAndCondition(value) {
    console.log("is checked ", value);
    this.isChecked = value;
    if (!this.isChecked) {
      this.f['isTurmsandCondistions'].setValidators(Validators.required);
    } else {
      this.f['isTurmsandCondistions'].clearValidators();
    }
    this.f['isTurmsandCondistions'].updateValueAndValidity();
  }

  public isOTP = false;
  public mobileOtp: any = '';
  public emailOtp: any = '';
  onNext() {
    this.ngxLoader.start();
    this.globalService.otpVerification(this.userSignupForm.value.companyEmail, this.userSignupForm.value.contactMobile).subscribe(
      (data: any) => {
        this.ngxLoader.stop();
        this.isOTP = true;
        this.mobileOtp = data.data.otpSms;
        this.emailOtp = data.data.otpMail;

      },
      error => {
        this.ngxLoader.stop();
        this.loading = false;
      }
    )
  }

  modules: any = {};
  isVerified: boolean = false

  onVerify() {

    if (this.mobileOtp.otpSms == this.userSignupForm.value.otp) {
      this.isVerified = true;
    }
    else {
      this.isVerified = false;
    }
  }

  loginUser(user: any) {

    this.zohoConfirmDialogService.confirm('Company Has been created Successfully.', 'Proceed To Enter')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxLoader.start();
          user["loggedInFlag"] = 0,
            this.globalService.loginV2(user).subscribe((data) => {
              console.log("login checking ", data);
              this.ngxLoader.stop();
              let loggedInUser = data.data;
              if (data.statusCode == 200) {
                let user1: any = JSON.parse(localStorage.getItem("currentUser"));
                console.log("currently Logged in user: ", user);
                if (user1 != undefined && user1 != null) {
                  this.logoutExistingUser(data.data);
                } else {
                  this.globalService.logoutSession();
                  this.appComp.loggedInUser = data.data;
                  this.globalService.setCurrentUser(data.data);
                  this.globalService.setAccessToken(loggedInUser.tokenBean);
                  this.router.navigate([`/financialYear/${data.data.pk}`]);

                }
              }

            }, error => {
              console.log("error=>", error);
              this.ngxLoader.stop();
            });


        }
      })
      .catch(() => console.log('User dismissed the dialog'));
  }

  duplicateDomainFlag: boolean = true;
  availableDomainList: any[] = [];
  checkDuplicateCompanyCodeBulk() {
    this.duplicateDomainFlag = true;

    this.globalService.checkDuplicateCompanyCodeBulk(this.userSignupForm.value.domainName).subscribe((data) => {
      this.availableDomainList = data.data;
      if (this.availableDomainList != null && this.availableDomainList.length > 0) {
        this.duplicateDomainFlag = true;
      } else {
        this.duplicateDomainFlag = false;
      }
      console.log("data of check domain name ", data);

    }, (error) => {
      console.log("error -->", error);
    });
  }

  public domainNameMessage: string = '';
  checkDuplicateCompanyCode() {
    this.duplicateDomainFlag = true;
    this.globalService.checkDuplicateDomainName(this.userSignupForm.value.domainName).subscribe((data) => {
      this.domainNameMessage = data.msg;
      if (this.domainNameMessage != null && this.domainNameMessage != '' && this.domainNameMessage == "Domain name already exists.") {
        this.duplicateDomainFlag = false;
        this.userSignupForm.get("domainName").setErrors({ 'invalid': true });
        this.userSignupForm.setErrors({ 'invalid': true });
      } else {
        this.duplicateDomainFlag = true;
      }
      console.log("data of check domain name ", data);

    }, (error) => {
      console.log("error -->", error);
    });
  }

  public otpGenerateFlag: boolean = false;
  getMobileOtp() {

    console.log("form value ", this.userSignupForm.value);
    if (this.userSignupForm.value.companyEmail != '' && this.userSignupForm.value.contactMobile != '') {
      this.ngxLoader.start();
      this.otpGenerateFlag = false;
      this.globalService.getMobileEmailOtp(this.userSignupForm.value.countryCode + this.userSignupForm.value.contactMobile, this.userSignupForm.value.companyEmail).subscribe((data) => {
        console.log("mobileOtp: ", data.data);
        this.ngxLoader.stop();
        this.otpGenerateFlag = true;
        this.isOTP = true;
        this.mobileOtp = data.data;
        this.isMobileNoVarify = true;
        this.isEmailVarify = true;
        this.tmpCountryCode = this.userSignupForm.value.countryCode;
        this.tmpWorkEmail = this.userSignupForm.value.companyEmail;
        this.tmpContactMobile = this.userSignupForm.value.contactMobile

        if (this.userSignupForm.value.otp != '' && this.userSignupForm.value.otp != null && this.mobileOtp.otpSms == this.userSignupForm.value.otp) {
          this.isVerified = true;
        }
        else if (this.userSignupForm.value.otp != '' && this.userSignupForm.value.otp != null && this.mobileOtp.otpSms != this.userSignupForm.value.otp) {
          this.isVerified = false;
        }


      }, (error) => {
        this.ngxLoader.stop();
        this.loading = false;
      });
    }


  }

  isManufacturing(plan) {
    let flag: boolean = false;
    let name = plan.name.toLowerCase();
    if (name.indexOf("manufacturing") == -1) {
      flag = true;
    }
    return flag;
  }

  public countryFields: Object = { text: 'countryName', value: 'countryId' };
  public placeholder1: string = "Search a Country";
  public stateFields: Object = { text: 'stateName', value: 'stateId' };
  public placeholder2: string = "Search a State";

  public countryCodeFields: Object = { text: 'countryCode', value: 'countryCode' };
  public placeholderCountryCode: string = "";

  getState(countryId: number) {
    this.states = [];

    this.companyService.getStateByCountryId(countryId).subscribe(
      (data: any) => {

        this.states = data.data;
        console.log("states", this.states);

      }, error => {

      }
    );
  }

  getCountry() {
    this.companyService.getCountry().subscribe(
      (data: any) => {

        data.data.forEach(element => {
          element.countryCode = "+" + element.countryCode;
        });
        this.countries = data.data;
        console.log("countries", this.countries);



      }
    )
  }

  public countryCode: string = '';
  onCountryChanged(event: any) {
    console.log("country changed ", event);
    let countryId = event.value;
    this.selectedCountry = event.itemData.countryName;
    if (event.itemData.countryName == "India") {
      this.countryIndiaFlag = true;
      this.countryCode = event.itemData.countryCode;
      this.userSignupForm.patchValue({
        "countryCode": this.countryCode
      });

    } else {
      this.countryIndiaFlag = false;
      this.countryCode = event.itemData.countryCode;
      this.userSignupForm.patchValue({
        "countryCode": this.countryCode
      });
    }


    console.log("form value ", this.userSignupForm);
    this.getState(countryId);


    this.countID = countryId;
  }

  onCountryCodeChanged(event: any) {
    console.log("country changed ", event);
    let countryId = event.value;

    if (event.itemData.countryName == "India") {
      this.countryIndiaFlag = true;
      this.countryCode = "+" + event.itemData.countryCode;
      this.userSignupForm.patchValue({
        "countryCode": this.countryCode
      });

    } else {
      this.countryIndiaFlag = false;
      this.countryCode = "+" + event.itemData.countryCode;
      this.userSignupForm.patchValue({
        "countryCode": this.countryCode
      });
    }


  }


  stateId = "";
  stateFlage: boolean = true;
  countID = "";
  public selectedState: string = '';
  onCountryStateChanged(event: any) {
    let stateId = event.value;
    this.stateId = stateId;

    this.selectedState = event.itemData.stateName;
    var ref = this;
    console.log(event);
    setTimeout(function () {
      ref.getzohoStateCode();
    }, 200);
  }

  public companyDetail: any = {};
  getCompanyDetalsByDomain(domainName: string) {
    this.paidPlanService.getCompanyByDomainName(domainName).subscribe((data) => {
      this.companyDetail = data.data;
      console.log("company Details: ", this.companyDetail);
      this.selectedCountry = this.companyDetail.countryName;
      //this.displayProperPlan('Monthly');
      //this.displayProperPlan('Trial');

    }, error => {
      console.log("error --> ", error);
    });
  }

  subscribedPlanDetails: any = {};
  getCompanySubscribeDetails(domainName: string) {
    this.paidPlanService.getCompanySubsciptionDetailsByDomainName(domainName).subscribe((data) => {
      console.log("subscribed Details ", data);

      this.subscribedPlanDetails = data.data;

    }, error => {
      console.log("Error -->", error);
    });
  }

  logoutExistingUser(userData) {
    let user: any = JSON.parse(localStorage.getItem("currentUser"));
    console.log("currently Logged in user: ", user);
    if (user != undefined && user != null) {
      this.logoutDialogService.confirm('Are you sure you want to logout of ' + user.companyName + ' company?', 'Yes to logout')
        .then((confirmed) => {
          if (confirmed) {
            this.globalService.logout().subscribe(
              (data: any) => {
                if (data.statusCode == 200) {
                  this.globalService.logoutSession();
                  this.appComp.loggedInUser = userData;
                  this.globalService.setCurrentUser(userData);
                  this.router.navigate([`/financialYear/${userData.pk}`]);

                }
              },
              (err: any) => {
                console.log(err);
              }
            );
          }
        }).catch(() => console.log('User dismissed the dialog'));

    }


  }

  textTransformInLowercase() {
    this.userSignupForm.patchValue({
      "domainName": this.userSignupForm.value.domainName.toLowerCase()
    });

  }
  checkDuplicateEmail: any = {};
  checkDuplicateEmailId() {
    var ref = this;
    setTimeout(function () {
      if (!ref.userSignupForm.get("companyEmail").invalid) {
        ref.globalService.checkDuplicateEmailId(ref.userSignupForm.value.companyEmail).subscribe((data) => {
          ref.checkDuplicateEmail = data;
          if (ref.checkDuplicateEmail.statusCode == 201) {

            ref.userSignupForm.get("companyEmail").setErrors({ 'duplicateEmail': true });
            ref.userSignupForm.setErrors({ 'invalid': true });
          }
        }, error => {
          console.log("Error --> ", error);
        });
      }
    }, 200);

  }

  getzohoStateCode() {
    let countryState = {
      "countryName": this.selectedCountry,
      "stateName": this.selectedState,
      "zohoStateCode": null
    };

    if (this.selectedCountry != '' && this.selectedState != '') {
      this.globalService.getZohoStateCode(countryState).subscribe((data) => {
        console.log("zoho state code ", data);
        this.zohoStateCode = data.data.zohoStateCode;
      });
    }
  }

  checkPlanAvailability() {
    for (let i in this.allPlans) {
      if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == "Monthly") {
        if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
          this.group_options[1].plan_active = true;
          let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
          this.selectedPlans.push(copyObj);
          this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
          copyObj = {};
        } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
          this.group_options[1].plan_active = true;
          let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
          this.selectedPlans.push(copyObj);
          this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
          copyObj = {};
        }
      } else if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == "Quaterly") {
        if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
          this.group_options[2].plan_active = true;
          let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
          this.selectedPlans.push(copyObj);
          this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
          copyObj = {};
        } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
          this.group_options[2].plan_active = true;
          let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
          this.selectedPlans.push(copyObj);
          this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
          copyObj = {};
        }
      } else if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == "Half Yearly") {
        if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
          this.group_options[3].plan_active = true;
          let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
          this.selectedPlans.push(copyObj);
          this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
          copyObj = {};
        } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
          this.group_options[3].plan_active = true;
          let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
          this.selectedPlans.push(copyObj);
          this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
          copyObj = {};
        }
      } else if (this.allPlans[i].status == "active" && this.allPlans[i].custom_fields.length > 0 && this.allPlans[i].custom_fields[0].value == "Yearly") {
        if (this.selectedCountry == "India" && this.allPlans[i].custom_fields[2].value_formatted == "INR") {
          this.group_options[4].plan_active = true;
          let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
          this.selectedPlans.push(copyObj);
          this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
          copyObj = {};
        } else if (this.selectedCountry != "India" && this.allPlans[i].custom_fields[2].value_formatted == "USD") {
          this.group_options[4].plan_active = true;
          let copyObj = JSON.parse(JSON.stringify(this.allPlans[i]));
          this.selectedPlans.push(copyObj);
          this.currencyFormat = this.allPlans[i].custom_fields[2].value_formatted;
          copyObj = {};
        }
      }
    }
  }

  calculateAmount(qty) {
    this.selectedPlan.amount = qty * this.selectedPlan.recurring_price * this.selectedPlan.interval;
    this.selectedPlan.price = this.selectedPlan.interval * this.selectedPlan.recurring_price;
    this.sumOfPlanAmount = this.selectedPlan.amount;
    this.companyDetail.zohoUserCount = parseInt(qty);
    this.calculateSelectedAddOnsAmount();
  }

  calculateSelectedAddOnsAmount() {
    this.sumOfAddOnsAmount = 0;
    //this.selectedPlan.addons = [];
    let addOnsData: any[] = JSON.parse(JSON.stringify(this.addons));
    // this.selectedPlan.addons = this.addons;
    for (var i = 0; i < this.selectedPlan.addons.length; i++) {
      this.selectedPlan.addons[i].amount = addOnsData[i].amount;
    }
    let ref = this;
    console.log("addons", addOnsData);
    setTimeout(function () {
      ref.selectedPlan.addons.forEach(element => {

        if (element.isSelected) {
          if (element.type == 'one_time' && element.quantity > 0) {
            element.amount = element.quantity * element.amount;
          }

          ref.sumOfAddOnsAmount = ref.sumOfAddOnsAmount + element.amount;
        }
      });

      ref.subTotal = ref.sumOfAddOnsAmount + ref.sumOfPlanAmount;

      console.log("subTotal: ", ref.subTotal);
      ref.calculateTaxes();
    }, 200);

  }

  calculateTaxes() {
    this.sumOftaxesAmount = 0;
    this.selectedPlan.item_tax_preferences.forEach(element => {
      if (element.isActive) {


        let taxAmount = (this.subTotal * element.tax_percentage) / 100;
        element["taxAmount"] = taxAmount;
        this.sumOftaxesAmount = this.sumOftaxesAmount + taxAmount;
      }
    })
    this.calculateGrandTotal();
  }
  calculateGrandTotal() {
    this.grandTotal = this.subTotal + this.sumOftaxesAmount;
  }

  addonFlag: boolean = true;
  addOnQtyvalidation(addon: any) {
    let flag = true;
    let ref = this;
    setTimeout(function () {
      ref.selectedPlan.addons.forEach(element => {
        if ((element.type == 'one_time') && (element.quantity <= 0 || element.quantity == '') && (element.isSelected)) {
          flag = false;
        }
      });
      if (!flag) {
        ref.addonFlag = false;
      } else {
        ref.addonFlag = true;
      }
    }, 200);
  }

  createSubscription() {
    const modalRef = this.modalService.open(ZohoConfirmComponent, { size: 'lg' });
    modalRef.componentInstance.planData = this.selectedPlan;
    modalRef.componentInstance.companyData = this.userSignupData;
    modalRef.componentInstance.planConfirm.subscribe((confirm) => {
      if (confirm) {
        let createDate = this.selectedPlan.created_time;
        this.planName = this.selectedPlan.plan_code + "#" + this.selectedPlan.name;
        if (this.selectedPlan.interval_unit == "months") {
          let createdDateTime = new Date(createDate);
          let endDate = CommonVariables.add_months(createdDateTime, this.selectedPlan.interval);
          this.plantEndDate = CommonVariables.currentDateDMYHMS(endDate);
          console.log("planendDate ", this.plantEndDate);
          this.planEndDateFlag = true;
        }


        let priceBookId = 0;
        let currencyCode = "";
        if (this.selectedCountry == "India") {
          priceBookId = this.priceBooks[0].pricebook_id;
          currencyCode = this.priceBooks[0].currency_code;
        } else {
          priceBookId = this.priceBooks[1].pricebook_id;
          currencyCode = this.priceBooks[1].currency_code;
        }

        let planData = {
          "auto_collect": false,
          "place_of_supply": this.zohoStateCode,
          "payment_terms": 0,
          "customer": {
            "display_name": this.userSignupData.companyName,
            "email": this.userSignupData.companyEmail,
            "company_name": this.userSignupData.companyName,
            "currency_code": currencyCode,
            "gst_treatment": this.selectedCountry == "India" ? "consumer" : "overseas",
            "mobile": this.userSignupData.contactMobile,
            "phone": 0,
            "payment_terms": 0,
            "billing_address": {
              "attention": "",
              "city": "",
              "state": this.selectedCountry == "India" ? this.selectedState.trim() : "",
              "country": this.selectedCountry
            }
          },
          "plan": {
            "plan_code": this.selectedPlan.plan_code,
            "quantity": this.selectedPlan.quantity,
            "price": this.selectedPlan.recurring_price,
            "setup_fee": this.selectedPlan.setup_fee,
            "exclude_trial": false,
            "exclude_setup_fee": false,
            "billing_cycles": 0,
            "trial_days": this.selectedPlan.trial_period
          },
          "addons": []
        }

        for (var i = 0; i < this.selectedPlan.addons.length; i++) {
          if (this.selectedPlan.addons[i].isSelected == true) {
            if (this.selectedPlan.addons[i].quantity == 0) { this.selectedPlan.addons[i].quantity = 1; }
            planData.addons.push(this.selectedPlan.addons[i]);
          }
        }


        this.addSubscription(planData, this.selectedPlan);
      } else {

      }

    });
  }
}
