import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { Location } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  submitted = false;
  constructor(private location: Location,private ngxLoader: NgxUiLoaderService,
    private formBuilder: FormBuilder, private globalService: GlobalServiceService,private router:Router) { }

  ngOnInit() {
    this.ngxLoader.start();
    this.forgotPasswordForm = this.formBuilder.group({
      companyCode: ['', Validators.required],
      userId: ['', Validators.required],
    });
    this.ngxLoader.stop();
  }
  get f() { return this.forgotPasswordForm.controls; }
  onSubmit() {
    this.ngxLoader.start();
    this.submitted = true;

    if (this.forgotPasswordForm.invalid) {
      this.ngxLoader.stop();
      return;
    }
    this.globalService.forgotPassword(this.forgotPasswordForm.value)
      .subscribe(
        data => {
          this.ngxLoader.stop();
          //alert("New Password is sent to your register email.")
         // this.router.navigate(['/success-message']);
          this.router.navigate(['/success-message'], { queryParams: { page: 'forgotPassword' } });
          //this.navigateToLogin();
        },
        error => {
          console.log(error);
          this.ngxLoader.stop();
          alert("Please enter a valid username and password.")
        });
  }
  resetForgotPasswordForm(): void {
    this.forgotPasswordForm.reset();
  }
  navigateToLogin(): void {
    this.location.back();
  }
  logout() {
    this.globalService.logout().subscribe(
      (data: any) => {
        this.globalService.logoutSession();
        this.router.navigate(['/login']);
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
