import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonVariables } from 'src/app/commonVariables';
import { AppComponent } from '../app.component';
import { LogoutDialogService } from '../existing-user-logout/logout-dialog.service';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { PaidPlanSubscribeService } from '../modules/subscription/paid-plan-subscribe.service';
import { ZohoConfirmDialogService } from '../zoho-confirm-dailog/zoho-confirm-dialog.service';


@Component({
  selector: 'app-transaction-saved',
  templateUrl: './transaction-saved.component.html',
  styleUrls: ['./transaction-saved.component.css']
})
export class TransactionSavedComponent implements OnInit {

  hostPageId: string = '';

  currentUser: any = {};
  public moduleList: any[] = [];
  public isSetupFee: boolean = false;
  public signupData: any = {};
  constructor(public appComp: AppComponent, private paidPlanService: PaidPlanSubscribeService, private router: Router,
    private route: ActivatedRoute, private globalService: GlobalServiceService, private ngxLoader: NgxUiLoaderService,
    private zohoConfirmDialogService: ZohoConfirmDialogService, private logoutDialogService: LogoutDialogService) { }

  ngOnInit() {
    this.ngxLoader.start();
    this.route.queryParams.subscribe(params => {

      if (params['hostedpage_id'] != undefined) {
        this.hostPageId = params['hostedpage_id'];
        this.getHostPageById(this.hostPageId);

        this.moduleList = JSON.parse(localStorage.getItem("moduleList"));
        this.isSetupFee = JSON.parse(localStorage.getItem("isSetupFee"));
        console.log("modules List ", JSON.parse(localStorage.getItem("moduleList")));
      }
    });

    this.currentUser = JSON.parse(localStorage.getItem("signupData"));
    this.signupData = JSON.parse(localStorage.getItem("signupData"));
    console.log("currentUser ", this.currentUser);
    this.updateCompanyForPaymentDetail(this.signupData);
  }

  paymentDetails: any = null;
  planStartDate: string = '';
  planEndDate: string = '';
  billedon: string = '';
  getHostPageById(hostPageId: any) {
    this.paidPlanService.retrieveHostedPageById(hostPageId).subscribe((data) => {
      console.log("payment details ", data);
      this.paymentDetails = data.data;
      this.planStartDate = CommonVariables.reverseStrDate(this.paymentDetails.data.subscription.current_term_starts_at);
      this.planEndDate = CommonVariables.reverseStrDate(this.paymentDetails.data.subscription.current_term_ends_at);
      if (this.paymentDetails.data.subscription.interval == 1) {
        this.billedon = "Monthly"
      } else if (this.paymentDetails.data.subscription.interval == 3) {
        this.billedon = "Quaterly"
      } else if (this.paymentDetails.data.subscription.interval == 6) {
        this.billedon = "Half-yearly"
      } else if (this.paymentDetails.data.subscription.interval == 12) {
        this.billedon = "Yearly"
      }

      this.currentUser = JSON.parse(localStorage.getItem("signupData"));

      console.log("currentUser ", this.currentUser);

      

    }, error => {
      this.ngxLoader.stop();
      console.log("Error -->", error);
    });
  }

  thanksMessage: any = {};


  public companyDetail: any = {};

  subscribedPlanDetails: any = {};
  customerId: number = 0;

  pk = 0;
  updateCompanyForPaymentDetail(signupData: any) {

    signupData.subscriptionStatus = "live"
    this.globalService.addOrUpdateCompanyMaster(signupData).subscribe(
      (data) => {
        this.ngxLoader.stop();
        console.log("zoho usercount updated", data.data);
        this.pk = data.data.pk;
        this.loginUser(data.data);
      },
      (err) => {
        this.ngxLoader.stop();
        console.log(err);

      }
    );
  }

  customerDetails: any = {};

  contactUs() {
    window.open("https://easemyproduction.com/contact-us", "_blank");
  }

  loginUser(user: any) {

    this.zohoConfirmDialogService.confirm('Company Has been created Successfully.', 'Proceed To Enter')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxLoader.start();
          user["loggedInFlag"] = 0,
            this.globalService.checkLogin(user).subscribe((data) => {
              console.log("login checking ", data);
              this.ngxLoader.stop();
              if (data.statusCode == 200) {
                let user1: any = JSON.parse(localStorage.getItem("currentUser"));
                console.log("currently Logged in user: ", user);
                if (user1 != undefined && user1 != null) {
                  this.logoutExistingUser(data.data);
                } else {
                  this.globalService.logoutSession();
                  this.appComp.loggedInUser = data.data;
                  this.globalService.setCurrentUser(data.data);
                  this.router.navigate([`/financialYear/${data.data.pk}`]);

                }


              }

            }, error => {
              console.log("error=>", error);
              this.ngxLoader.stop();
            });


        } else {

        }
      })
      .catch(() => console.log('User dismissed the dialog'));
  }
  logoutExistingUser(userData) {
    let user: any = JSON.parse(localStorage.getItem("currentUser"));
    console.log("currently Logged in user: ", user);
    if (user != undefined && user != null) {
      this.logoutDialogService.confirm('Are you sure you want to logout of ' + user.companyName + ' company?', 'Yes to logout')
        .then((confirmed) => {
          if (confirmed) {
            this.globalService.logout().subscribe(
              (data: any) => {
                if (data.statusCode == 200) {
                  this.globalService.logoutSession();
                  this.appComp.loggedInUser = userData;
                  this.globalService.setCurrentUser(userData);
                  this.router.navigate([`/financialYear/${userData.pk}`]);

                }
              },
              (err: any) => {
                console.log(err);
              }
            );
          }
        }).catch(() => console.log('User dismissed the dialog'));

    }


  }

}
