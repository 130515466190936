import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { HeaderConfiguration } from '../headerConfiguration';
import { ExistingUserLogoutComponent } from './existing-user-logout.component';

@Injectable({
  providedIn: 'root'
})
export class LogoutDialogService {

  //requestHeader = HeaderConfiguration.headerConfg;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();

  constructor(private modalService: NgbModal) {
    console.log("logout-dialog.service.ts");
   }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ExistingUserLogoutComponent, { size: dialogSize, windowClass: 'logout-popup' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }
}
