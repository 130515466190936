import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAuth500errorComponent } from '../modules/shared/modal-auth500error/modal-auth500error.component';
import { AppComponent } from '../app.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    public auth2: any = {};
    public access_token: any = {};
    public authTokenFlag: boolean = false;
    private previousUrl: string = null;
    currentUrl: string = null;
    flag206: number = 0;
    constructor(private router: Router, private toasterService: ToastrService, private ngxLoader: NgxUiLoaderService,
        private globalService: GlobalServiceService, private modalService: NgbModal) {
        this.authTokenFlag = false;
        console.log("interceptor constructor");
        this.currentUrl = this.router.url;
        this.router.events.subscribe(event => {

            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
                this.authTokenFlag = false;
            };
        });

    }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let re = req.url;
        if (re.indexOf("/manufacturing/saveItemBom") != -1 || re.indexOf("/common/checkDuplicateCompanyCode") != -1 || re.indexOf("/common/testEmail") != -1) {
            console.log("line called 42");
            return next.handle(req);
        } else {
            return next.handle(req).pipe(
                catchError((err: any) => {
                    if (err.error instanceof ErrorEvent) {
                        var errorMsg = `Error: ${err.error.message}`;
                    }
                    else if (err instanceof HttpErrorResponse) {
                        try {
                            if (err.status === 401) {
                                console.log("error status: 401");
                                if (!this.authTokenFlag) {
                                    this.authTokenFlag = true;
                                    let loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
                                    let token = JSON.parse(localStorage.getItem("accessToken"));
                                    console.log("token ", token);
                                    let userDetail: any = {
                                        "userId": loggedInUser.userId,
                                        "password": loggedInUser.password,
                                        "refreshToken": token.refresh_token
                                    };

                                    return this.globalService.handleUnauthorized(req, next, userDetail).pipe(
                                        take(1),
                                        debounceTime(4000),
                                        switchMap((token) => {
                                            if (token.statusCode === 500) {
                                                this.openModal(token.msg);
                                                this.toasterService.error(token.statusCode, token.msg, { positionClass: 'toast-top-right' });
                                                console.log("token statusCode === 500");
                                            } else {
                                                this.globalService.setAccessToken(token.data);
                                                this.auth2 = token.data;
                                                let accessT = JSON.parse(localStorage.getItem('accessToken'));
                                                if (accessT != null) {
                                                    this.access_token = accessT.access_token;
                                                    req = req.clone({ headers: req.headers.set('Authorization', 'bearer ' + this.auth2.access_token) });
                                                }
                                                this.changeLocation();
                                                return next.handle(req);
                                            }

                                        }));

                                } else {

                                }

                            } else if (err.status === 206) {
                                this.flag206 += 1;
                                console.log("err.status206:", this.flag206);
                                if (this.flag206 <= 1) {
                                    this.toasterService.error('206', err.error.text, { positionClass: 'toast-top-right' });
                                    this.globalService.logout().subscribe((data: any) => {
                                        if (data.statusCode == 200) {
                                            this.globalService.logoutSession();
                                            this.router.navigate(['/login']);
                                            window.location.href = "/";
                                        }

                                    });
                                }

                            } else {
                                this.toasterService.error(err.error.statusCode, err.error.msg, { positionClass: 'toast-top-right' });
                                this.ngxLoader.stop();
                            }

                            this.globalService.setSubmitErrorFlag(false);

                        } catch (e) {
                            this.globalService.setSubmitErrorFlag(false);
                            this.toasterService.error('An error occurred', '', { positionClass: 'toast-top-right' });
                            console.log("an error occured");
                            this.ngxLoader.stop();
                        }
                    }
                    console.log(of(err));
                    return of(err);
                }));
        }
    }




    sleeptime(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    changeLocation() {

        // save current route first
        const currentRoute = this.router.url;

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }

    openModal(errorMsg: any) {
        const modalRef = this.modalService.open(ModalAuth500errorComponent, { size: 'xl' as 'lg' });
        modalRef.componentInstance.modalData = { "errorMsg": errorMsg };
        modalRef.componentInstance.itemData.subscribe((receivedEntry) => {
        })
    }

}
export let ErrorInterceptorProviders = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
};