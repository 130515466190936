import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
//import { User } from '../model/model';
//import { HeaderConfiguration } from 'src/app/headerConfiguration';
import { Router } from '@angular/router';
import { catchError, map, take } from 'rxjs/operators';
import { TaxApiModel } from 'src/app/models/tax-api-model';

@Injectable({
  providedIn: 'root'
})
export class GlobalServiceService {
  baseurl = environment.apiUrl;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  private facilitySubject: BehaviorSubject<any>;
  public facility: Observable<any>;

  private mailSettingDataSubject: BehaviorSubject<any>;
  public mailSettingData: Observable<any>;

  private financialYearSubject: BehaviorSubject<any>;
  public financialYear: Observable<any>;
  public profilePicUrl: Observable<any>;
  private profilePicUrlSubject: BehaviorSubject<any>;
  public minDate2 = new Subject<any>();
  public soMinDate2 = new Subject<any>();
  public issueReturnableRtvMinDate2 = new Subject<any>();
  public soPackingMinDate2 = new Subject<any>();
  public soInvoiceMinDate2 = new Subject<any>();
  public soRfcMinDate2 = new Subject<any>();
  public sampleBaseInvoiceMinDate2 = new Subject<any>();
  public stOPackingMinDate2 = new Subject<any>();
  public issueForJobWorkMinDate2 = new Subject<any>();
  public stockReqWhMinDate2 = new Subject<any>();
  public orderToMoStockBaseMinDate2 = new Subject<any>();
  public stoInvoiceMinDate2 = new Subject<any>();
  public dropShipMinDate2 = new Subject<any>();
  public receiptFromJobWorkMinDate2 = new Subject<any>();
  public moWhIssueMinDate2 = new Subject<any>();
  public moPackingMinDate2 = new Subject<any>();
  public aditionMatReqMinDate2 = new Subject<any>();
  public openIrnRTVMinDate2 = new Subject<any>();
  public openIrnQcMinDate2 = new Subject<any>();
  public inwardReceiptMinDate2 = new Subject<any>();
  public irnMinDate2 = new Subject<any>();
  public poQcMinDate2 = new Subject<any>();
  public poRTVMinDate2 = new Subject<any>();
  public reqFromMoMinDate2 = new Subject<any>();
  public reqFromMoaddiReqMinDate2 = new Subject<any>();
  public reqOpIssueMinDate2 = new Subject<any>();
  public srcWHIrnMinDate2 = new Subject<any>();
  public srcWHQcMinDate2 = new Subject<any>();
  public centerSoMinDate2 = new Subject<any>();
  public issueSampleMinDate2 = new Subject<any>();
  public issueToProdMinDate2 = new Subject<any>();
  public moProcMinDate2 = new Subject<any>();
  public addOpenIrnMinDate2 = new Subject<any>();
  public issueToWhMinDate2 = new Subject<any>();
  public orderToMoMinDate2 = new Subject<any>();
  public addPoMinDate2 = new Subject<any>();
  public addQuotationMinDate2 = new Subject<any>();
  public addQuotationInvMinDate2 = new Subject<any>();
  public reqFromProdMinDate2 = new Subject<any>();
  public reqToMoMinDate2 = new Subject<any>();
  public soMoMinDate2 = new Subject<boolean>();

  public isFacilitySelectedSubject: BehaviorSubject<boolean>;
  public isFacilitySelected = new Subject<boolean>();

  public isUnreadNotificationAvailableSubject: BehaviorSubject<boolean>;
  public isUnreadNotificationAvailable = new Subject<boolean>();
  //requestHeader = HeaderConfiguration.getHeaderConfiguration();
  public isCompanyUpgradedSubject: BehaviorSubject<boolean>;
  public isCompanyUpgraded = new Subject<boolean>();
  public isNotificationAddedSubject: BehaviorSubject<boolean>;
  public isNotificationAdded = new Subject<boolean>();

  private accessTokenSubject: BehaviorSubject<any>;
  public accessToken: Observable<any>;

  isAuthCalledFromFySubject: BehaviorSubject<boolean>;
  isAuthCalledFromFy = new Subject<boolean>();

  private upgradeAccessSubject: BehaviorSubject<any>;
  public upgradeAccess: Observable<any>;
  private upgradeViewSubject: BehaviorSubject<any>;
  public upgradeView: Observable<any>;
  public submitErrorFlag = new Subject<any>();

  private dealerFlagSubject$: BehaviorSubject<boolean>;
  public dealerFlag$: Observable<boolean>;

  private dealerDataSubject$: BehaviorSubject<{ flag: boolean, dealerData: any }>;
  public dealerData$: Observable<{ flag: boolean, dealerData: any }>;

  constructor(private http: HttpClient, private router: Router) {
    console.log("global-service.service.ts")
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.facilitySubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('facility')));
    this.facility = this.facilitySubject.asObservable();

    this.financialYearSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('financialYear')));
    this.financialYear = this.financialYearSubject.asObservable();
    this.profilePicUrlSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('profilePicUrl')));
    this.profilePicUrl = this.profilePicUrlSubject.asObservable();

    this.accessTokenSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('accessToken')));
    this.accessToken = this.accessTokenSubject.asObservable();
    this.isAuthCalledFromFy.next(false);
    // this.requestHeader = HeaderConfiguration.getHeaderConfiguration();

    this.upgradeAccessSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('isUpgradeAccess')));
    this.upgradeAccess = this.upgradeAccessSubject.asObservable();
    this.upgradeViewSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('isViewAccess')));
    this.upgradeView = this.upgradeViewSubject.asObservable();

    this.dealerFlagSubject$ = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('dealerFlag')));
    this.dealerFlag$ = this.dealerFlagSubject$.asObservable();

    this.dealerDataSubject$ = new BehaviorSubject<{ flag: boolean, dealerData: any }>(JSON.parse(localStorage.getItem('dealerData')));
    this.dealerData$ = this.dealerDataSubject$.asObservable();

  }

  setActionBean(actionBean: any) {
    console.log("action in current route", actionBean);
    this.getClearStorage();
    actionBean.forEach(action => {

      switch (action.actionName) {
        case 'Add':
          localStorage.setItem('isAddAccess', JSON.stringify(true));
          break;
        case 'Edit':
          localStorage.setItem('isEditAccess', JSON.stringify(true));
          break;
        case 'Delete':
          localStorage.setItem('isDeleteAccess', JSON.stringify(true));
          break;
        case 'Approve':
          localStorage.setItem('isApproveAccess', JSON.stringify(true));
          break;
        case 'Cancel':
          localStorage.setItem('isCancelAccess', JSON.stringify(true));
          break;
        case 'Print':
          localStorage.setItem('isPrintAccess', JSON.stringify(true));
          break;
        case 'Hold':
          localStorage.setItem('isHoldAccess', JSON.stringify(true));
          break;
        case 'UnHold':
          localStorage.setItem('isUnholdAccess', JSON.stringify(true));
          break;
        case 'Upload Image':
          localStorage.setItem('isUploadImageAccess', JSON.stringify(true));
          break;
        case 'Upload Doc':
          localStorage.setItem('isUploadDocAccess', JSON.stringify(true));
          break;
        case 'Copy':
          localStorage.setItem('isCopyAccess', JSON.stringify(true));
          break;
        case 'Update':
          localStorage.setItem('isUpdateAccess', JSON.stringify(true));
          break;
        case 'Lock':
          localStorage.setItem('isLockAccess', JSON.stringify(true));
          break;
        case 'UnLock':
          localStorage.setItem('isUnLockAccess', JSON.stringify(true));
          break;
        case 'Accept':
          localStorage.setItem('isAcceptAccess', JSON.stringify(true));
          break;
        case 'Reject':
          localStorage.setItem('isRejectAccess', JSON.stringify(true));
          break;
        case 'Create Return':
          localStorage.setItem('isCreateReturnAccess', JSON.stringify(true));
          break;
        case 'Create QC':
          localStorage.setItem('isCreateQCAccess', JSON.stringify(true));
          break;
        case 'Create IRN':
          localStorage.setItem('isCreateIRNAccess', JSON.stringify(true));
          break;
        case 'Purchase Cart':
          localStorage.setItem('isPurchaseCartAccess', JSON.stringify(true));
          break;
        case 'Save':
          localStorage.setItem('isSaveAccess', JSON.stringify(true));
          break;
        case 'Issue Items':
          localStorage.setItem('isIssueItemsAccess', JSON.stringify(true));
          break;
        case 'Create Packing':
          localStorage.setItem('isCreatePackingAccess', JSON.stringify(true));
          break;
        case 'View':
          localStorage.setItem('isViewAccess', JSON.stringify(true));
          break;
        case 'Create Invoice':
          localStorage.setItem('isCreateInvoiceAccess', JSON.stringify(true));
          break;
        case 'Add Mail Settings':
          localStorage.setItem('isAddMailSettingsAccess', JSON.stringify(true));
          break;
        case 'Submit':
          localStorage.setItem('isSubmitAccess', JSON.stringify(true));
          break;
        case 'Test Mail':
          localStorage.setItem('isTestMailAccess', JSON.stringify(true));
          break;
        case 'Upgrade':
          localStorage.setItem('isUpgradeAccess', JSON.stringify(true));
          break;
        case ' Mail Client':
          localStorage.setItem('isMailClientAccess', JSON.stringify(true));
          break;
        case 'Revise Quote':
          localStorage.setItem('isReviseQuoteAccess', JSON.stringify(true));
          break;
        case ' Mail Client':
          localStorage.setItem('isMailClientAccess', JSON.stringify(true));
          break;
        case 'Revise Quote':
          localStorage.setItem('isReviseQuoteAccess', JSON.stringify(true));
          break;
        case 'Generate Order':
          localStorage.setItem('isGenerateOrderAccess', JSON.stringify(true));
          break;
        case 'Search':
          localStorage.setItem('isSearchAccess', JSON.stringify(true));
          break;
        case 'Execute':
          localStorage.setItem('isExecuteAccess', JSON.stringify(true));
          break


      }
    });
  }

  setActionBeanOverview(actionBean: any, moduleName) {
    console.log("action in current route", actionBean);
    // this.getClearOverViewStorage();
    if (moduleName == "sales-order") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isAddOverViewSalesAccess', JSON.stringify(true));
            break;
          case 'Approve':
            localStorage.setItem('isApproveOverViewSalesAccess', JSON.stringify(true));
            break;
          case 'UnHold':
            localStorage.setItem('isUnholdOverViewSalesAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "packing") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isAddOverViewSalesPackingAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "so-invoice") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Create Invoice':
            localStorage.setItem('isAddOverViewSalesInvoiceAccess', JSON.stringify(true));
            break;

        }
      });
    }
    if (moduleName == "issue-for-jobwork") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Approve':
            localStorage.setItem('isApproveViewJobWorkAccess', JSON.stringify(true));
            break;

        }
      });
    }
    if (moduleName == "issue-returnable-non-returnable") {

    }

    if (moduleName == "issue-to-production") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Approve':
            localStorage.setItem('isApproveViewMoMovementAccess', JSON.stringify(true));
            break;

        }
      });
    }

    if (moduleName == "item-receipt-warehouse") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isCreateIrnViewIrnWhAccess', JSON.stringify(true));
            break;

        }
      });
    }
    if (moduleName == "open-irn") {

    }
    if (moduleName == "order-to-manufacturing-order-base") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Approve':
            localStorage.setItem('isApproveViewOrderToMoBaseAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "purchase-order-list") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Approve':
            localStorage.setItem('isApproveOverViewPoAccess', JSON.stringify(true));
            break;
          case 'UnHold':
            localStorage.setItem('isUnHoldOverViewPoAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "qc-acceptance") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Create QC':
            localStorage.setItem('isCreateQcOverViewPoAcceptAccess', JSON.stringify(true));
            break;
        }
      });

    }
    if (moduleName == "qc-warehouse") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Create QC':
            localStorage.setItem('isCreateQcOverViewStockWhReqAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "quick-po") {

    }
    if (moduleName == "receipt-from-productions") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Accept':
            localStorage.setItem('isAcceptOverViewReceiptfromProdAccess', JSON.stringify(true));
            break;

        }
      });
    }
    if (moduleName == "receipt-manufacturing") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Accept':
            localStorage.setItem('isAcceptOverViewReceiptMoAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "requistion-to-manufacturing") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Approve':
            localStorage.setItem('isApproveOverViewStockBaseAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "return-from-customer") {

    }

    if (moduleName == "return-to-vendor") {

    }
    if (moduleName == "stock-adjustments") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Approve':
            localStorage.setItem('isApproveOverViewStockAdjustmentAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "stock-conversion") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Approve':
            localStorage.setItem('isApproveOverViewStockConversionAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "stock-requisition-warehouse") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Approve':
            localStorage.setItem('isApproveOverViewWhReqAccess', JSON.stringify(true));
            break;
          case 'UnHold':
            localStorage.setItem('isUnHoldOverViewWhReqAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "stock-transfer-order") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'UnHold':
            localStorage.setItem('isUnHoldOverViewStoAccess', JSON.stringify(true));
            break;
          case 'Approve':
            localStorage.setItem('isApproveOverViewStoAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "stock-warehouse-invoice") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isCreateInvoiceOverViewStoAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "stock-warehouse-packing") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isPackingOverViewStoAccess', JSON.stringify(true));
            break;
        }
      });
    }
    if (moduleName == "vendor-irn") {
      actionBean.forEach(action => {
        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isCreateIrnOverViewPoIrnAccess', JSON.stringify(true));
            break;
        }
      });
    }

    //  for Overview Manufacturing
    if (moduleName == "issue-from-MO") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isAddOverViewIssueMoAccess', JSON.stringify(true));
            break;

        }
      });
    }

    if (moduleName == "mo-warehouse") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'UnHold':
            localStorage.setItem('isUnholdOverViewMoWarehouseAccess', JSON.stringify(true));
            break;
          case 'Create Packing':
            localStorage.setItem('isCreatePackingOverViewMoWarehouseAccess', JSON.stringify(true));
            break;

        }
      });
    }

    if (moduleName == "open-issue-to-warehouse") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isAddOverViewOpenIssueWarehouseAccess', JSON.stringify(true));
            break;
          case 'Approve':
            localStorage.setItem('isApproveOverViewOpenIssueWarehouseAccess', JSON.stringify(true));
            break;

        }
      });
    }

    if (moduleName == "open-receipt-from-warehouse") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Accept':
            localStorage.setItem('isAcceptOverViewOpenReceiptWarehouseAccess', JSON.stringify(true));
            break;
          case 'Reject':
            localStorage.setItem('isRejectOverViewOpenReceiptWarehouseAccess', JSON.stringify(true));
            break;

        }
      });
    }

    if (moduleName == "receipt-from-warehouse") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Accept':
            localStorage.setItem('isAcceptOverViewReceiptWarehouseAccess', JSON.stringify(true));
            break;
          case 'Reject':
            localStorage.setItem('isRejectOverViewReceiptWarehouseAccess', JSON.stringify(true));
            break;

        }
      });
    }

    if (moduleName == "requisition-from-production") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isAddOverViewReqProdAccess', JSON.stringify(true));
            break;
          case 'Approve':
            localStorage.setItem('isApproveOverViewReqProdAccess', JSON.stringify(true));
            break;

        }
      });
    }

    if (moduleName == "requisition-mo") {
      actionBean.forEach(action => {

        switch (action.actionName) {
          case 'Add':
            localStorage.setItem('isAddOverViewReqMoAccess', JSON.stringify(true));
            break;
          case 'Approve':
            localStorage.setItem('isApproveOverViewReqMoAccess', JSON.stringify(true));
            break;

        }
      });
    }


  }


  getClearOverViewStorage() {
    localStorage.removeItem('isAddOverViewSalesAccess');
    localStorage.removeItem('isApproveOverViewSalesAccess');
    localStorage.removeItem('isUnholdOverViewSalesAccess');
    localStorage.removeItem('isAddOverViewSalesPackingAccess');
    localStorage.removeItem('isAddOverViewSalesInvoiceAccess');
    localStorage.removeItem('isApproveViewJobWorkAccess');
    localStorage.removeItem('isApproveViewMoMovementAccess');
    localStorage.removeItem('isCreateIrnViewIrnWhAccess');
    localStorage.removeItem('isApproveViewOrderToMoBaseAccess');
    localStorage.removeItem('isApproveOverViewPoAccess');
    localStorage.removeItem('isUnHoldOverViewPoAccess');
    localStorage.removeItem('isCreateQcOverViewPoAcceptAccess');
    localStorage.removeItem('isCreateQcOverViewStockWhReqAccess');
    localStorage.removeItem('isAcceptOverViewReceiptfromProdAccess');
    localStorage.removeItem('isAcceptOverViewReceiptMoAccess');
    localStorage.removeItem('isApproveOverViewStockBaseAccess');
    localStorage.removeItem('isApproveOverViewStockAdjustmentAccess');
    localStorage.removeItem('isApproveOverViewStockConversionAccess');
    localStorage.removeItem('isApproveOverViewWhReqAccess');
    localStorage.removeItem('isUnHoldOverViewWhReqAccess');
    localStorage.removeItem('isUnHoldOverViewStoAccess');
    localStorage.removeItem('isApproveOverViewStoAccess');
    localStorage.removeItem('isCreateInvoiceOverViewStoAccess');
    localStorage.removeItem('isPackingOverViewStoAccess');
    localStorage.removeItem('isCreateIrnOverViewPoIrnAccess');
    localStorage.removeItem('isAddOverViewIssueMoAccess');
    localStorage.removeItem('isUnholdOverViewMoWarehouseAccess');
    localStorage.removeItem('isCreatePackingOverViewMoWarehouseAccess');
    localStorage.removeItem('isAddOverViewOpenIssueWarehouseAccess');
    localStorage.removeItem('isApproveOverViewOpenIssueWarehouseAccess');
    localStorage.removeItem('isAcceptOverViewOpenReceiptWarehouseAccess');
    localStorage.removeItem('isRejectOverViewOpenReceiptWarehouseAccess');
    localStorage.removeItem('isAcceptOverViewReceiptWarehouseAccess');
    localStorage.removeItem('isRejectOverViewReceiptWarehouseAccess');
    localStorage.removeItem('isAddOverViewReqProdAccess');
    localStorage.removeItem('isApproveOverViewReqProdAccess');
    localStorage.removeItem('isAddOverViewReqMoAccess');
    localStorage.removeItem('isApproveOverViewReqMoAccess');

  }

  getClearStorage() {
    localStorage.removeItem('isAddAccess');
    localStorage.removeItem('isEditAccess');
    localStorage.removeItem('isDeleteAccess');
    localStorage.removeItem('isApproveAccess');
    localStorage.removeItem('isCancelAccess');
    localStorage.removeItem('isPrintAccess');
    localStorage.removeItem('isHoldAccess');
    localStorage.removeItem('isUnholdAccess');
    localStorage.removeItem('isUploadImageAccess');
    localStorage.removeItem('isUploadDocAccess');
    localStorage.removeItem('isCopyAccess');
    localStorage.removeItem('isUpdateAccess');
    localStorage.removeItem('isLockAccess');
    localStorage.removeItem('isUnLockAccess');
    localStorage.removeItem('isAcceptAccess');
    localStorage.removeItem('isRejectAccess');
    localStorage.removeItem('isCreateReturnAccess');
    localStorage.removeItem('isCreateQCAccess');
    localStorage.removeItem('isCreateIRNAccess');
    localStorage.removeItem('isPurchaseCartAccess');
    localStorage.removeItem('isSaveAccess');
    localStorage.removeItem('isIssueItemsAccess');
    localStorage.removeItem('isCreatePackingAccess');
    localStorage.removeItem('isViewAccess');
    localStorage.removeItem('isCreateInvoiceAccess');
    localStorage.removeItem('isAddMailSettingsAccess');
    localStorage.removeItem('isSubmitAccess');
    localStorage.removeItem('isTestMailAccess');
    localStorage.removeItem('isUpgradeAccess');
    localStorage.removeItem('isMailClientAccess');
    localStorage.removeItem('isReviseQuoteAccess');
    localStorage.removeItem('isGenerateOrderAccess');
    localStorage.removeItem('isSearchAccess');
    localStorage.removeItem('isExecuteAccess');


  }

  clearDealerAndGridsChacheOnLogout() {
    localStorage.removeItem('dealerData');
    localStorage.removeItem('dealerFlag');
    localStorage.clear();
    sessionStorage.clear();
  }

  setCurrentUser(currentUser: any) {
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.currentUserSubject.next(currentUser);
    //this.startRefreshTokenTimer(currentUser.tokenBean.expires_in, currentUser.tokenBean.refresh_token);

  }


  setCurrentUser2(currentUser: any) {
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.currentUserSubject.next(currentUser);
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  setFacility(facility: any) {
    sessionStorage.setItem('facility', JSON.stringify(facility));
    this.facilitySubject.next(facility);
    console.log(facility, sessionStorage.getItem('facility'));
  }

  public get facilityValue() {
    return this.facilitySubject.value
  }

  setMailSettingData(mailSettingData: any) {
    sessionStorage.setItem('mailSettingData', JSON.stringify(mailSettingData));
  }

  setFinancialYear(financialYear: any) {
    let financialYearJson = sessionStorage.setItem('financialYear', JSON.stringify(financialYear));
    this.financialYearSubject.next(financialYear);
    console.log(financialYear, financialYearJson);

  }

  public get financialYearValue() {
    return this.financialYearSubject.value
  }

  getServerDate(): Observable<any> {
    //this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<any>(`${this.baseurl}/common/getServerDate`,
      // { headers: this.requestHeader }
    );
  }

  login(user: any): Observable<any> {
    let exitingUser = {
      "userId": user.userId,
      "password": user.password,
      "companyCode": user.companyCode
    }

    return this.http.post<any>(`${this.baseurl}/common/checkLogin`, exitingUser).pipe(map(user => {
      //this.setCurrentUser(user.data);
      //this.startRefreshTokenTimer();
      return user;
    }));
  }

  logoutSession() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('facilityId');
    sessionStorage.removeItem('facilityId');
    sessionStorage.removeItem('financialYear');
    sessionStorage.removeItem('tokenFlag');
    sessionStorage.removeItem('facility');
    localStorage.removeItem("isCompanyExpired")
    localStorage.removeItem('financialYear');
    localStorage.removeItem('facility');
    localStorage.removeItem('profilePicUrl');
    localStorage.removeItem('accessToken');
    this.accessTokenSubject.next(null);
    this.currentUserSubject.next(null);
    this.facilitySubject.next(null);
    this.financialYearSubject.next(null);
    this.financialYearSubject.next(null);
    this.getClearStorage();
    this.stopRefreshTokenTimer();
    this.clearDealerAndGridsChacheOnLogout();
  }

  logout() {
    return this.http.get<any>(`${this.baseurl}/common/logout`);
  }

  otpVerification(emailId: any, mobileNo: number) {
    //this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<any>(`${this.baseurl}/common/sendOtpForCompanyRegistration?emailId=${emailId}&mobileNumber=${mobileNo}`,
      //{ headers: this.requestHeader }
    );
  }
  getStateByCountryId(countryId: number): Observable<any> {
    //this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<any>(`${this.baseurl}/common/getStateByCountryId/${countryId}`,
      //  { headers: this.requestHeader }
    )
  }
  getCountry(): Observable<any> {
    //this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<any>(`${this.baseurl}/common/getCountries`,
      //{ headers: this.requestHeader }
    )
  }

  getCompanyLogoAsStream(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getCompanyLogoAsStream`)
  }

  getAllModules(): Observable<any[]> {
    // this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<any[]>(`${this.baseurl}/common/allModuleList`,
      // { headers: this.requestHeader }
    );
  }

  getAllPaidModules(): Observable<any[]> {
    // this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<any[]>(`${this.baseurl}/common/paidModuleList`,
      // { headers: this.requestHeader }
    );
  }

  registerCompany(company: any): Observable<any> {
    //this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.post<any>(`${this.baseurl}/common/addOrUpdateCompany`, company,
      //{ headers: this.requestHeader }
    );
  }

  changeOldPassword(userId: string, password: any, oldPassword: any): Observable<any> {
    //this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    let passwordDetails = {
      "userId": userId,
      "password": password,
      "oldPassword": oldPassword
    }
    return this.http.post<any>(`${this.baseurl}/common/changePasswordWithOldPasswordCheck`, passwordDetails,
      // { headers: this.requestHeader }
    );
  }

  changePassword(userId: string, password: any, companyCode: any): Observable<any> {
    //this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    let passwordDetails = {
      "userId": userId,
      "password": password,
      "companyCode": companyCode
    }
    return this.http.post<any>(`${this.baseurl}/common/changePassword`, passwordDetails,
      // { headers: this.requestHeader }
    );
  }

  forgotPassword(userInfo: any): Observable<any> {
    //this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<any>(`${this.baseurl}/common/forgetPassword?userId=${userInfo.userId}&companyCode=${userInfo.companyCode}`,
      //{ headers: this.requestHeader }
    )
  }

  getFacilityByUser(pk: number, financialYearId: number): Observable<any> {
    // this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<any>(`${this.baseurl}/common/userDetailsByPk?userPk=${pk}&financialYearId=${financialYearId}`,
      // { headers: this.requestHeader }
    )
  }

  loginV2(user: any): Observable<any> {
    let exitingUser = {
      "userId": user.userId,
      "password": user.password,
      "companyCode": user.companyCode
    }

    return this.http.post<any>(`${this.baseurl}/common/v2/checkLogin`, exitingUser).pipe(map(user => {
      return user;
    }));
  }

  getAllFinancialYears(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseurl}/common/financialYearList`);
  }

  getButtonAccess(modulePk: number, actionName: string): Observable<any> {
    let user: any = JSON.parse(localStorage.getItem("currentUser"))
    let userPk: number = user.pk
    return this.http.get<any>(`${this.baseurl}/common/role/menuSubMenuActionByRoleAndMenuUrl?modulePk=${modulePk}&menuAction=${actionName}&userPk=${userPk}`)
  }

  checkDuplicateDomainName(domainName) {
    //this.requestHeader = HeaderConfiguration.getHeaderConfiguration();
    return this.http.get<any>(`${this.baseurl}/common/checkDuplicateCompanyCode/${domainName}`,
      //{ headers: this.requestHeader }
    );
  }

  getUnreadNotificationCount(): Observable<any> {
    let user: any = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get<any[]>(`${this.baseurl}/common/unreadNotificationCount?userPk=${user.pk}`);
  }

  saveReadReceipt(notificationId): Observable<any[]> {
    let user: any = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get<any[]>(`${this.baseurl}/common/saveReadReceipt?notificationPk=${notificationId}&userPk=${user.pk}`);
  }

  getLessActiveNotificationList(): Observable<any[]> {
    let user: any = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get<any[]>(`${this.baseurl}/common/lessActiveNotificationList?userPk=${user.pk}`);
  }

  addNotification(notification: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/saveNotification`, notification);
  }

  getAllCompanyList(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseurl}/common/allCompanyMasterList`)

  }

  setProfilePic(url: any) {

    localStorage.setItem('profilePicUrl', JSON.stringify(url));
    this.profilePicUrlSubject.next(url);
  }

  public get profilePicValue() {
    return this.profilePicUrlSubject.value
  }
  getIndustryTypeList(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/industry/industryTypeList`)
  }

  addAllowAdminLogin(allowAdminobj: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/allowAdminLogin`, allowAdminobj);
  }

  getAllowAdminLoginDetails(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/allowAdminLoginDetails`);
  }

  getAllPlan(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/allSubsriptionPlanList`);
  }

  getAllPlanRedirectURI() {

    const redirectUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoSubscriptions.fullaccess.all&client_id=1000.R28LACA4U9PMO9ZLKP45ET39KTFVEH&response_type=code&redirect_uri=http://13.234.36.44:3333/common/allSubsriptionPlanList& access_type=offline`;
    //window.location.href = redirectUrl;
    window.open(redirectUrl, "_blank");
  }

  addSubscription(subscription: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/addSubscription`, subscription);
  }

  addOrUpdateCompanyMaster(userData): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/addOrUpdateCompany`, userData);
  }

  checkLogin(userData): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/checkLogin`, userData).pipe(map(user => {
      return user;
    }));
  }

  checkLogin_v2(userData): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/v2/checkLogin`, userData).pipe(map(user => {
      return user;
    }));
  }

  checkDuplicateCompanyCodeBulk(domainName: string): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/checkDuplicateCompanyCodeBulk`, [domainName]);
  }

  getMobileOtp(mobileNo: string): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/sms/sendOtp/${mobileNo}`);
  }
  getMobileEmailOtp(mobileNo: string, email: string): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/sendOtpForCompanyRegistration?emailId=${email}&mobileNumber=${mobileNo}`);
  }

  getLatestNotication(userPk: number, count: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/latestNotificationList/${userPk}?count=${count}`);
  }

  setMinDate2(mindate2: any) {
    this.minDate2.next(mindate2);
  }

  setSoMinDate2(mindate2: any) {
    this.soMinDate2.next(mindate2);
  }
  setIssueReturnableRtvMinDate2(mindate2: any) {
    this.issueReturnableRtvMinDate2.next(mindate2);
  }
  setSoPackingMinDate2(mindate2: any) {
    this.soPackingMinDate2.next(mindate2);
  }
  setSoInvoiceMinDate2(mindate2: any) {
    this.soInvoiceMinDate2.next(mindate2);
  }
  setSoRfcMinDate2(mindate2: any) {
    this.soRfcMinDate2.next(mindate2);
  }
  setSampleBaseInvoiceMinDate2(mindate2: any) {
    this.sampleBaseInvoiceMinDate2.next(mindate2);
  }
  setStOPackingMinDate2(mindate2: any) {
    this.stOPackingMinDate2.next(mindate2);
  }
  setIssueForJobWorkMinDate2(mindate2: any) {
    this.issueForJobWorkMinDate2.next(mindate2);
  }
  setStockReqWhMinDate2(mindate2: any) {
    this.stockReqWhMinDate2.next(mindate2);
  }
  setOrderToMoStockBaseMinDate2(mindate2: any) {
    this.orderToMoStockBaseMinDate2.next(mindate2);
  }
  setStoInvoiceMinDate2(mindate2: any) {
    this.stoInvoiceMinDate2.next(mindate2);
  }

  setDropShipMinDate2(mindate2: any) {
    this.dropShipMinDate2.next(mindate2);
  }
  setReceiptFromJobWorkMinDate2(mindate2: any) {
    this.receiptFromJobWorkMinDate2.next(mindate2);
  }
  setMoWhIssueMinDate2(mindate2: any) {
    this.moWhIssueMinDate2.next(mindate2);
  }
  setMoPackingMinDate2(mindate2: any) {
    this.moPackingMinDate2.next(mindate2);
  }
  setAditionMatReqMinDate2(mindate2: any) {
    this.aditionMatReqMinDate2.next(mindate2);
  }
  setOpenIrnRTVMinDate2(mindate2: any) {
    this.openIrnRTVMinDate2.next(mindate2);
  }
  setOpenIrnQcMinDate2(mindate2: any) {
    this.openIrnQcMinDate2.next(mindate2);
  }
  setInwardReceiptMinDate2(mindate2: any) {
    this.inwardReceiptMinDate2.next(mindate2);
  }
  setIrnMinDate2(mindate2: any) {
    this.irnMinDate2.next(mindate2);
  }
  setPoQcMinDate2(mindate2: any) {
    this.poQcMinDate2.next(mindate2);
  }
  setPoRTVMinDate2(mindate2: any) {
    this.poRTVMinDate2.next(mindate2);
  }
  setReqFromMoMinDate2(mindate2: any) {
    this.reqFromMoMinDate2.next(mindate2);
  }

  setReqFromMoaddiReqMinDate2(mindate2: any) {
    this.reqFromMoaddiReqMinDate2.next(mindate2);
  }
  setReqOpIssueMinDate2(mindate2: any) {
    this.reqOpIssueMinDate2.next(mindate2);
  }
  setSrcWHIrnMinDate2(mindate2: any) {
    this.srcWHIrnMinDate2.next(mindate2);
  }
  setSrcWHQcMinDate2(mindate2: any) {
    this.srcWHQcMinDate2.next(mindate2);
  }
  setCenterSoMinDate2(mindate2: any) {
    this.centerSoMinDate2.next(mindate2);
  }
  setIssueSampleMinDate2(mindate2: any) {
    this.issueSampleMinDate2.next(mindate2);
  }
  setIssueToProdMinDate2(mindate2: any) {
    this.issueToProdMinDate2.next(mindate2);
  }
  setMoProcMinDate2(mindate2: any) {
    this.moProcMinDate2.next(mindate2);
  }
  setAddOpenIrnMinDate2(mindate2: any) {
    this.addOpenIrnMinDate2.next(mindate2);
  }
  setIssueToWhMinDate2(mindate2: any) {
    this.issueToWhMinDate2.next(mindate2);
  }
  setOrderToMoMinDate2(mindate2: any) {
    this.orderToMoMinDate2.next(mindate2);
  }
  setAddPoMinDate2(mindate2: any) {
    this.addPoMinDate2.next(mindate2);
  }
  setAddQuotationMinDate2(mindate2: any) {
    this.addQuotationMinDate2.next(mindate2);
  }
  setAddQuotationInvMinDate2(mindate2: any) {
    this.addQuotationInvMinDate2.next(mindate2);
  }
  setReqFromProdMinDate2(mindate2: any) {
    this.reqFromProdMinDate2.next(mindate2);
  }
  setReqToMoMinDate2(mindate2: any) {
    this.reqToMoMinDate2.next(mindate2);
  }
  setSoMoMinDate2(mindate2: any) {
    this.soMoMinDate2.next(mindate2);
  }
  getIsCompanyExpired(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/isCompanyExpired`);
  }

  getFacilityById(facilityId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getFacility/${facilityId}`);
  }

  setFacilitySelect(isfacilitySelected: boolean) {
    this.isFacilitySelected.next(isfacilitySelected);
  }

  isUnreadNotification(unreadNotificationFlag: boolean) {
    this.isUnreadNotificationAvailable.next(unreadNotificationFlag);
  }

  checkDuplicateEmailId(emailId: any): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/checkDuplicateEmailId?email=${emailId}`);
  }
  getCompanyStatusMessage(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/companyStatusMessage`);
  }

  setCompanyUpgrade(companyUpgradeFlag: boolean) {
    this.isCompanyUpgraded.next(companyUpgradeFlag);
  }
  getZohoStateCode(stateCountry: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/getZohoStateCode`, stateCountry);
  }

  setNotificationAdded(noticationFlag: boolean) {
    this.isNotificationAdded.next(noticationFlag);
  }
  getNotificationAdded(): Observable<any> {
    // this.isNotificationAdded.next(noticationFlag);
    return this.isNotificationAdded.asObservable();
  }

  getCompanyUpgrade(): Observable<any> {

    return this.isCompanyUpgraded.asObservable();
  }
  getUnreadNotification(): Observable<any> {
    return this.isUnreadNotificationAvailable.asObservable();
  }
  getFacilitySelect(): Observable<any> {
    return this.isFacilitySelected.asObservable();
  }

  getCurrentUser(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }

  addHostedPageSubscription(plan: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/addHostedPageSubscription`, plan);
  }

  moduleListByUserPk(pk): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/role/moduleListByUserPk?userPk=${pk}`)
  }

  subModuleListAutomatic(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/subModuleListAutomatic`);
  }

  setAccessToken(accessToken: any) {
    console.log(accessToken);
    localStorage.setItem('accessToken', JSON.stringify(accessToken));
    this.accessTokenSubject.next(accessToken);
  }

  public get accessTokenValue() {
    return this.accessTokenSubject.value;
  }

  authenticationToken(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/refreshSessionToken`, user).pipe(map((auth) => {
      if (auth.data != null) {
        this.setAccessToken(auth.data);
        //this.startRefreshTokenTimer(auth.data.expires_in);
      }

      return auth;
    }));
  }

  authenticationRefreshToken(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/refreshSessionToken`, user);
  }


  private refreshTokenTimeout;
  public authToken: any = {};

  public expireTime = 0;
  public bufferMinutes = 0;
  public timeOutTime = 0;
  public startRefreshTokenTimer(expireIn: number) {

    this.expireTime = expireIn * 1 * 1000;
    this.bufferMinutes = 60 * 1 * 1000;
    this.timeOutTime = this.expireTime - this.bufferMinutes;

    var ref = this;
    this.refreshTokenTimeout = setInterval(() => {
      console.log("token time ", this.timeOutTime);

      let loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
      let token = JSON.parse(localStorage.getItem("accessToken"));
      let userDetail: any = {
        "userId": loggedInUser.userId,
        "password": loggedInUser.password,
        "refreshToken": token.refresh_token
      };

      ref.authenticationRefreshToken(userDetail).subscribe((data) => {
        ref.authToken = data.data;
        if (data.data != null) {
          ref.setAccessToken(ref.authToken);
        }

      });
    }, this.timeOutTime);

  }

  checkFormRestrictions(formTypeId: any): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/formRestrictionsCheck?formId=${formTypeId}`);
  }


  private stopRefreshTokenTimer() {
    clearInterval(this.refreshTokenTimeout);
  }


  setIsAuthCalledFromFy(flag: boolean) {
    this.isAuthCalledFromFy.next(flag);
  }

  public getIsAuthCalledFromFy() {
    return this.isAuthCalledFromFy.asObservable();;
  }

  handleUnauthorized(req: HttpRequest<any>, next: HttpHandler, user: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/refreshSessionToken`, user).pipe(
      map((auth) => {
        if (auth.data != null) {
          this.setAccessToken(auth.data);
          console.log("handleUnauthorized Service Function", auth);
          return auth;
        }


      }));
  }

  getTaxesForExtraCharges(extraChargeId: number, customerId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/master/getTaxesForExtraCharge?extraChargeId=${extraChargeId}&customerId=${customerId}`);
  }

  getTaxesForExtraCharges1(extraChargeId: number, vendorId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/master/getTaxesForExtraCharge?extraChargeId=${extraChargeId}&vendorId=${vendorId}`);
  }

  getTaxesForExtraCharges2(extraChargeId: number, otherFacilityId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/master/getTaxesForExtraCharge?extraChargeId=${extraChargeId}&otherFacilityId=${otherFacilityId}`);
  }

  getReportUrl(formTypeId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getReportUrl/${formTypeId}`);
  }

  getModulesByModuleGroup(pk: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getModulesByModuleGroup?pk=${pk}`);
  }

  setUpgradeAccess(isUpgradeAccess: any) {
    localStorage.setItem('isUpgradeAccess', JSON.stringify(isUpgradeAccess));
    this.upgradeAccessSubject.next(isUpgradeAccess);

  }

  setUpgradeView(isViewAccess: any) {
    localStorage.setItem('isViewAccess', JSON.stringify(isViewAccess));
    this.upgradeViewSubject.next(isViewAccess);

  }
  getUpgradeAccess(): Observable<any> {
    return this.upgradeAccessSubject.asObservable();
  }

  getUpgradeViewAccess(): Observable<any> {
    return this.upgradeViewSubject.asObservable();
  }

  getDefaultReportUrl(formTypeId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getInUseReportUrl/${formTypeId}`);
  }

  getMailTemplateWithFilledData(orderData: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/notification/mail/getMailTemplateWithFilledData`, orderData);
  }

  sendMailUsingTemplate(mailData: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/notification/mail/sendMailUsingTemplate`, mailData);
  }

  updateUserSession(userId: any, companyCode: any): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/updateUserSession?userId=${userId}&companyCode=${companyCode}`);
  }
  getDecimalPlaceAllowed(applicableModule: any): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getDecimalPlaceAllowed?applicableModule=${applicableModule}`);
  }
  getFilesApiByMultiForms(formTypeId, itemIds) {
    let reqParam = { "formTypeId": formTypeId, "formPks": itemIds };
    return this.http.get<any>(this.baseurl + "/common/document/getFilesApiByMultiForms", { params: reqParam });
  }

  getDecimalPlaceAllowedForRatesAndDiscount(applicableModule: any): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getDecimalPlaceAllowedRatesAndDiscount?applicableModule=${applicableModule}`);
  }

  setSubmitErrorFlag(flag: boolean) {
    this.submitErrorFlag.next(flag);
  }
  getSalesOrderItemDetailsByBarCode(customerId: number, barcode: any): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/inventory/getSalesOrderItemDetailsByBarCode?customerId=${customerId}&barCode=${barcode}`);
  }
  checkItemBarcodeDuplicate(barcode: any): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/master/checkItemBarcodeDuplicate?barcode=${barcode}`);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  setDealerFlag(dealerFlag: boolean) {
    localStorage.setItem('dealerFlag', JSON.stringify(dealerFlag));
    this.dealerFlagSubject$.next(dealerFlag);
  }

  public get dealerFlagValue() {
    return this.dealerFlagSubject$.value;
  }

  setDealerData(dealerData: any) {
    localStorage.setItem('dealerData', JSON.stringify(dealerData));
    this.dealerDataSubject$.next(dealerData);
  }

  public get dealerDataValue() {
    return this.dealerDataSubject$.value;
  }
  getDealerFlag(): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/master/getDealerFlag`)
  }
  getTaxesInventoryPreview(apiPath: TaxApiModel, id: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/${apiPath?.apiModule}/${apiPath?.apiPath}?${apiPath.queryParamName}=${id}`)
  }
}
