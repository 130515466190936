<div class="container-fluid justify-content-center align-items-center">
  <form [hidden]="!planFlag1" (ngSubmit)="onSubmit()" [formGroup]="userSignupForm" class="registerContainer"
    autocomplete='disabled' novalidate>

    <div class="row">
      <div class="col-sm-6 sign-up-bg">
        <div class="erp-signup-logo">
          <img class="img-responsive erp-logo" src="../../assets/image/sigup-bg-image.png">
        </div>

        <div class="erp-signup-text">
          <h1>Free Trial</h1>
        </div>

        <div class="erp-signup-footer-image">
          <img class="img-responsive erp-logo" src="../../assets/image/machine.jpg">
        </div>

      </div>
      <div class="col-sm-6 signup-form">
        <div>
          <h3 class="alignCentre">Sign Up! Create Your Account</h3>
          <fieldset [disabled]="otpGenerateFlag">
            <div class="form-group">
              <div class="row justify-content-center align-items-center">
                <div class="col-sm-3">
                  <label>Company Name</label>
                </div>
                <div class="col-sm-9">
                  <div class="e-float-input">
                    <input class="form-control" type="text" (focusout)="validationUpdate()"
                      placeholder="Enter Company Name" formControlName="companyName"
                      [ngClass]="{'is-invalid': (f['companyName'].touched || f['companyName'].dirty) && !f['companyName'].valid || (submitted && !f['companyName'].valid) }"
                      autocomplete='disabled'>
                    <span class="e-float-line"></span>
                    <div class="invalid-feedback">
                      <div *ngIf="f['companyName'].hasError('required')">Company Name is required.</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row justify-content-center align-items-center">
                <div class="col-sm-3">
                  <label>Domain Name</label>
                </div>
                <div class="col-sm-9">
                  <div class="e-float-input">
                    <input class="form-control domain-name-control" placeholder="Enter Domain Name"
                      formControlName="domainName" type="text" (keyup)="textTransformInLowercase()"
                      (focusout)="checkDuplicateCompanyCode()" (keyup.enter)="checkDuplicateCompanyCode()"
                      [ngClass]="{'is-invalid': (f['domainName'].touched || f['domainName'].dirty) && !f['domainName'].valid || (submitted && !f['domainName'].valid) }"
                      autocomplete='disabled'>
                    <span class="e-float-line"></span>
                    <!--
                        <i [routerLink]="['/demo']" class="fa fa-info-circle fa-2x domainIcon"
                        aria-hidden="true"></i>
                      -->
                    <div class="invalid-feedback">
                      <div *ngIf="f['domainName'].hasError('required')">Domain Name is required</div>
                      <div *ngIf="f['domainName'].hasError('minlength')">Domain name should be minimum 3 characters.</div>
                      <div *ngIf="f['domainName'].hasError('maxlength')">Domain name should be maximum 20 characters.</div>
                      <div *ngIf="f['domainName'].hasError('invalidDomainName')">Special character and space are not allowed
                      </div>
                    </div>
                    <div>
                      <small style="color: red;" *ngIf="!duplicateDomainFlag">
                        {{domainNameMessage}}, please try another
                      </small>
                      <small style="color: green;" *ngIf="duplicateDomainFlag">
                        {{domainNameMessage}}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3">
                  <label>Contact Person</label>
                </div>
                <div class="col-sm-9">
                  <div class="e-float-input">
                    <input class="form-control" placeholder="Contact Person" formControlName="contactPerson" type="text"
                      [ngClass]="{'is-invalid': (f['contactPerson'].touched || f['contactPerson'].dirty) && !f['contactPerson'].valid || (submitted && !f['contactPerson'].valid) }"
                      autocomplete='disabled'>
                    <span class="e-float-line"></span>
                    <div class="invalid-feedback">
                      <div *ngIf="f['contactPerson'].hasError('required')">contact Person is required</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-3 label">
                  <label>Country</label>
                </div>
                <div class="col-sm-9">

                  <ejs-dropdownlist id='ddlelement' #samples [dataSource]='countries' [fields]='countryFields'
                    class="form-control" [placeholder]='placeholder1' [allowFiltering]='true'
                    formControlName="countryId" (change)='onCountryChanged($event)' (click)="setAttribute()"
                    [enabled]="!otpGenerateFlag">
                  </ejs-dropdownlist>
                  <div ngIf="f['countryId'].errors" * class="invalid-feedback">
                    <div *ngIf="f['countryId'].hasError('required')">Country is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" [hidden]="!countryIndiaFlag">
              <div class="row">
                <div class="col-sm-3 label">
                  <label>State</label>
                </div>
                <div class="col-sm-9">

                  <ejs-dropdownlist id='ddlelement' #samples [dataSource]='states' formControlName="stateId"
                    [fields]='stateFields' class="form-control" [placeholder]='placeholder2' [allowFiltering]='true'
                    (change)="onCountryStateChanged($event)" (click)="setAttribute()" [enabled]="!otpGenerateFlag">
                  </ejs-dropdownlist>

                  <div ngIf="f['stateId'].errors" * class="invalid-feedback">
                    <div *ngIf="f['stateId'].hasError('required')">State is required</div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-3">
                <label>Work Email*</label>
              </div>
              <div class="col-sm-9">
                <div class="e-float-input">
                  <input class="form-control" placeholder="Email Id" formControlName="companyEmail" type="text"
                    (keyup)="checkDuplicateEmailId()"
                    [ngClass]="{'is-invalid': (f['companyEmail'].touched || f['companyEmail'].dirty) && !f['companyEmail'].valid || (submitted && !f['companyEmail'].valid) }"
                    autocomplete='disabled'>
                  <span class="e-float-line"></span>
                  <div class="invalid-feedback">
                    <div *ngIf="f['companyEmail'].hasError('required')"> Email is required</div>
                    <div *ngIf="f['companyEmail'].hasError('invalidEmail')"> Please enter valid Email</div>
                    <div *ngIf="f['companyEmail'].hasError('duplicateEmail')">Email ID already exists.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm-3">
                <label>Mobile No*</label>
              </div>
              <div class="col-sm-2 country-code">
                <ejs-dropdownlist class="countrycode-dropdown" id='ddlelement' #samples [dataSource]='countries'
                  [fields]='countryCodeFields' class="form-control" [placeholder]='placeholderCountryCode'
                  [allowFiltering]='true' formControlName="countryCode" (click)="setAttribute()">
                </ejs-dropdownlist>
                <div ngIf="f['countryCode'].errors" * class="invalid-feedback">
                  <div *ngIf="f['countryCode'].hasError('required')">Country code is required</div>
                </div>

                <!-- <div class="e-float-input">
                  <input class="form-control" placeholder="" readonly formControlName="countryCode" type="text"
                    [ngClass]="{'is-invalid': (f['countryCode'].touched || f['countryCode'].dirty) && !f['countryCode'].valid || (submitted && !f['countryCode'].valid) }"
                    autocomplete='disabled'>
                  <span class="e-float-line"></span>
                </div> -->
              </div>

              <div class="col-sm-4">
                <div class="e-float-input">
                  <input class="form-control" placeholder=" Enter Mobile No." formControlName="contactMobile"
                    type="text"
                    [ngClass]="{'is-invalid': (f['contactMobile'].touched || f['contactMobile'].dirty) && !f['contactMobile'].valid || (submitted && !f['contactMobile'].valid) }"
                    autocomplete='disabled'>
                  <span class="e-float-line"></span>
                  <!-- <small style="color: red;" *ngIf="userSignupForm.controls['contactMobile'].invalid">
                    Please enter valid mobile no.
                  </small> -->

                  <div class="invalid-feedback">
                    <div *ngIf="f['contactMobile'].hasError('required')"> Mobile No. is required</div>
                    <div *ngIf="f['contactMobile'].hasError('invalidNumber')"> Please enter valid mobile no.</div>
                  </div>
                  <div *ngIf="isMobileNoVarify && isEmailVarify && isOTP" style="color: green;"><small>Otp has sent on
                      your mobile and email</small> </div>
                  <div *ngIf="!isMobileNoVarify || !isEmailVarify" style="color: red;">
                    <small>{{emailPhoneNoVerifyMsg}}</small>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 resend-otp">
                <button ejs-button cssClass=""
                  [disabled]="f['companyEmail'].errors!=null || f['countryCode'].errors!=null || f['contactMobile'].errors!=null"
                  class="btnResend button_padding button_margin" (click)="getMobileOtp()" type="button">Generate -
                  <br />Resend OTP</button>
              </div>

            </div>
          </div>



          <fieldset [disabled]="!otpGenerateFlag">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3">
                  <label>Enter OTP</label>
                </div>
                <div class="col-sm-9">
                  <div class="e-float-input">
                    <input class="form-control" placeholder="Enter OTP" formControlName="otp" type="text"
                      [readonly]="!otpGenerateFlag"
                      [ngClass]="{'is-invalid': (f['otp'].touched || f['otp'].dirty) && !f['otp'].valid || (submitted && !f['otp'].valid) }"
                      (keyup)="onVerify()" autocomplete='disabled'>
                    <span class="e-float-line"></span>
                    <div class="invalid-feedback">
                      <div *ngIf="f['otp'].hasError('required')"> Otp is required</div>

                    </div>
                    <div *ngIf="!f['otp'].hasError('required') && isOTP && !isVerified " style="color: red;"><small>Please
                        enter
                        correct OTP</small></div>
                  </div>
                </div>

              </div>
            </div>
          </fieldset>

          <fieldset [disabled]="!isVerified">
            <div class="form-group">
              <div class="row">
                <div class="col-sm-3">
                  <label>Industry Type</label>
                </div>
                <div class="col-sm-9">


                  <ejs-dropdownlist id='ddlelement' #samples [dataSource]='industryTypeList' [fields]='industryFields'
                    class="form-control" [placeholder]='placeholderIndustry' [allowFiltering]='true'
                    formControlName="industryPk"
                    [ngClass]="{'is-invalid': (f['industryPk'].touched || f['industryPk'].dirty) && !f['industryPk'].valid || (submitted && !f['industryPk'].valid) }"
                    [enabled]="isVerified">
                  </ejs-dropdownlist>

                  <div class="invalid-feedback">
                    <div *ngIf="f['industryPk'].hasError('required')"> Mobile No. is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col-sm-9">

                  <label><input type="checkbox" class="turmsandcondition-checkbox confirmterm-condition"
                      formControlName="isTurmsandCondistions"
                      (ngModelChange)="istrmAndCondition(userSignupForm.value.isTurmsandCondistions);">I
                    agree to Terms & Conditions and Privacy Policy</label>
                  <div class="invalid-feedback">
                    <div *ngIf="f['isTurmsandCondistions'].hasError('required')">Please select checkbox</div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="form-group">
            <div class="row">
              <div class="col-sm-3">

              </div>
              <div class="col-sm-9" style="text-align: center;">
                <button type="submit"
                  [disabled]="!userSignupForm.valid || !isChecked || !isVerified || !isMobileNoVarify || !isEmailVarify"
                  ejs-button cssClass="" class="get-start button_padding button_margin">Get
                  Started</button>
              </div>
            </div>

          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-sm-3">

              </div>
              <div class="col-sm-9" style="text-align: center;">
                <button type="button" ejs-button cssClass="" class="already-account button_padding button_margin"
                  (click)="alreadyAccountLogin()">
                  Already have an Account? Sign In</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </form>

  <form [hidden]="!planFlag2" class="" style="background: #eef4f7;" novalidate>
    <div>
      <h3 class="alignCentre">Welcome<br />{{userSignupData.companyName}}</h3>



      <div class="form-group" >
        <div class="row justify-content-center align-items-center">
          <div class="col-sm-12">
            <div class="pricing-table-frequency clearfix">



              <ul class="navs">
                <ng-container *ngFor="let group of group_options;let index=index">

                  <li class="nav-item" (click)="displayProperPlan(group.plan_type)"
                   ><a class="nav-link frequency" name="1_months"
                      [ngClass]="{'active': activeFlag==group.plan_type}">{{group.frequency}}</a>
                  </li>

                </ng-container>


              </ul>

            </div>
          </div>

        </div>
      </div>


      <div class="form-group">
        <div class="row justify-content-center">
          <ng-container *ngFor="let plan of selectedPlans;let index=index">
            <div class="col-md-3 col-md-offset-1 plan-list-dynamic">
              <div class="card plan-body">
                <!-- <div *ngIf="index==0" class="recommended-plan">Recommended</div> -->
                <div class="warehouse-icon" *ngIf="isManufacturing(plan)"><img [src]="warehouseIcon" class="warehouse">
                </div>
                <div class="factory-icon" *ngIf="!isManufacturing(plan)"> <img [src]="factoryIcon" class="factory">
                </div>
                <div class="card-header plan-name-header">
                  {{plan.name}}

                </div>
                <div class="horizontal-block">
                  <hr class="horizontal-line" />
                </div>

                <div class="card-body price-body">
                  <div class="plan-name-body">{{currencyFormat+" "+plan.recurring_price}}</div>
                  <div class="inr-perunit">{{plan.unit}}</div>
                  <div class="billing-plan"> {{billingPlan}} </div>
                  <!-- <div class="inr-perunit">{{plan.unit}}</div>
                  <div class="billing-plan"> {{billingPlan}} </div> -->

                  <!--  <div class="optional">Optional add on</div>

                  <div class="order-processing">
                    <ng-container *ngFor="let addon of plan.addons;let i=index">
                      <label *ngIf="addon.status == 'active'">
                        <input type="checkbox" class="turmsandcondition-checkbox" [(ngModel)]="addon.isSelected"
                          [ngModelOptions]="{standalone: true}">{{addon.name}}</label>
                    </ng-container>

                  </div> -->
                </div>
                <div class="card-header select-header">
                  <button type="button" ejs-button cssClass="" class="select-plan button_padding"
                    (click)="selectPlan(plan)">Select</button>

                </div>
                <div class="card-body feature-body">
                  <div class="feature" *ngIf="plan.features.length>0">
                    <ul class="feature-list">
                      <ng-container *ngFor="let feature of plan.features;let i=index">
                        <li class="item-list"> {{feature}}</li>
                      </ng-container>

                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </ng-container>
        </div>

      </div>

    </div>

    <div class="row">
      <div class="col-sm-12 plan-form-footer">
        <button type="button" (click)="back()" ejs-button cssClass=""
          class="proceed-back button_padding button_margin buttonSize"><i class="fa fa-arrow-left"></i>
          back</button>
      </div>
    </div>
  </form>

  <form [hidden]="!planFlag3" class="" novalidate>
    <div class="container justify-content-center align-items-center">
      <div>
        <h3 class="alignCentre">Welcome<br />{{userSignupData.companyName}}</h3>
        <div class="form-group">
          <div class="row justify-content-center align-items-center">
            <div class="col-sm-12">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Plan Name</th>
                    <th>No of months</th>
                    <th>Plan Rate/User/Month</th>
                    <th>No Of User</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{selectedPlan.name}}</td>
                    <td>{{selectedPlan.interval}}</td>
                    <td>{{selectedPlan.recurring_price| number : '1.2-2'}}</td>
                    <td><input type="text" name="noOfUser" [(ngModel)]="selectedPlan.quantity" class="form-control"
                        [ngModelOptions]="{standalone: true}" (keyup)="calculateAmount(selectedPlan.quantity)"></td>
                    <td>{{selectedPlan.amount| number : '1.2-2'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

        <div class="form-group">
          <div class="row justify-content-center align-items-center">
            <div class="col-sm-12">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Add On name</th>
                    <th>No of months</th>
                    <th>Add on Rate</th>
                    <th *ngIf="addOnQtyFieldFlag">No Of Users/Qty</th>

                    <th>Choose</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let addOn of selectedPlan.addons;let index=index">
                    <tr *ngIf="addOn.status=='active'">
                      <td>{{addOn.name}}</td>
                      <td><span *ngIf="addOn.type!='one_time'">{{selectedPlan.interval}}</span></td>
                      <td>{{addOn.price| number : '1.2-2'}}</td>
                      <td *ngIf="addOnQtyFieldFlag">
                        <p *ngIf="addOn.type=='one_time'">
                          <input type="text"
                            [ngClass]="{'is-invalid':(addOn.type=='one_time') && (addOn.isSelected) && (addOn.quantity<=0 || addOn.quantity=='')}"
                            (keyup)="calculateSelectedAddOnsAmount();addOnQtyvalidation(addOn)"
                            [disabled]="!addOn.isSelected" class="form-control user-qty-size"
                            [(ngModel)]="addOn.quantity" [ngModelOptions]="{standalone: true}">
                        </p>


                      </td>

                      <td><label><input type="checkbox" class="turmsandcondition-checkbox"
                            [(ngModel)]="addOn.isSelected"
                            (change)="calculateSelectedAddOnsAmount();addOnQtyvalidation(addOn)"
                            [ngModelOptions]="{standalone: true}"></label></td>
                      <td>{{addOn.amount| number : '1.2-2'}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </div>
        </div>

        <div class="form-group">
          <div class="row justify-content-center align-items-center">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <table class="table table-bordered calculated-charged">

                <tbody>
                  <tr>
                    <td class="align-right bold-text">Setup Fee </td>
                    <td class="align-right bold-text">{{selectedPlan.setup_fee | number : '1.2-2'}}</td>

                  </tr>
                  <tr>
                    <td class="align-right bold-text">Sub-Total</td>
                    <td class="align-right bold-text">{{subTotal | number : '1.2-2'}}</td>

                  </tr>
                  <ng-container *ngFor="let tax of selectedPlan.item_tax_preferences;let index=index">
                    <tr *ngIf="tax.isActive">
                      <td class="align-right bold-text">{{tax.tax_name }} ({{tax.tax_percentage}}%)</td>
                      <td class="align-right bold-text">{{((subTotal*tax.tax_percentage)/100) | number : '1.2-2'}}
                      </td>

                    </tr>
                  </ng-container>
                  <tr>
                    <td class="align-right bold-text">Grand Total </td>
                    <td class="align-right bold-text">{{grandTotal | number : '1.2-2'}}</td>

                  </tr>
                </tbody>
              </table>

              <div class="row">
                <div class="col-sm-6">

                </div>

                <div class="col-sm-6" style="text-align: right;">
                  <!--  <button type="button" (click)="editCompany()" ejs-button cssClass=""
                          class="edit button_padding button_margin">Edit Company Info</button> -->
                </div>

              </div>

            </div>


          </div>
        </div>


      </div>

      <div>
        <div class="row">
          <div class="col-sm-12 plan-form-footer">
            <button [disabled]="!addonFlag" type="button" (click)="createSubscription()" ejs-button cssClass=""
              class="update button_padding button_margin" style="height: 45px !important; font-weight: 600;">
              Confirm & Proceed
            </button>

            <button type="button" (click)="back2()" ejs-button cssClass=""
              class="proceed-back button_padding button_margin buttonSize"><i class="fa fa-arrow-left"></i>
              back</button>
          </div>
        </div>
      </div>
    </div>



  </form>
</div>

<ngx-ui-loader></ngx-ui-loader>
