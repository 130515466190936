import { Component, OnInit } from '@angular/core';
import { User } from '../modules/core/model/model';
import { DataService } from '../dataservices/dataservice.service';
import { Menu } from '../modules/navbar/models/menu';

import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { Router, ActivatedRoute, Params } from '@angular/router'
import { CommonVariables } from '../commonVariables';
import { HeaderComponent } from '../header/header.component';
import { Observable } from 'rxjs';
import { Observer } from '@syncfusion/ej2-base';
//import { validateConfig } from '@angular/router/src/config';

@Component({
  selector: 'app-asidebar',
  templateUrl: './asidebar.component.html',
  styleUrls: ['./asidebar.component.css']
})
export class AsidebarComponent implements OnInit {
  menus: Menu[] = [];
  currentUser: User;
  users: User[] = [];
  message: any = {};
  facility: any;
  financialYear: any;
  moduleAction: Observable<any>;
  public menuId: number = 0;
  public topmsgFlag: boolean = false;
  constructor(private router: Router, private dataservice: DataService,
    private globalService: GlobalServiceService,
    private route: ActivatedRoute) {

    this.globalService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    this.dataservice.getAllMenu(this.currentUser.pk).subscribe(
      (data: any) => {
        this.menus = data.data;
        this.menuId = CommonVariables.getVariavbleValue();
      },
      (err: any) => {
        console.log(err);
      }
    );

    var ref = this;
    this.router.events.subscribe(val => {
      if (val != undefined && val['snapshot'] != undefined && val['snapshot']['params'] != undefined && val['snapshot']['params']['id'] != undefined) {
        ref.menuId = val['snapshot']['params']['id'];
      }


    });


  }

  navigateToModule(menu) {
    this.facility = JSON.parse(sessionStorage.getItem('facility'));
    this.financialYear = JSON.parse(sessionStorage.getItem('financialYear'));
    if (this.financialYear == undefined || this.financialYear == null) {
      this.router.navigateByUrl(`/financialYear/${this.currentUser.pk}`);
    } else if (this.facility == undefined || this.facility == null) {
      this.router.navigateByUrl(`/facility/${this.currentUser.pk}`);
    } else {
      if (!!menu.moduleAction && menu.moduleAction.toLowerCase() === 'inventory') {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}/inv-overview`);
      }
      else if (!!menu.moduleAction && menu.moduleAction.toLowerCase() === 'settings') {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}`);
      }
      else if (!!menu.moduleAction && menu.moduleAction.toLowerCase() === 'manufacturing') {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}/mfrg-overview`);
      }
      else if (!!menu.moduleAction && menu.moduleAction.toLowerCase() === 'order-processing') {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}/processing-overview`);
      }
      else {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}`);
      }
    }
  }


  selectMenu(event, menu) {
    this.menuId = menu.pk;

  }
  setFlag() {
    console.log("Flag sideBar", this.topmsgFlag);
  }

  getAllMenuByUser() {
    this.dataservice.getAllMenu(this.currentUser.pk).subscribe(
      (data: any) => {
        this.menus = data.data;
        this.menuId = CommonVariables.getVariavbleValue();

      },
      (err: any) => {
        console.log(err);
      }
    );
  }

}
