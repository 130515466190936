import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common'
import { AggregateService, DetailRowService, ExcelExportService, FilterService, GridComponent, PageService, PdfExportService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { UploadedFileDetail } from '../modules/purchase-order/models/uploadedFileDetail';
import { AppComponent } from '../app.component';
import { ConfirmationDialogService } from '../confirmation-dailog/confirmation-dialog.service';
import { UpladServicesService } from '../modules/shared/sharedServices/uplad-services.service';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { PoFullfilmentService } from '../modules/po-fullfilment-date/po-fullfilment.service';
import { CommonVariables } from '../commonVariables';
import { ModalMultipleFileuploadPopupComponent } from '../modules/shared/modal-multiple-fileupload-popup/modal-multiple-fileupload-popup.component';
import { GlobalTmpUrlBaseService } from '../modules/core/services/global-tmp-url-base.service';

@Component({
  selector: 'app-po-fullfilment-po-details',
  templateUrl: './po-fullfilment-po-details.component.html',
  styleUrls: ['./po-fullfilment-po-details.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [GlobalTmpUrlBaseService, PageService, SortService, DetailRowService, FilterService,
    ToolbarService, ExcelExportService, PdfExportService, AggregateService],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PoFullfilmentPoDetailsComponent implements OnInit {


  poId: any;
  poFullfilmentData: any = {};
  poFulfillmentDates: any[] = [];

  @ViewChild('grid', {static: true})
  public grid: GridComponent;

  public minDate;
  public maxDate;
  public minDate2: Date;
  poFullfilmentForm: FormGroup;
  public fyStartDate: any;
  public fyEndDate: any;
  currentUser: any = {};
  serverDate: any;
  datePipe = new DatePipe('en-US');
  fullfilmentId: number = 0;
  /** multiple variables */
  uploadedFilesDetails: any[] = [];
  savedfileDetails: UploadedFileDetail[];
  dataRes: any = {};
  orderAmount: any;
  /** multiple variables ends */
  submitted: boolean = false;
  isUploadDocAccess: boolean = false;
  isEditAccess: boolean = false;
  isAddAccess: boolean = false;
  isDeleteAccess: boolean = false;
  isApproveAccess: boolean = false;
  isCancelAccess: boolean = false;
  isPrintAccess: boolean = false;

  constructor(public appComp: AppComponent, private route: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router, private confirmationDialogService: ConfirmationDialogService,
    private formBuilder: FormBuilder, private location: Location,
    private globalService: GlobalServiceService,
    private uploadService: UpladServicesService, private ngxLoader: NgxUiLoaderService,
    private poFullfilmentService: PoFullfilmentService,
    private globalTmpUrlBasedService: GlobalTmpUrlBaseService) {
    this.minDate = new Date();
    /*  let financialYear: any = JSON.parse(sessionStorage.getItem('financialYear'))
     this.minDate = this.getTransformDate(financialYear.startDate);
     this.maxDate = this.getTransformDate(financialYear.endDate);
     this.fyStartDate = CommonVariables.convertStrToDate(financialYear.startDate);
     this.fyEndDate = CommonVariables.convertStrToDate(financialYear.endDate);
     this.currentUser = JSON.parse(localStorage.getItem('currentUser')); */

  }

  get f() { return this.poFullfilmentForm.controls; }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        let id = params.get('id').toString();
        this.poId = id;
        this.getServerDate();
        if (this.poId == 0) {

        } else {

          console.log("PoId test ", this.poId);
          this.getPoFullfilmentDetailById(this.poId);
        }

      });

    this.poFullfilmentForm = this.formBuilder.group({
      fulfillmentDate: ['', [Validators.required]],
      comments: ['']
    });

    /*  this.route.parent.parent.paramMap.subscribe(
       params => {
         const modulePk = +params.get('id');
         const actionName = "po-fulfillment-date"
         this.getButtonAccess(modulePk, actionName);
       }
     ); */


  }

  onSubmit() {
    this.ngxLoader.start();
    this.submitted = true;
    if (!this.poFullfilmentForm.valid) {

      this.ngxLoader.stop();
      return false;
    }

    let formData = this.poFullfilmentForm.value;

    if (formData.fulfillmentDate != undefined && formData.fulfillmentDate != null && formData.fulfillmentDate != '') {
      if (typeof formData.fulfillmentDate === 'string') {
        if (formData.fulfillmentDate == this.serverDate) {
          formData.fulfillmentDate = this.serverDate;
        }
        else {
          formData.fulfillmentDate = formData.fulfillmentDate;
        }
      } else {
        formData.fulfillmentDate = this.datePipe.transform(formData.fulfillmentDate, 'dd/MM/yyyy');
      }
    }



    formData['poId'] = this.poId;
    this.globalTmpUrlBasedService.tmpCreatePurchaseOrderFulfillmentDate(formData).subscribe((data) => {
      this.ngxLoader.stop();
      console.log("responseData ", data);
      this.fullfilmentId = data.data;
      this.submitted = false;
      this.dataRes = data;
      if (this.savedfileDetails != undefined && this.savedfileDetails != null) {
        this.savedfileDetails.forEach(element => {
          element.formTypeId = 102;
          element.fulfillmentFlag = this.poFullfilmentData.vendorName;
          element.pk = 0;
          element.fileOrder = 0;
          element.formPk = this.poId;
        });

        this.saveFileDetail(this.savedfileDetails);
      }

      this.navigateToPurchase();
    });

  }

  getPoFullfilmentDetailById(poId) {

    this.globalTmpUrlBasedService.getTmpPurchaseOrderFulfillmentDateById(poId).subscribe((data) => {
      if (data.data != null) {
        this.poFullfilmentData = data.data;
        console.log("pofullfilment data ", data);
        this.poFulfillmentDates = this.poFullfilmentData.purchaseOrderFulfillmentDateBean;
      }
    });
  }

  getTransformDate(date): string {
    let parts: any[] = date.split('/');
    return (`${parts[1]}/${parts[0]}/${parts[2]}`);
  }

  changeDate() {
    var ref = this;
    setTimeout(function () {
      console.log("sales order form ", ref.poFullfilmentForm.value.fulfillmentDate);
      let strDate = '';
      let strFlag = false;
      if (typeof ref.poFullfilmentForm.value.purchaseOrderDate === 'string') {
        console.log("date is string");
        strFlag = true;
        strDate = ref.getTransformDate(ref.poFullfilmentForm.value.fulfillmentDate);
      } else {
        let dd = CommonVariables.dateTransformForValidation(ref.poFullfilmentForm.value.fulfillmentDate);
        strDate = ref.getTransformDate(dd);
        console.log("date is not string");
        strFlag = false;
      }


    }, 500);
  }


  navigateToPurchase(): void {
    this.router.navigateByUrl(`/success-message-mail`);

  }
  getServerDate() {
    this.globalTmpUrlBasedService.getTmpServerDate().subscribe(data => {
      this.serverDate = data.data;
      this.poFullfilmentForm.patchValue({
        fulfillmentDate: this.serverDate
      });
    });
  }

  /** multiple File upload Modal */

  openMultipleFileUploadFormPopup() {
    const modalRef = this.modalService.open(ModalMultipleFileuploadPopupComponent, { size: 'xl' as 'lg' });

    modalRef.componentInstance.modalData = { "formPk": this.poId, "formTypeId": 102, fulfillmentFlag: this.poFullfilmentData.vendorName, isDeleteAccess: false };

    modalRef.componentInstance.itemData.subscribe((fileDetails) => {
      if (fileDetails == null || fileDetails.length == 0) {
        this.savedfileDetails = [{
          "description": '',
          "fulfillmentFlag": this.poFullfilmentData.vendorName,
          "extension": '',
          "fileKey": '',
          "fileName": '',
          "fileOrder": 0,
          "formPk": this.fullfilmentId,
          "formTypeId": 102,
          "pk": 0,
        }];
      } else {
        this.uploadedFilesDetails = fileDetails;
        this.savedfileDetails = fileDetails;
      }


      if (this.fullfilmentId != 0) {
        if (this.savedfileDetails != undefined && this.savedfileDetails != null) {
          this.savedfileDetails.forEach(element => {
            element.formTypeId = 102;
            element.fulfillmentFlag = this.poFullfilmentData.vendorName;
            element.pk = 0;
            element.fileOrder = 0;
            element.formPk = this.fullfilmentId;
          });

          this.saveFileDetail(this.savedfileDetails);

        }
      }
    });
  }


  saveFileDetail(fileDtails) {
    this.ngxLoader.start();
    this.globalTmpUrlBasedService.tmpSaveUploadedFileDtails(fileDtails).subscribe((data) => {
      this.ngxLoader.stop();
    }, (error) => {
      console.log(error);
      this.ngxLoader.stop();
    });
  }
  /** multiple File upload Modal ends */

  isEnable: boolean = false;
  editForm: boolean = false;
  edit() {
    this.isEnable = true;
    this.editForm = true;

  }

  getButtonAccess(modulePk, actionName) {
    this.ngxLoader.start();
    this.globalService.getButtonAccess(modulePk, actionName).subscribe(
      (data: any) => {
        this.ngxLoader.stop();
        this.globalService.setActionBean(data.data)
        var ref = this;
        setTimeout(function () {

          ref.isUploadDocAccess = JSON.parse(localStorage.getItem('isUploadDocAccess'));
          ref.isEditAccess = JSON.parse(localStorage.getItem('isEditAccess'));
          ref.isAddAccess = JSON.parse(localStorage.getItem('isAddAccess'));
          ref.isDeleteAccess = JSON.parse(localStorage.getItem('isDeleteAccess'));
          ref.isApproveAccess = JSON.parse(localStorage.getItem('isApproveAccess'));
          ref.isCancelAccess = JSON.parse(localStorage.getItem('isCancelAccess'));
          ref.isPrintAccess = JSON.parse(localStorage.getItem('isPrintAccess'));

        }, 1000);

      },
      (err: any) => {
        console.log(err);
        this.ngxLoader.stop();
      }

    );
  }
}
