import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { AsidebarComponent } from '../asidebar/asidebar.component';
import { MainContentComponent } from '../main-content/main-content.component';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { PaidPlanSubscribeService } from '../modules/subscription/paid-plan-subscribe.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public logo: any;
  sessionUser: any;
  companyName: string;
  public remainingDays: number = 0;
  public topmsgFlag: boolean = false;
  public planName: string = '';
  public planStatus: string = '';
  public topMessage: string = '';
  public currentUser: any = {};
  isFacilitySelectedFlag: boolean = false;
  isUnreadNotification: boolean = false;
  isCompanyUpgraded: boolean = false;
  public isCompanyExired: boolean = false;
  public authFlag: boolean = false;
  constructor(public appCom: AppComponent, private globalService: GlobalServiceService,
    private paidPlanService: PaidPlanSubscribeService, private route: ActivatedRoute, private router: Router) {
    let loggedInUser = JSON.parse(localStorage.getItem("currentUser"));
    let token = JSON.parse(localStorage.getItem("accessToken"));

    this.authFlag = this.appCom.isAuthSelected;
    this.globalService.getUnreadNotification().subscribe(value => {
      this.isUnreadNotification = value;
      if (!this.isUnreadNotification) {
        //this.topmsgFlag = false;
        //this.appCom.topmsgFlag = false;
        setTimeout(function () {
          $("#div1").fadeIn();
          $("#div2").fadeOut();
        }, 200);
        this.globalService.getCurrentUser().pipe(take(1)).subscribe(user => {
          if (user) {

            this.currentUser = user;
            this.companyName = user.companyName;
            this.logo = (user.companyLogoImage !== "") ? user.companyLogoImage : 'assets/image/null_logo.png';

            this.getCompanySubscribeDetails2(user.companyCode, this.isUnreadNotification);
          }
        });
      } else {

        this.globalService.getCurrentUser().pipe(take(1)).subscribe(user => {
          if (user) {

            this.globalService.getLatestNotication(user.pk, 1).subscribe((data) => {

              if (data.data != null && data.data.length != 0) {
                this.topmsgFlag = true;
                this.appCom.topmsgFlag = true;
                this.latestNotication = data.data[0];
                setTimeout(function () {
                  $("#div1").fadeOut();
                  $("#div2").fadeIn();
                }, 200);
              }
            }
            );
          }
        });



      }
      console.log("unread notification avalable ", this.isUnreadNotification);
    });

    this.globalService.getFacilitySelect().pipe(take(1)).subscribe(value => {
      this.isFacilitySelectedFlag = value;
      if (this.isFacilitySelectedFlag) {
        this.globalService.getCurrentUser().pipe(take(1)).subscribe(user => {
          if (user) {
            console.log("User is ", user);
            var ref = this;

            ref.globalService.getLatestNotication(user.pk, 1).subscribe((data) => {

              if (data.data != null && data.data.length != 0) {
                ref.topmsgFlag = true;
                ref.appCom.topmsgFlag = true;


                ref.latestNotication = data.data[0];
                console.log("data ", ref.latestNotication);
                ref.noticationFlag = true;

                if (!this.isUnreadNotification) {
                  this.topmsgFlag = false;
                  this.appCom.topmsgFlag = false;

                  this.globalService.currentUser.subscribe(user => {
                    if (user) {

                      this.currentUser = user;
                      this.companyName = user.companyName;
                      this.logo = (user.companyLogoImage !== "") ? user.companyLogoImage : 'assets/image/null_logo.png';

                      this.getCompanySubscribeDetails2(user.companyCode, this.isUnreadNotification);
                    }
                  });

                }

                setTimeout(function () {
                  $("#div1").fadeOut();
                  $("#div2").fadeIn();
                }, 100);



              }
            }
            );

          }

        });
      }

      console.log("facility selected ", value);
    });

    this.globalService.getCompanyUpgrade().pipe(take(1)).subscribe(value => {
      this.isCompanyUpgraded = value;
      if (this.isCompanyUpgraded == true) {
        this.isCompanyExpire();
        this.globalService.getCurrentUser().pipe(take(1)).subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.companyName = user.companyName;
            this.logo = (user.companyLogoImage !== "") ? user.companyLogoImage : 'assets/image/erp.png';

            this.getCompanySubscribeDetails(user.companyCode);
          }
        });
      }

      console.log("isCompanyUpgraded ", this.isCompanyUpgraded);
    });

    this.globalService.getNotificationAdded().subscribe(value => {
      let flag: boolean = false;

      flag = value;
      if (flag) {
        this.globalService.getCurrentUser().pipe(take(1)).subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.companyName = user.companyName;

            this.getLatestNotification(user.pk, 1, flag)
          }
        });


      }

    });

  }

  ngOnDestroy() {

    //this.globalService.isUnreadNotificationAvailable.unsubscribe();
    //this.globalService.isCompanyUpgraded.unsubscribe();
  }

  ngOnInit() {

    var ref = this;
    this.globalService.getCurrentUser().subscribe(user => {
      if (user != null) {

        this.currentUser = user;
        this.companyName = user.companyName;

      }

      if (user) {
        this.currentUser = user;
        this.companyName = user.companyName;
        this.logo = (user.companyLogoImage !== "") ? user.companyLogoImage : 'assets/image/null_logo.png';


        this.getCompanySubscribeDetails(user.companyCode);
      }
    });


    let token = JSON.parse(localStorage.getItem("accessToken"));
    //this.globalService.startRefreshTokenTimer(token.expires_in);

  }

  subscribedPlanDetails: any = {};
  public notificationFlag: boolean = false;
  getCompanySubscribeDetails(domainName: string) {
    this.topmsgFlag = false;
    this.appCom.topmsgFlag = false;
    var ref = this;
    $("#div1").fadeOut();
    $("#div2").fadeIn();

    this.paidPlanService.getCompanySubsciptionDetailsByDomainName(domainName).subscribe((data) => {
          console.log("subscribed Details mm", data);
          this.subscribedPlanDetails = data.data;


          let plan: any[] = [];
          if (this.subscribedPlanDetails.plan != null) {
            plan = this.subscribedPlanDetails.plan.split("#");
            console.log("current plan ", plan);
            this.planName = plan[1];
          } else {
            this.planName = "None";
          }

          this.planStatus = this.subscribedPlanDetails.subscriptionStatus;
          let notificationFlag: boolean = false;
          this.noticationFlag = false;

          this.topmsgFlag = false;
          this.appCom.topmsgFlag = false;
          setTimeout(function () {
            ref.remainingDays = ref.subscribedPlanDetails.trialRemainingDays;

            if (ref.remainingDays > 0 && ref.remainingDays <= 10 && ref.planStatus == "trial") {
              ref.topMessage = "Dear Sir . The current plan -<b>" + ref.planName + "</b> is expiring in <b>" + ref.remainingDays + "</b> days. You are requested to "
              notificationFlag = true;
              ref.noticationFlag = true;
              ref.topmsgFlag = true;
              ref.appCom.topmsgFlag = true;
              ref.appCom.setFlag();

            } else if (ref.remainingDays <= 0 && ref.planStatus == "trial") {
              ref.topMessage = "Dear Sir . The current plan -<b>" + ref.planName + "</b> is already expired. You are requested to "
              notificationFlag = true;
              ref.noticationFlag = true;
              ref.topmsgFlag = true;
              ref.appCom.topmsgFlag = true;

              ref.appCom.setFlag();
            } else {

            }


            if (ref.topMessage != '' && ref.topMessage != null) {
              if (ref.isUnreadNotification == true) {
                setInterval(function () {
                  console.log("Notification status5 ", ref.isUnreadNotification);
                  $("#div1").fadeIn();
                  $("#div2").fadeOut();
                  if (ref.isUnreadNotification == true) {
                    setTimeout(function () {
                      $("#div1").fadeOut("slow");
                      $("#div2").fadeIn("slow");
                    }, 15000);
                  }

                }, 60000);
              } else {
                setTimeout(function () {
                  console.log("Notification status6 ", ref.isUnreadNotification);
                  $("#div1").fadeIn("slow");
                  $("#div2").fadeOut("slow");

                }, 60000);
              }
            } else if ((ref.topMessage == '' || ref.topMessage == null) && !ref.isUnreadNotification) {
              ref.topmsgFlag = false;
              ref.appCom.topmsgFlag = false;
            }





          }, 10000);


        }
    
    );
  }

  getCompanySubscribeDetails2(domainName: string, isUnreadNotification: boolean) {

    var ref = this;
    if (isUnreadNotification == true) {
      $("#div1").fadeOut();
      $("#div2").fadeIn();
    } else {
      $("#div1").fadeIn();
      $("#div2").fadeOut();
    }

    this.paidPlanService.getCompanySubsciptionDetailsByDomainName(domainName).subscribe((data) => {
      console.log("subscribed Details mm", data);
      this.subscribedPlanDetails = data.data;
      let plan: any[] = [];
      if (this.subscribedPlanDetails.plan != null) {
        plan = this.subscribedPlanDetails.plan.split("#");
        console.log("current plan ", plan);
        this.planName = plan[1];
      } else {
        this.planName = "None";
      }


      this.planStatus = this.subscribedPlanDetails.subscriptionStatus;
      let notificationFlag: boolean = false;
      this.noticationFlag = false;
      setTimeout(function () {
        ref.remainingDays = ref.subscribedPlanDetails.trialRemainingDays;

        if (ref.remainingDays > 0 && ref.remainingDays <= 10 && ref.planStatus == "trial") {
          ref.topMessage = "Dear Sir . The current plan -<b>" + ref.planName + "</b> is expiring in <b>" + ref.remainingDays + "</b> days. You are requested to "
          notificationFlag = true;
          ref.noticationFlag = true;
          ref.topmsgFlag = true;
          ref.appCom.topmsgFlag = true;
          ref.appCom.setFlag();

        } else if (ref.remainingDays <= 0 && ref.planStatus == "trial") {
          ref.topMessage = "Dear Sir . The current plan -<b>" + ref.planName + "</b> is already expired. You are requested to "
          notificationFlag = true;
          ref.noticationFlag = true;
          ref.topmsgFlag = true;
          ref.appCom.topmsgFlag = true;

          ref.appCom.setFlag();
        } else {

        }


        if (ref.topMessage != '' && ref.topMessage != null) {
          console.log("Notification status0 ", isUnreadNotification);
          if (isUnreadNotification == true) {
            setInterval(function () {
              console.log("Notification status1 ", isUnreadNotification);
              $("#div1").fadeIn();
              $("#div2").fadeOut();
              if (isUnreadNotification == true) {
                setTimeout(function () {
                  $("#div1").fadeOut("slow");
                  $("#div2").fadeIn("slow");
                }, 15000);
              }


            }, 60000);
          } else {
            setTimeout(function () {
              console.log("Notification status2 ", isUnreadNotification);
              $("#div1").fadeIn("slow");
              $("#div2").fadeOut("slow");

            }, 60000);
          }
        } else if ((ref.topMessage == '' || ref.topMessage == null) && !isUnreadNotification) {
          ref.topmsgFlag = false;
          ref.appCom.topmsgFlag = false;
        }




      }, 10000);


    }, error => {
      console.log("Error -->", error);
    });
  }

  noticationFlag: boolean = false;
  latestNotication: any = {};
  getLatestNotification(userPK, count, flag) {
    var ref = this;
    setTimeout(function () {
      ref.globalService.getLatestNotication(userPK, count).subscribe((data) => {

        if (data.data != null && data.data.length != 0) {
          if (!flag) {
            ref.topmsgFlag = true;
            ref.appCom.topmsgFlag = true;
            setTimeout(function () {
              $("#div1").fadeOut();
              $("#div2").fadeIn();
            }, 100);
          } else {
            $("#div1").fadeOut("slow");
            $("#div2").fadeIn("slow");
          }
          ref.latestNotication = data.data[0];
          console.log("data", ref.latestNotication);
          ref.noticationFlag = true;
        }

      }, (error) => {
        console.log("error --> ", error);
      });
    }, 20000);
  }

  closeTopMsg() {
    this.topmsgFlag = false;
    this.appCom.topmsgFlag = false;
    this.appCom.setFlag();
  }
  gotToNotication(notification: any) {
    this.saveReadReceipt(notification.pk);
    this.router.navigate([`/settings/6/notifications/add-notification/${notification.pk}/1`]);
  }

  isCompanyExpire() {
    this.globalService.getIsCompanyExpired().subscribe((data) => {
      localStorage.setItem('isCompanyExpired', data.data);
      this.isCompanyExired = data.data;
      this.appCom.isCompanyExired = data.data;
    }, (error) => {
      console.log("Error -->", error);
    });
  }

  saveReadReceipt(notificationId): any {
    this.globalService.saveReadReceipt(notificationId).subscribe((data: any) => {
      if (data.statusCode == 200) {

      }
    },
      (err: any) => {
        console.log(err);
      });
  }

}
