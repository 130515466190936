<div class="container justify-content-center align-items-center">
  <div class="row justify-content-center align-items-center ">
    <div class="col-sm-12 ">
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="LoginContainer">
        <h2 class="loginTitle">Update User</h2>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group justify-content-center align-items-center e-float-input">
              <label for="userName">Username</label>

              <input type="text" formControlName="userName" class="form-control" placeholder="Please enter userName "
                [ngClass]="{ 'is-invalid': submitted && f['userName'].errors }" />
              <span class="e-float-line"></span>
              <!-- <div *ngIf="submitted && f['userName'].errors" class="invalid-feedback">
                <div *ngIf="f['userName'].hasError('required')">Username is required</div>
              </div> -->
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group justify-content-center align-items-center e-float-input">
              <label for="mobileNumber">Mobile No.</label>
              <input type="text" formControlName="mobileNumber" class="form-control"
                placeholder="Please enter mobile number "
                [ngClass]="{'is-invalid': (f['mobileNumber'].touched || f['mobileNumber'].dirty) && !f['mobileNumber'].valid || (submitted && !f['mobileNumber'].valid) }"
                pattern="[0-9]+" />
              <span class="e-float-line"></span>
              <!-- <div *ngIf="submitted && f['mobileNumber'].errors" class="invalid-feedback">
                <div *ngIf="f['mobileNumber'].hasError('required')">userId is required</div>
              </div>  -->
              <div class="invalid-feedback">
                <div *ngIf="f['mobileNumber'].hasError('required')">Mobile no. Number is required.</div>
                <div *ngIf="f['mobileNumber'].hasError('minlength')">Mobile no. should be minimum 8 characters.</div>
                <div *ngIf="f['mobileNumber'].hasError('maxlength')">Mobile no. should be max 15 characters.</div>
                <div *ngIf="f['mobileNumber'].hasError('pattern')">Mobile no. must be in valid format.</div>
              </div>
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <!-- <div class="form-group justify-content-center align-items-center e-float-input">
          <label for="userId">UserId</label>
          <input type="text" formControlName="userId" class="form-control"
            placeholder="Please enter emailid for userId " [ngClass]="{ 'is-invalid': submitted && f['userId'].errors }"
            />
            <span class="e-float-line"></span>
            <div *ngIf="submitted && f['userId'].errors" class="invalid-feedback">
            <div *ngIf="f['userId'].hasError('required')">userId is required</div>
          </div>
        </div> -->
            <div class="form-group justify-content-center align-items-center e-float-input">
              <label for="userId">UserId</label>
              <input type="text" formControlName="userId" class="form-control" readonly
                placeholder="Please enter emailid for userId "
                [ngClass]="{ 'is-invalid': submitted && f['userId'].errors }"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" autocomplete="off">
              <span class="e-float-line"></span>
              <div class="invalid-feedback">
                <div *ngIf="f['userId'].hasError('required')">Email is required.</div>
                <div *ngIf="f['userId'].hasError('minlength')">Email must at least 2 characters.</div>
                <div *ngIf="f['userId'].hasError('maxlength')">Email allowed maximum 100 characters.</div>
                <div *ngIf="f['userId'].hasError('pattern')">Email must be in valid format.</div>
              </div>
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>

        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="picsum-img-wrapper company-logo">
              <img class="sliderImage" [src]="profilePic" alt="Random first slide">
            </div>
            <div class="form-group justify-content-center align-items-center e-float-input">



              <div>
                <button type="button" ejs-button cssClass="" class="documentUpload button_padding button_margin"
                  (click)="openMultipleImageUploadFormPopup()">Upload
                  Profile photo</button>
              </div>



            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>

        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group">
              <button ejs-button cssClass="" class="update button_padding button_margin buttonSize"
                type="submit">Update</button>

              <button type="button" (click)="navigateToUser()" ejs-button cssClass=""
                class="back button_padding button_margin buttonSize"><i class="fa fa-arrow-left"></i>
                back</button>
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>

      </form>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
