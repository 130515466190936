import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  sub: any;
  page: string;
  isSuccess: boolean = false;
  logo: any;
  logoMobile: any;
  currentUser: any = {};
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log("current User ", this.currentUser);
    if (this.currentUser != undefined && this.currentUser != '' && this.currentUser != null) {
      this.router.navigateByUrl(`/`);
    }
    this.logo = "assets/image/easemyprod-transpLanding.png";
    this.logoMobile = "assets/image/easemyprod1-Mobile.png";
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.page = params['page'];
      });
    if (this.page == "success") {
      this.isSuccess = true;
    }
  }

}
