<div class="container-fluid userlogin-body">

  <div class="row login-logo landing-login-mobile">
    <div class="col-sm-8 landing-page-mobile">
      <div class="alert alert-success alignCenter" role="alert" *ngIf="isSuccess==true">
        Congratulations! You have been registered sucessfully. Your account will be active in 2 hours. You will
        receive
        email notifications shortly.
      </div>
      <div>
        <img src={{logo}} class="img-responsive" id="customLogo">
        <img src={{logoMobile}} class="img-responsive" id="customLogoMobile">
      </div>
    </div>
    <div class="col-sm-4 user-login-button">

      <!--  <div><button type="button" class="create_packing button_padding button_margin customBtn" ejs-button cssClass="" [routerLink]="['/register']" >New Customer</button></div> -->
      <button type="button" class="user-login button_padding button_margin customBtn customBtn-mobile" ejs-button cssClass=""
        [routerLink]="['/login']">User Login</button>
    </div>
  </div>

  <div class="row login-footer hide-login-footer">
    <div class="col-md-1"></div>
    <div class="col-md-2">
      <i class="fa fa-car images" aria-hidden="true"></i>
    </div>
    <div class="col-md-2">
      <i class="fa fa-space-shuttle images" aria-hidden="true"></i>
    </div>
    <div class="col-md-2 on-overflow">
      <i class="fa fa-cog fa-spin fa-3x fa-fw images"></i>
    </div>
    <div class="col-md-2">
      <i class="fa fa-industry images" aria-hidden="true"></i>
    </div>
    <div class="col-md-2">
      <i class="fa fa-line-chart images" aria-hidden="true"></i>
    </div>
    <div class="col-md-1"></div>
  </div>

</div>