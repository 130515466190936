import { Component, OnInit } from '@angular/core';
import { CommonVariables } from '../commonVariables';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET, RoutesRecognized, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { Menu } from '../modules/navbar/models/menu';
import { GlobalServiceService } from '../modules/core/services/global-service.service';
import { DataService } from '../dataservices/dataservice.service';
import { User } from '../modules/core/model/model';
import { CreadCrumbLabel, modulePreviewPageIrl } from '../cread-crumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  currentUser: User;
  users: User[] = [];
  message: any = {};
  name: string;
  menu: Array<any> = [];


  /** side bar starts */
  menus: Menu[];


  public menuId: number = 0;
  public topmsgFlag: boolean = false;
  /** side bar ends */

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  readonly home = { icon: 'pi pi-home', url: 'home' };
  menuItems: MenuItem[];
  facility: any;
  financialYear: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private location: Location, private dataservice: DataService,
    private globalService: GlobalServiceService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
      });

  }
  ngOnInit() {
    var ref = this;
    this.dataservice.getAllMenu(this.currentUser.pk).subscribe(
      (data: any) => {
        this.menus = data.data;
        this.menuId = CommonVariables.getVariavbleValue();
        
      },
      (err: any) => {
        console.log(err);
      }
    );

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);

      });

    setTimeout(()=> {
      ref.menuItems = ref.createBreadcrumbs(ref.activatedRoute.root);
      $(".custom-breadcrumb ul li:first-child a").attr("href", "javascript:void(0)");
      $(".custom-breadcrumb ul li:first-child a").attr("target", "_self");
      $(".custom-breadcrumb ul li:first-child").attr("routerLink", ref.menuItems[1]?.url);
      $(".custom-breadcrumb ul li:first-child").on('click', function () {
        if (ref.menuItems.length > 0) {
          if (ref.menuItems[0].label == CreadCrumbLabel.OrderProcessing) {
            const tmpMenuUrl = ref.menuItems[0]?.url;
            const menuUrl = tmpMenuUrl.replace("#", "");
            ref.router.navigateByUrl(menuUrl + "/" + modulePreviewPageIrl.OrderProcessingPreview);
          } else if (ref.menuItems[0].label == CreadCrumbLabel.Manufacturing) {
            const tmpMenuUrl = ref.menuItems[0]?.url;
            const menuUrl = tmpMenuUrl.replace("#", "");
            ref.router.navigateByUrl(menuUrl + "/" + modulePreviewPageIrl.ManufacturingPreview);
          } else if (ref.menuItems[0].label == CreadCrumbLabel.Inventory) {
            const tmpMenuUrl = ref.menuItems[0]?.url;
            const menuUrl = tmpMenuUrl.replace("#", "");
            ref.router.navigateByUrl(menuUrl + "/" + modulePreviewPageIrl.InventoryPreview);
          }
          else {
            const tmpMenuUrl = ref.menuItems[0]?.url;
            const menuUrl = tmpMenuUrl.replace("#", "");
            ref.router.navigateByUrl(menuUrl);
          }
        }
      });
    }, 4000);
    this.router.events.subscribe(val => {
      if (val != undefined && val['snapshot'] != undefined && val['snapshot']['params'] != undefined && val['snapshot']['params']['id'] != undefined) {
        this.menuId = val['snapshot']['params']['id'];
      }
    });
  }


  private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      //console.log(label)
      if (label !== undefined || label !== null) {
        let flag = false;
        if (breadcrumbs.length > 0) {
          for (var i = 0; i < breadcrumbs.length; i++) {
            if (breadcrumbs[i].label == label) {
              flag = true;
              break;
            }
          }
        }
        if (!flag) {
          if (label == "" && url == "#") {

          } else {
            breadcrumbs.push({ label, url, target: "_self" });
          }
        }

      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  navigateToModule(menu) {
    this.facility = JSON.parse(sessionStorage.getItem('facility'));
    this.financialYear = JSON.parse(sessionStorage.getItem('financialYear'));
    if (this.financialYear == undefined || this.financialYear == null) {
      this.router.navigateByUrl(`/financialYear/${this.currentUser.pk}`);
    } else if (this.facility == undefined || this.facility == null) {
      this.router.navigateByUrl(`/facility/${this.currentUser.pk}`);
    } else {
      if (!!menu.moduleAction && menu.moduleAction.toLowerCase() === 'inventory') {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}/inv-overview`);
      }
      else if (!!menu.moduleAction && menu.moduleAction.toLowerCase() === 'settings') {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}`);
      }
      else if (!!menu.moduleAction && menu.moduleAction.toLowerCase() === 'manufacturing') {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}/mfrg-overview`);
      }
      else if (!!menu.moduleAction && menu.moduleAction.toLowerCase() === 'order-processing') {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}/processing-overview`);
      }
      else {
        this.router.navigateByUrl(`/${menu.moduleAction.toLowerCase()}/${menu.pk}`);
      }
    }
  }



  selectMenu(event, menu) {
    this.menuId = menu.pk;

  }
  setFlag() {
    console.log("Flag sideBar", this.topmsgFlag);
  }

}
