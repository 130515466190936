import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { CommonVariables } from './commonVariables';
import { GlobalServiceService } from './modules/core/services/global-service.service';

export class CustomValidator {
    // Number only validation

    static numeric(control: AbstractControl) {
        let val = control.value;
        console.log("value is integer ", Number.isInteger(val));
        if (val === null || val === '') return null;

        if (!val.toString().match(/^[0-9]+(\?[0-9]+)?$/)) return { 'invalidNumber': true };

        return null;
    }

    static mobileNumberValidator(control: AbstractControl) {
        let val = control.value;
        console.log("value is integer ", Number.isInteger(val));
        if (val === null || val === '') return null;

        if (!val.toString().match(/^\d{10}$/)) return { 'invalidNumber': true };

        return null;
    }

    static emailValidator(control: AbstractControl) {
        let val = control.value;
        console.log("value is integer ", val);
        if (val === null || val === '') return null;

        if (!val.toString().match(/^([a-zA-Z0-9_.])+\@(([a-zA-Z0-9])+\.)+([a-zA-Z0-9]{2,4})+$/)) return { 'invalidEmail': true };

        return null;
    }

    static gstNoValidator(control: AbstractControl) {
        let val = control.value;
        console.log("value is integer ", val);
        if (val === null || val === '') return null;

        if (!val.toString().match(/^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/g)) return { 'invalidGstNumber': true };

        return null;
    }

    static deliveryDateValidation(control: AbstractControl) {

        let financialYear: any = JSON.parse(sessionStorage.getItem('financialYear'));
        let fyStartDate = CommonVariables.convertStrToDate(financialYear.startDate);
        let fyEndDate = CommonVariables.convertStrToDate(financialYear.endDate);

        let orderDate = control.value;

        if (typeof orderDate === 'string') {

            let parts: any[] = orderDate.split('/');
            let dd = new Date(parts[2], parts[1] - 1, parts[0]);

            if (dd < fyStartDate || dd > fyEndDate) {
                return { 'invalidOrderDate': true };
            }
        } else {

            if (orderDate < fyStartDate || orderDate > fyEndDate) {
                return { 'invalidOrderDate': true };
            }
        }

        return null;
    }

    static minDateValidator(minDate) {
        return (control: AbstractControl): ValidationErrors | null => {
            let date: Date = control.value;

            let parseddate = Date.parse(minDate);
            let minDate2 = new Date(parseddate);
            if (date < minDate2) {

                return { 'invalidDeliveryDate': true };
            } else {

                return null;
            }

        }
    }

    static minAndMaxDateValidation(minDate, fyEndDate) {
        return (control: AbstractControl): ValidationErrors | null => {
            let date: Date = control.value;

            let parseddate = Date.parse(minDate);
            let minDate2 = new Date(parseddate);
            if (date < minDate2 || date > fyEndDate) {

                return { 'invalidDeliveryDate': true };
            } else {

                return null;
            }

        }
    }

    static domainNameValidator(control: AbstractControl) {
        let val = control.value;

        if (val === null || val === '') return null;

        if (!val.toString().match(/^[a-zA-Z0-9]*$/)) return { 'invalidDomainName': true };

        return null;
    }

    static passWordValidator(control: AbstractControl) {
        let val = control.value;

        if (val === null || val === '') return null;

        if (!val.toString().match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[_$@!%&*?])[A-Za-z\d_$@!%&*?]{6,15}$/)) return { 'invalidPassword': true };

        return null;
    }

    static hsnNumeric(control: AbstractControl) {
        let val = control.value;
        console.log("value is integer ", Number.isInteger(val));
        if (val === null || val === '') return null;

        if (!val.toString().match(/^[0-9]+(\?[0-9]+)?$/) || val.toString().length > 8) return { 'invalidHsnNumber': true };

        return null;
    }

    static isVendorDateValid(control: AbstractControl) {

        let vendorDatePattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

        let val = control.value;

        if (val === null || val === '') return null;

        if (typeof val === 'string') {
            let date = val.split("/")
            if (date[2].length > 4) {
                return { "invalidVendorDate": true };
            } else {
                return null;
            }

        } else if (typeof val !== 'string') {
            let year = val.getFullYear().toString();

            if (year.length > 4) {
                return { "invalidVendorDate": true };
            } else {
                return null;
            }

        }

        return null;
    }

    static isPoUrlAvailable(control: AbstractControl) {

        let val = control.value;

        if (val === null || val === '') return null;

        if (val.indexOf("AUTO_GENERATED_URL") == -1) {
            return { "invalidMessage": true };
        }

        return null;
    }

    static noWhitespaceValidator(control: AbstractControl) {
        let val = control.value;
        if (val == null || val == '') {
            return null;
        }
        else {
            if ((val || '').trim().length === 0) {
                return { noWhitespaceValidator: true }
            }

        }

        return null;
    }

    static checkIfUnique(index) {
        return (control: FormControl) => {
            const formArray =
                control.parent && control.parent.parent
                    ? (control.parent.parent as FormArray)
                    : null;
            if (formArray && formArray.controls.length) {
                for (let i = index - 1; i >= 0; i--) {
                    if (
                        (formArray.at(i) as FormGroup).get("label").value == control.value && (formArray.at(i) as FormGroup).get("isDisplay").value == 1
                    )
                        return { errorRepeat: true };
                }
            }
        };
    }

    quantityDecimalValidator(control: AbstractControl) {
        let decimalPlace = JSON.parse(localStorage.getItem('decimalPlace'));
        let numRegex = `^-?\\d*[.,]?\\d{0,${decimalPlace}}$`;
        let pattern = new RegExp(numRegex);
        let val = control.value;
        if (!pattern.test(val.toString())) {
            return { 'invalid_decimal_place': true };
        } else {
            return null
        }
    }

    rateAndDiscountDecimalValidator(control: AbstractControl) {
        let decimalPlaceRateAndDiscount = JSON.parse(localStorage.getItem('decimalPlaceRatesAndDiscount'));
        let numRegex = `^-?\\d*[.,]?\\d{0,${decimalPlaceRateAndDiscount}}$`;
        let pattern = new RegExp(numRegex);
        let val = control.value;
        if (!pattern.test(val.toString())) {
            return { 'invalid_decimal_place': true };
        } else {
            return null
        }
    }

}