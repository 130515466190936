import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonVariables } from 'src/app/commonVariables';
import { AppComponent } from 'src/app/app.component';
import { FinacilayearType } from '../modules/financialyear/models/financiaYearType';
import { Finacilayear } from '../modules/financialyear/models/financialYear';
import { FinancialYearService } from '../modules/financialyear/services/financial-year.service';
import { ConfirmationDialogService } from '../confirmation-dailog/confirmation-dialog.service';
import { GlobalServiceService } from '../modules/core/services/global-service.service';

@Component({
  selector: 'app-add-financial-year-firt-time',
  templateUrl: './add-financial-year-firt-time.component.html',
  styleUrls: ['./add-financial-year-firt-time.component.css']
})
export class AddFinancialYearFirtTimeComponent implements OnInit {
  public minDate;
  public maxDate;
  serverDate: any;
  isUpdateAccess: boolean = false;
  isAddAccess: boolean = false;
  finacilayear: Finacilayear;
  submitted: boolean = false;
  isEdit: boolean = false;
  finacilayearForm: FormGroup;
  finacilayearTypes: FinacilayearType;
  datePipe = new DatePipe('en-US');
  currentUser: any = {};
  months: any[] = [];
  public stateFields: Object = { text: 'monthKey', value: 'monthvalue' };
  public placeholder2: string = "Search a months";
  public userPk: number = 0;
  constructor(public appComp: AppComponent, private route: ActivatedRoute, private modalService: NgbModal,
    private financialYearService: FinancialYearService, private router: Router,
    private confirmationDialogService: ConfirmationDialogService, private formBuilder: FormBuilder,
    private location: Location, private globalService: GlobalServiceService,
    private ngxLoader: NgxUiLoaderService) {


  }


  ngOnInit() {
    this.ngxLoader.start();
    this.getListOfMonths();
    this.finacilayearForm = this.formBuilder.group({
      companyCode: ['', Validators.required],
      month: ['', Validators.required],

    });

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log("current user ", this.currentUser);
    this.finacilayearForm.patchValue({
      "companyCode": this.currentUser.companyCode
    });
    this.route.paramMap.subscribe(
      params => {
        const id = +params.get('pk');
        this.userPk = id;

      }
    );




    this.ngxLoader.stop();
  }

  onSubmit() {
    this.ngxLoader.start();
    this.submitted = true;
    if (this.finacilayearForm.invalid) {
      this.ngxLoader.stop();
      return;
    }

    let value: any = this.finacilayearForm.value


    this.financialYearService.createFyAtLoginTime(value).subscribe(
      (response: any) => {
        this.ngxLoader.stop();

        console.log("data ", response);
          this.gotoFy();
      },
      (err) => {
        console.log(err);
        this.ngxLoader.stop();
      }
    );



  }

  get f() { return this.finacilayearForm.controls; }

  navigateToFinacilayear(): void {
    this.location.back();
  }
  gotoFy() {
    this.router.navigateByUrl(`/financialYear/${this.userPk}`);
  }

  getListOfMonths() {
    this.financialYearService.getListOfMonths().subscribe((data) => {
      this.months = data.data;
    });
  }

  setAttribute() {
    $("#ddlelement_popup .e-input").attr("placeholder", "Type here to search");
  }

  changeMonth(event) {
    var ref = this;
    setTimeout(function () {
      console.log(ref.finacilayearForm.value);
    }, 500);

  }
}
