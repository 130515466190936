<div id="MainContainer" class="container-fluid mobile-container-fluid">
  <app-header></app-header>
  <div class="container" style="margin-top:5rem">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 fy-container" *ngIf="financialYearList?.length != 0">
        <h3 style="text-align:center">Select Financial Year</h3>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Start Year</th>
              <th>Final Year</th>
              <th>Financial Year</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let f of financialYearList;let sl=index" (click)="navigateToFacility(f)"
              style="cursor: pointer;">
              <td>{{ sl+1 }}</td>
              <td> {{f.startDate}}</td>
              <td>{{f.endDate}}</td>
              <td>{{f.financialYear}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</div>

<ngx-ui-loader></ngx-ui-loader>
