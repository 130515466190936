<div class="container justify-content-center align-items-center">
    <div class="row justify-content-center align-items-center ">
        <div class="col-sm-12 ">
            <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="LoginContainer">
                <h2 class="loginTitle">Forgot Password</h2>
                <div class="form-group justify-content-center align-items-center">
                    <label for="companyCode">Company Code</label>
                    <input type="text" formControlName="companyCode" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f['companyCode'].errors }" />
                    <div *ngIf="submitted && f['companyCode'].errors" class="invalid-feedback">
                        <div *ngIf="f['companyCode'].hasError('required')">Company Code is required</div>
                    </div>
                </div>
                <div class="form-group justify-content-center align-items-center">
                    <label for="userId">UserId</label>
                    <input type="text" formControlName="userId" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f['userId'].errors }" />
                    <div *ngIf="submitted && f['userId'].errors" class="invalid-feedback">
                        <div *ngIf="f['userId'].hasError('required')">UserId is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <button class="btn btn-success" type="submit" [disabled]="!forgotPasswordForm.valid">Submit</button>
                    <button type="button" class="btn btn-danger" (click)="resetForgotPasswordForm()"><i
                            class="fa fa-undo"></i> Reset</button>
                    <button type="button" (click)="logout()" class="btn btn-warning"><i
                            class="fa fa-arrow-left"></i> Back</button>
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
