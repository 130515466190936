import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalServiceService } from '../modules/core/services/global-service.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  companyCode: string;
  facilityId: string;
  financialId: string;
  userId: string;
  userPk: string;
  user: any;
  access_token: string;
  sessionId: string;
  constructor(private globalService: GlobalServiceService) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*   request = request.clone({
        withCredentials: true,
      }); */

    let re = request.url;

    let indexNo: number = re.indexOf("/tempurl");

    if (indexNo != -1) {
      let mailSetting: any = JSON.parse(sessionStorage.getItem('mailSettingData'));
      request = request.clone({
        setHeaders: { 'Accept': 'application/json' }
      });
      request = request.clone({
        setHeaders: { 'companyCode': mailSetting.companyCode.toString() }
      });
      request = request.clone({
        setHeaders: { 'facilityId': mailSetting.facilityId.toString() }
      });
      request = request.clone({
        setHeaders: { 'finanicalId': mailSetting.financialYearId.toString() }
      });
      request = request.clone({
        setHeaders: { 'userPk': mailSetting.userId.toString() }
      });
      request = request.clone({
        setHeaders: { 'Authorization': 'bearer ' + mailSetting.urlToken.toString() }
      });
      return next.handle(request);

    } else {
      request = request.clone({
        setHeaders: { 'Accept': 'application/json' }
      });
      this.globalService.currentUser.subscribe(user => {
        if (user) {
          this.companyCode = user.companyCode;
          request = request.clone({
            setHeaders: { 'companyCode': this.companyCode.toString() }
          });
        }
      });
      this.globalService.facility.subscribe(facility => {
        if (facility) {
          this.facilityId = facility.id.toString();
          request = request.clone({
            setHeaders: { 'facilityId': this.facilityId.toString() }
          });
        }
      });
      this.globalService.financialYear.subscribe(financialYear => {
        if (financialYear) {
          this.financialId = financialYear.id.toString();
          request = request.clone({
            setHeaders: { 'finanicalId': this.financialId.toString() }
          });
        }
      });

      this.globalService.currentUser.subscribe(user => {
        if (user) {
          this.userId = user.pk.toString();
          this.sessionId = user.sessionId;
          request = request.clone({
            setHeaders: { 'userPk': this.userId.toString() }
          });
          request = request.clone({
            setHeaders: { 'sessionId': this.sessionId.toString() }
          });
        }
      });

      this.globalService.accessToken.subscribe((token)=>{
        if (token != null) {
          this.access_token = token.access_token;
          request = request.clone({
            setHeaders: { 'Authorization': 'bearer ' + this.access_token.toString() }
          });
        }
      });
      
      return next.handle(request);
    }

  }
}
export let httpInterceptorProviders = {
  provide: HTTP_INTERCEPTORS,
  useClass: JwtInterceptor,
  multi: true
};