import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { DataStateChangeEventArgs, Sorts, PredicateModel } from '@syncfusion/ej2-grids';
import { Predicate, DataResult } from '@syncfusion/ej2-data';
import { map } from 'rxjs/operators';
import { CommonVariables } from 'src/app/commonVariables';
import { environment } from 'src/environments/environment';
import { HeaderConfiguration } from 'src/app/headerConfiguration';


@Injectable({
  providedIn: 'root'
})
export class PaidPlanSubscribeService extends Subject<DataStateChangeEventArgs>  {
  baseurl = environment.apiUrl;
  requestHeader = HeaderConfiguration.getHeaderConfiguration();
  constructor(private http: HttpClient) {
    console.log("service constructor called");
    super();

  }
  getAllSalesReport(): Observable<any> {
    return this.http.get<any>(this.baseurl + "/reporting/salesReportDetailed?top=1000&$count=true");
  }

  getCompanyByDomainName(domainName: string): Observable<any> {
    this.requestHeader = HeaderConfiguration.getHeaderConfiguration();

    return this.http.get<any>(`${this.baseurl}/common/getCompanyMasterByDomainName/${domainName}`, { headers: this.requestHeader });
  }

  getCompanySubsciptionDetailsByDomainName(domainName: string): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getCompanySubsciptionDetailsByDomainName/${domainName}`);
  }

  updateSubscription(subscriptionData: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/updateSubscription`, subscriptionData);
  }

  cancelTrialPlan(subscriptionId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/cancelSubscription/${subscriptionId}`);
  }

  addHostedPageSubscription(subscriptionData: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/addHostedPageSubscription`, subscriptionData);
  }

  retrieveCustomerById(customerId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/retrieveCustomer/${customerId}`);
  }

  retrieveHostedPageById(hostPageId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/retrieveHostedPage/${hostPageId}`);
  }

  addOrUpdateCompany(company: any): Observable<any> {
    return this.http.post<any>(`${this.baseurl}/common/addOrUpdateCompany`, company);
  }

  thankyouSubscription(hostPageId: number): Observable<any> {
    return this.http.get<any>(`https://subscriptions.zoho.com/api/v1/hostedpage/${hostPageId}/thankyou`);
  }

  retrieveSubsciption(subscriptionId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/retrieveSubsciption/${subscriptionId}`);
  }

  listAllInvoiceByCustomerId(customerId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/listAllInvoiceByCustomerId/${customerId}`);
  }
  getInvoiceByInviceId(invoiceId: number): Observable<any> {
    return this.http.get<any>(`${this.baseurl}/common/getInvoiceByInvoiceId/${invoiceId}`);
  }

}
